import axios from "axios";
import { FetchException } from "../errors/FetchException";
import { Logger } from "../log/Logger";

const getAuthToken = () => {
  let token: any = null;
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].split("=");
    if (cookie[0].trim().toLocaleLowerCase() === "token") {
      token = cookie[1];
    }
  }
  return token;
};

// TODO replace axios with fetch
const DEFAULT_TIMEOUT_MILLIS = 1000 * 15;
// const DEFAULT_HEADERS = {};
const DEFAULT_HEADERS = {
  Authorization: `Bearer ${getAuthToken()}`,
};
enum Method {
  POST = "POST",
  GET = "GET",
  DELETE = "DELETE",
  PUT = "PUT",
}
/**
 * Wrapping class to be used as a REST client
 * Avoid using direct calls to e.g. fetch as it'll be difficult to add authentication, custom headers, throttling, etc...
 * TODO: wrap with authentication (e.g. JWT), add custom headers
 */
export class RestClient {
  /**
   * Make a GET call to an endpoint and return JSON
   * @param url url to make a call to
   * @param timeoutInMillis timeout(ms) after which call will be cancelled (optional)
   * @throws FetchException thrown in case of failure
   */
  public async getCall<T>(
    url: string,
    headers: any = DEFAULT_HEADERS,
    timeoutInMillis: number = DEFAULT_TIMEOUT_MILLIS
  ): Promise<T> {
    return this.axiosCall<T>(
      Method.GET,
      url,
      undefined,
      headers,
      timeoutInMillis
    );
  }

  /**
   * Make a POST call to an endpoint and return JSON
   * @param url url to make a call to
   * @param body request body (JSON)
   * @param headers headers to be sent (optional)
   * @param timeoutInMillis timeout(ms) after which call will be cancelled (optional)
   * @throws FetchException thrown in case of failure
   */
  public async postCall<T>(
    url: string,
    body: any = {},
    headers: any = DEFAULT_HEADERS,
    timeoutInMillis: number = DEFAULT_TIMEOUT_MILLIS
  ): Promise<T> {
    return this.axiosCall<T>(Method.POST, url, body, headers, timeoutInMillis);
  }
  public async putCall<T>(
    url: string,
    body: any = {},
    headers: any = DEFAULT_HEADERS,
    timeoutInMillis: number = DEFAULT_TIMEOUT_MILLIS
  ): Promise<T> {
    console.log({
      method: Method.PUT,
      url,
      body,
      headers,
    });
    return this.axiosCall<T>(Method.PUT, url, body, headers, timeoutInMillis);
  }
  public async deleteCall<T>(
    url: string,
    body: any = {},
    headers: any = DEFAULT_HEADERS,
    timeoutInMillis: number = DEFAULT_TIMEOUT_MILLIS
  ): Promise<T> {
    return this.axiosCall<T>(
      Method.DELETE,
      url,
      body,
      headers,
      timeoutInMillis
    );
  }

  private async axiosCall<T>(
    method: Method,
    url: string,
    body: any,
    headers: any = DEFAULT_HEADERS,
    timeoutInMillis: number
  ): Promise<T> {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const timeout = setTimeout(() => source.cancel("Timeout"), timeoutInMillis);
    const config = {
      cancelToken: source.token,
      headers,
    };
    const data = body ? body : {};
    let result: T;
    try {
      let response;
      switch (method) {
        case Method.GET:
          response = await axios.get(url, config);
          break;
        case Method.POST:
          response = await axios.post(url, data, config);
          break;
        case Method.DELETE:
          response = await axios.delete(url, config);
          break;
        case Method.PUT:
          response = await axios.put(url, data, config);
          break;
        default:
          Logger.error(`Unsupported method:${method}`);
      }
      result = response.data as T;
    } catch (error: any) {
      Logger.error(
        `Failed to make a GET call to url ${url}, error:${error.message}`
      );
      if (axios.isCancel(error)) {
        throw new FetchException(
          `URL did not respond within maximum allowed time, ${error.message}`,
          url,
          error
        );
      } else {
        let responseBody = "";
        if (error.response && error.response.data) {
          responseBody = JSON.stringify(error.response.data).substring(0, 100);
          Logger.debug("Error from server:", error.response.data);
          Logger.debug("Request payload:", data);
        }
        throw new FetchException(
          `REST call error occurred, ${error.message};${responseBody}`,
          url,
          error
        );
      }
    } finally {
      clearTimeout(timeout);
    }
    return result;
  }
}
