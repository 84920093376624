/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import KPICard from "../KPICard/KPICard";
import styles from "./styles.module.css";
import { KPIContext } from "context-api/KPIContext";
import KPI from "../KPI/KPI";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { KpiClient } from "lib/api/msd/kpi/Kpi";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { KPISaveClient } from "lib/api/kpi-save";
import SkeletonView from "./SkeletonView";

const SortableItem = SortableElement(({ value }) => {
  return (
    <div className={styles.item}>
      <KPICard cardData={value} minimize />
    </div>
  );
});

const SortableList = SortableContainer(({ items }) => {
  const [position, setPosition] = useState(0);
  const [isOpenExtendKpi, setIsOpenExtendKpi] = useState(false);
  const { theme } = useContext(ThemeContext);

  const style = {
    position: "absolute",
    width: "100vw",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    overflowY: "scroll",
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.container}>
      <Modal
        open={isOpenExtendKpi}
        onClose={() => setIsOpenExtendKpi(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          className={styles.background_container}
          data-theme={theme}
        >
          <div className={styles.extend_and_download_buttons}>
            <span
              className={styles.extend_back_button}
              onClick={() => {
                setIsOpenExtendKpi(false);
              }}
              data-theme={theme}
            >
              Back
            </span>
            <span className={styles.extend_title} data-theme={theme}>
              Drag and drop to change order of cards.
            </span>
            <span
              className={styles.extend_download_button}
              style={{ visibility: "hidden" }}
            ></span>
          </div>
          <div>
            <KPI />
          </div>
        </Box>
      </Modal>
      <div className={styles.buttons_wrapper}>
        <span
          className={styles.kpi_button}
          onClick={() => {
            setIsOpenExtendKpi(true);
          }}
          data-theme={theme}
        >
          Extend KPI’s
        </span>
        <div
          className={styles.arrow_button}
          onClick={() => {
            if (position > 0) {
              if (screenWidth > 1280) {
                setPosition(position - 1095);
              } else if (screenWidth > 640) {
                setPosition(position - 730);
              } else if (screenWidth <= 640) {
                setPosition(position - 365);
              }
            } else setPosition(2190);
          }}
          data-theme={theme}
        >
          <span className={`${styles.arrow} ${styles.arrow_left}`} />
        </div>
        <div
          className={styles.arrow_button}
          onClick={() => {
            if (position < 1800) {
              if (screenWidth > 1280) {
                setPosition(position + 1095);
              } else if (screenWidth > 640) {
                setPosition(position + 730);
              } else if (screenWidth <= 640) {
                setPosition(position + 365);
              }
            } else setPosition(0);
          }}
          data-theme={theme}
        >
          <span className={`${styles.arrow} ${styles.arrow_right}`} />
        </div>
      </div>
      <div className={styles.slider_wrapper}>
        <div className={styles.slider} style={{ right: position }}>
          {items?.map((value, index) => (
            <SortableItem key={index} index={index} value={value} />
          ))}
        </div>
      </div>
    </div>
  );
});

interface IKpiMini {
  filterIsOpen: boolean;
}
export default function KPIMini({ filterIsOpen }: IKpiMini) {
  const { kpiList, setKpiList } = useContext(KPIContext);
  const { filterList } = useContext(FilterContext);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (kpiList.items.length > 0) {
      const data = { kpis: kpiList.items.map((item) => item?.kpiType) };
      KPISaveClient.fetchKPISave({ data })
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  }, [kpiList.items]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setKpiList(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));
  };
  useEffect(() => {
    if (!filterIsOpen && filterList.mainCompetitor.length > 0) {
      setLoader(true);
      KpiClient.fetchKpi({
        filterList,
        kpi_type: "ALL",
      })
        .then((response: any) => {
          if (Array.isArray(response)) setKpiList({ items: response });
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          setTimeout(() => {
            setLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList]);

  return loader ? (
    <SkeletonView />
  ) : (
    <SortableList
      axis="xy"
      items={kpiList.items}
      onSortEnd={onSortEnd}
      distance={1}
      helperClass={"SortableHelperWithOverride"}
    />
  );
}
