import Card from "components/common/Card/Card";
import styles from "./DistributionChannels.module.css";
import PlotlyComponent from "components/common/Charts/Plotly";

export default function DistributionChannels() {
  const data = {
    fig_host: {
      data: [
        {
          hole: 0.5,
          hoverinfo: "label+percent+value",
          labels: ["AE", "IN", "Others"],
          marker: {
            colors: [
              "rgb(41.0, 92.0, 255.0)",
              "rgb(79.5, 121.0, 255.0)",
              "rgb(118.0, 150.0, 255.0)",
            ],
          },
          textfont: {
            family: "Fabriga Medium",
            size: 14,
          },
          textposition: "outside",
          values: [148, 271, 3069],
          type: "pie",
          domain: {
            x: [0, 0.2833333333333333],
            y: [0.575, 1],
          },
        },
        {
          hole: 0.5,
          hoverinfo: "label+percent+value",
          labels: ["AE", "IN", "Others"],
          marker: {
            colors: [
              "rgb(41.0, 92.0, 255.0)",
              "rgb(79.5, 121.0, 255.0)",
              "rgb(118.0, 150.0, 255.0)",
            ],
          },
          textfont: {
            family: "Fabriga Medium",
            size: 14,
          },
          textposition: "outside",
          values: [11689, 42788, 317749],
          type: "pie",
          domain: {
            x: [0, 0.2833333333333333],
            y: [0, 0.425],
          },
        },
        {
          hoverinfo: "x+y",
          hovertext: ["SA", "GB", "US", "CA", "SE", "ES", "CN", "OM"],
          legendgroup: "Others",
          marker: {
            color: [
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
            ],
            line: {
              width: 0,
            },
          },
          showlegend: false,
          x: ["SA", "GB", "US", "CA", "SE", "ES", "CN", "OM"],
          y: [49, 38, 33, 18, 4, 2, 2, 2],
          type: "bar",
          xaxis: "x",
          yaxis: "y",
        },
        {
          hoverinfo: "x+y",
          hovertext: ["SA", "US", "GB", "CA", "SE", "OM", "ES", "CN"],
          legendgroup: "Others",
          marker: {
            color: [
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
              "#295CFF",
              "#7696FF",
            ],
            line: {
              width: 0,
            },
          },
          showlegend: false,
          x: ["SA", "US", "GB", "CA", "SE", "OM", "ES", "CN"],
          y: [5343, 2518, 2158, 891, 359, 153, 146, 121],
          type: "bar",
          xaxis: "x2",
          yaxis: "y2",
        },
      ],
      layout: {
        template: {
          data: {
            bar: [
              {
                error_x: {
                  color: "#2a3f5f",
                },
                error_y: {
                  color: "#2a3f5f",
                },
                marker: {
                  line: {
                    color: "#E5ECF6",
                    width: 0.5,
                  },
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "bar",
              },
            ],
            barpolar: [
              {
                marker: {
                  line: {
                    color: "#E5ECF6",
                    width: 0.5,
                  },
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "barpolar",
              },
            ],
            carpet: [
              {
                aaxis: {
                  endlinecolor: "#2a3f5f",
                  gridcolor: "white",
                  linecolor: "white",
                  minorgridcolor: "white",
                  startlinecolor: "#2a3f5f",
                },
                baxis: {
                  endlinecolor: "#2a3f5f",
                  gridcolor: "white",
                  linecolor: "white",
                  minorgridcolor: "white",
                  startlinecolor: "#2a3f5f",
                },
                type: "carpet",
              },
            ],
            choropleth: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "choropleth",
              },
            ],
            contour: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "contour",
              },
            ],
            contourcarpet: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "contourcarpet",
              },
            ],
            heatmap: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "heatmap",
              },
            ],
            heatmapgl: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "heatmapgl",
              },
            ],
            histogram: [
              {
                marker: {
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "histogram",
              },
            ],
            histogram2d: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "histogram2d",
              },
            ],
            histogram2dcontour: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "histogram2dcontour",
              },
            ],
            mesh3d: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "mesh3d",
              },
            ],
            parcoords: [
              {
                line: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "parcoords",
              },
            ],
            pie: [
              {
                automargin: true,
                type: "pie",
              },
            ],
            scatter: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatter",
              },
            ],
            scatter3d: [
              {
                line: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatter3d",
              },
            ],
            scattercarpet: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattercarpet",
              },
            ],
            scattergeo: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattergeo",
              },
            ],
            scattergl: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattergl",
              },
            ],
            scattermapbox: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattermapbox",
              },
            ],
            scatterpolar: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterpolar",
              },
            ],
            scatterpolargl: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterpolargl",
              },
            ],
            scatterternary: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterternary",
              },
            ],
            surface: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "surface",
              },
            ],
            table: [
              {
                cells: {
                  fill: {
                    color: "#EBF0F8",
                  },
                  line: {
                    color: "white",
                  },
                },
                header: {
                  fill: {
                    color: "#C8D4E3",
                  },
                  line: {
                    color: "white",
                  },
                },
                type: "table",
              },
            ],
          },
          layout: {
            annotationdefaults: {
              arrowcolor: "#2a3f5f",
              arrowhead: 0,
              arrowwidth: 1,
            },
            autotypenumbers: "strict",
            coloraxis: {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
            },
            colorscale: {
              diverging: [
                [0, "#8e0152"],
                [0.1, "#c51b7d"],
                [0.2, "#de77ae"],
                [0.3, "#f1b6da"],
                [0.4, "#fde0ef"],
                [0.5, "#f7f7f7"],
                [0.6, "#e6f5d0"],
                [0.7, "#b8e186"],
                [0.8, "#7fbc41"],
                [0.9, "#4d9221"],
                [1, "#276419"],
              ],
              sequential: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              sequentialminus: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
            colorway: [
              "#636efa",
              "#EF553B",
              "#00cc96",
              "#ab63fa",
              "#FFA15A",
              "#19d3f3",
              "#FF6692",
              "#B6E880",
              "#FF97FF",
              "#FECB52",
            ],
            font: {
              color: "#2a3f5f",
            },
            geo: {
              bgcolor: "white",
              lakecolor: "white",
              landcolor: "#E5ECF6",
              showlakes: true,
              showland: true,
              subunitcolor: "white",
            },
            hoverlabel: {
              align: "left",
            },
            hovermode: "closest",
            mapbox: {
              style: "light",
            },
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            polar: {
              angularaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              bgcolor: "#E5ECF6",
              radialaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
            },
            scene: {
              xaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
              yaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
              zaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
            },
            shapedefaults: {
              line: {
                color: "#2a3f5f",
              },
            },
            ternary: {
              aaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              baxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              bgcolor: "#E5ECF6",
              caxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
            },
            title: {
              x: 0.05,
            },
            xaxis: {
              automargin: true,
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
              title: {
                standoff: 15,
              },
              zerolinecolor: "white",
              zerolinewidth: 2,
            },
            yaxis: {
              automargin: true,
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
              title: {
                standoff: 15,
              },
              zerolinecolor: "white",
              zerolinewidth: 2,
            },
          },
        },
        xaxis: {
          anchor: "y",
          domain: [0.43333333333333335, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        yaxis: {
          anchor: "x",
          domain: [0.575, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        xaxis2: {
          anchor: "y2",
          domain: [0.43333333333333335, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        yaxis2: {
          anchor: "x2",
          domain: [0, 0.425],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        annotations: [
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Total Passengers",
            x: 0.14166666666666666,
            xanchor: "center",
            xref: "paper",
            y: 1,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Others Breakdown",
            x: 0.7166666666666667,
            xanchor: "center",
            xref: "paper",
            y: 1,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Total Revenue",
            x: 0.14166666666666666,
            xanchor: "center",
            xref: "paper",
            y: 0.425,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Others Breakdown",
            x: 0.7166666666666667,
            xanchor: "center",
            xref: "paper",
            y: 0.425,
            yanchor: "bottom",
            yref: "paper",
          },
        ],
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 60,
          pad: 8,
        },
        title: {
          font: {
            family: "Open Sans",
            size: 23,
          },
          text: "XX",
          x: 0.5,
        },
        paper_bgcolor: "rgb(32, 32, 68)",
        plot_bgcolor: "rgb(32, 32, 68)",
        showlegend: false,
        autosize: false,
        height: 508,
        width: 512,
        bargap: 0,
        id: "9f110679-8013-4b68-adce-2e0adcca25c5",
      },
    },
    fig_comp: {
      data: [
        {
          hole: 0.5,
          hoverinfo: "label+percent+value",
          labels: ["AE", "IN", "Others"],
          marker: {
            colors: [
              "rgb(148.0, 59.0, 255.0)",
              "rgb(165.5, 100.5, 242.0)",
              "rgb(183.0, 142.0, 229.0)",
            ],
          },
          textfont: {
            family: "Fabriga Medium",
            size: 14,
          },
          textposition: "outside",
          values: [74, 1067, 2011],
          type: "pie",
          domain: {
            x: [0, 0.2833333333333333],
            y: [0.575, 1],
          },
        },
        {
          hole: 0.5,
          hoverinfo: "label+percent+value",
          labels: ["AE", "IN", "Others"],
          marker: {
            colors: [
              "rgb(148.0, 59.0, 255.0)",
              "rgb(165.5, 100.5, 242.0)",
              "rgb(183.0, 142.0, 229.0)",
            ],
          },
          textfont: {
            family: "Fabriga Medium",
            size: 14,
          },
          textposition: "outside",
          values: [9987, 78429, 193910],
          type: "pie",
          domain: {
            x: [0, 0.2833333333333333],
            y: [0, 0.425],
          },
        },
        {
          hoverinfo: "x+y",
          hovertext: [
            "GB",
            "US",
            "AU",
            "CA",
            "SA",
            "IT",
            "TR",
            "ZA",
            "NZ",
            "DE",
            "NG",
            "ES",
            "HK",
            "UG",
            "RU",
            "SE",
            "IE",
            "ZW",
            "KG",
            "DK",
            "JO",
            "NO",
            "BR",
            "MW",
            "FR",
            "RO",
            "CN",
            "BJ",
            "ZM",
            "BH",
            "FI",
            "NL",
            "CL",
            "PK",
          ],
          legendgroup: "Others",
          marker: {
            color: [
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
            ],
            line: {
              width: 0,
            },
          },
          showlegend: false,
          x: ["GB", "US", "AU", "CA", "SA", "IT", "TR", "ZA", "NZ", "DE"],
          y: [537, 167, 121, 43, 22, 20, 17, 16, 15, 14],
          type: "bar",
          xaxis: "x",
          yaxis: "y",
        },
        {
          hoverinfo: "x+y",
          hovertext: [
            "GB",
            "US",
            "AU",
            "CA",
            "ZA",
            "TR",
            "IT",
            "SA",
            "ZW",
            "NG",
            "RU",
            "NZ",
            "UG",
            "ES",
            "DE",
            "DK",
            "SE",
            "KG",
            "BR",
            "HK",
            "JO",
            "IE",
            "RO",
            "NO",
            "BJ",
            "MW",
            "CL",
            "CN",
            "ZM",
            "PK",
            "BH",
            "FR",
            "NL",
            "FI",
          ],
          legendgroup: "Others",
          marker: {
            color: [
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
              "#943BFF",
              "#B78EE5",
            ],
            line: {
              width: 0,
            },
          },
          showlegend: false,
          x: ["GB", "US", "AU", "CA", "ZA", "TR", "IT", "SA", "ZW", "NG"],
          y: [31913, 15654, 8810, 4134, 2231, 1715, 1626, 1224, 1084, 1008],
          type: "bar",
          xaxis: "x2",
          yaxis: "y2",
        },
      ],
      layout: {
        template: {
          data: {
            bar: [
              {
                error_x: {
                  color: "#2a3f5f",
                },
                error_y: {
                  color: "#2a3f5f",
                },
                marker: {
                  line: {
                    color: "#E5ECF6",
                    width: 0.5,
                  },
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "bar",
              },
            ],
            barpolar: [
              {
                marker: {
                  line: {
                    color: "#E5ECF6",
                    width: 0.5,
                  },
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "barpolar",
              },
            ],
            carpet: [
              {
                aaxis: {
                  endlinecolor: "#2a3f5f",
                  gridcolor: "white",
                  linecolor: "white",
                  minorgridcolor: "white",
                  startlinecolor: "#2a3f5f",
                },
                baxis: {
                  endlinecolor: "#2a3f5f",
                  gridcolor: "white",
                  linecolor: "white",
                  minorgridcolor: "white",
                  startlinecolor: "#2a3f5f",
                },
                type: "carpet",
              },
            ],
            choropleth: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "choropleth",
              },
            ],
            contour: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "contour",
              },
            ],
            contourcarpet: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "contourcarpet",
              },
            ],
            heatmap: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "heatmap",
              },
            ],
            heatmapgl: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "heatmapgl",
              },
            ],
            histogram: [
              {
                marker: {
                  pattern: {
                    fillmode: "overlay",
                    size: 10,
                    solidity: 0.2,
                  },
                },
                type: "histogram",
              },
            ],
            histogram2d: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "histogram2d",
              },
            ],
            histogram2dcontour: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "histogram2dcontour",
              },
            ],
            mesh3d: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                type: "mesh3d",
              },
            ],
            parcoords: [
              {
                line: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "parcoords",
              },
            ],
            pie: [
              {
                automargin: true,
                type: "pie",
              },
            ],
            scatter: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatter",
              },
            ],
            scatter3d: [
              {
                line: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatter3d",
              },
            ],
            scattercarpet: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattercarpet",
              },
            ],
            scattergeo: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattergeo",
              },
            ],
            scattergl: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattergl",
              },
            ],
            scattermapbox: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scattermapbox",
              },
            ],
            scatterpolar: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterpolar",
              },
            ],
            scatterpolargl: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterpolargl",
              },
            ],
            scatterternary: [
              {
                marker: {
                  colorbar: {
                    outlinewidth: 0,
                    ticks: "",
                  },
                },
                type: "scatterternary",
              },
            ],
            surface: [
              {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
                colorscale: [
                  [0, "#0d0887"],
                  [0.1111111111111111, "#46039f"],
                  [0.2222222222222222, "#7201a8"],
                  [0.3333333333333333, "#9c179e"],
                  [0.4444444444444444, "#bd3786"],
                  [0.5555555555555556, "#d8576b"],
                  [0.6666666666666666, "#ed7953"],
                  [0.7777777777777778, "#fb9f3a"],
                  [0.8888888888888888, "#fdca26"],
                  [1, "#f0f921"],
                ],
                type: "surface",
              },
            ],
            table: [
              {
                cells: {
                  fill: {
                    color: "#EBF0F8",
                  },
                  line: {
                    color: "white",
                  },
                },
                header: {
                  fill: {
                    color: "#C8D4E3",
                  },
                  line: {
                    color: "white",
                  },
                },
                type: "table",
              },
            ],
          },
          layout: {
            annotationdefaults: {
              arrowcolor: "#2a3f5f",
              arrowhead: 0,
              arrowwidth: 1,
            },
            autotypenumbers: "strict",
            coloraxis: {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
            },
            colorscale: {
              diverging: [
                [0, "#8e0152"],
                [0.1, "#c51b7d"],
                [0.2, "#de77ae"],
                [0.3, "#f1b6da"],
                [0.4, "#fde0ef"],
                [0.5, "#f7f7f7"],
                [0.6, "#e6f5d0"],
                [0.7, "#b8e186"],
                [0.8, "#7fbc41"],
                [0.9, "#4d9221"],
                [1, "#276419"],
              ],
              sequential: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              sequentialminus: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
            },
            colorway: [
              "#636efa",
              "#EF553B",
              "#00cc96",
              "#ab63fa",
              "#FFA15A",
              "#19d3f3",
              "#FF6692",
              "#B6E880",
              "#FF97FF",
              "#FECB52",
            ],
            font: {
              color: "#2a3f5f",
            },
            geo: {
              bgcolor: "white",
              lakecolor: "white",
              landcolor: "#E5ECF6",
              showlakes: true,
              showland: true,
              subunitcolor: "white",
            },
            hoverlabel: {
              align: "left",
            },
            hovermode: "closest",
            mapbox: {
              style: "light",
            },
            paper_bgcolor: "white",
            plot_bgcolor: "#E5ECF6",
            polar: {
              angularaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              bgcolor: "#E5ECF6",
              radialaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
            },
            scene: {
              xaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
              yaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
              zaxis: {
                backgroundcolor: "#E5ECF6",
                gridcolor: "white",
                gridwidth: 2,
                linecolor: "white",
                showbackground: true,
                ticks: "",
                zerolinecolor: "white",
              },
            },
            shapedefaults: {
              line: {
                color: "#2a3f5f",
              },
            },
            ternary: {
              aaxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              baxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
              bgcolor: "#E5ECF6",
              caxis: {
                gridcolor: "white",
                linecolor: "white",
                ticks: "",
              },
            },
            title: {
              x: 0.05,
            },
            xaxis: {
              automargin: true,
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
              title: {
                standoff: 15,
              },
              zerolinecolor: "white",
              zerolinewidth: 2,
            },
            yaxis: {
              automargin: true,
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
              title: {
                standoff: 15,
              },
              zerolinecolor: "white",
              zerolinewidth: 2,
            },
          },
        },
        xaxis: {
          anchor: "y",
          domain: [0.43333333333333335, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        yaxis: {
          anchor: "x",
          domain: [0.575, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        xaxis2: {
          anchor: "y2",
          domain: [0.43333333333333335, 1],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        yaxis2: {
          anchor: "x2",
          domain: [0, 0.425],
          gridcolor: "rgb(248, 235, 247)",
          tickfont: {
            size: 13,
            family: "Open Sans",
          },
          zeroline: false,
          showgrid: false,
        },
        annotations: [
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Total Passengers",
            x: 0.14166666666666666,
            xanchor: "center",
            xref: "paper",
            y: 1,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Others Breakdown",
            x: 0.7166666666666667,
            xanchor: "center",
            xref: "paper",
            y: 1,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Total Revenue",
            x: 0.14166666666666666,
            xanchor: "center",
            xref: "paper",
            y: 0.425,
            yanchor: "bottom",
            yref: "paper",
          },
          {
            font: {
              size: 18,
              family: "Open Sans",
            },
            showarrow: false,
            text: "Others Breakdown",
            x: 0.7166666666666667,
            xanchor: "center",
            xref: "paper",
            y: 0.425,
            yanchor: "bottom",
            yref: "paper",
          },
        ],
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 60,
          pad: 8,
        },
        title: {
          font: {
            family: "Open Sans",
            size: 23,
          },
          text: "YY",
          x: 0.5,
        },
        paper_bgcolor: "rgb(32, 32, 68)",
        plot_bgcolor: "rgb(32, 32, 68)",
        showlegend: false,
        autosize: false,
        height: 508,
        width: 512,
        bargap: 0,
        id: "8ba4316f-976d-4477-98e5-9e9c3bf9804d",
      },
    },
    story_text: {
      main_card: {
        content:
          "AE based Host sales make up 46% of total passengers at an average fare of $103 and a revenue of $317749.\nAE based Comp sales make up 30% of total passengers at an average fare of $96 and a revenue of $193910.",
        title: "Graph Text",
      },
    },
  };
  return (
    <Card variant="secondary" title="Distribution Channels">
      <div className={styles.container}>
        <Card variant="primary" width={"100%"} height={"100%"}>
          <PlotlyComponent
            data={data.fig_comp.data}
            layout={data.fig_comp.layout}
            minHeight={524}
          />
        </Card>
        <Card variant="primary" width={"100%"} height={"100%"}>
          <PlotlyComponent
            data={data.fig_host.data}
            layout={data.fig_host.layout}
            minHeight={524}
          />
        </Card>
      </div>
    </Card>
  );
}
