/* eslint-disable react-hooks/exhaustive-deps */
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import { FilterContext } from "context-api/FilterContext";
import { NetworkSchedulingConnectivityMapTableClient } from "lib/api/msd/networkSchedulingConnectivityMapTable/NetworkSchedulingConnectivityMapTable";
import Plotly from "components/common/Charts/Plotly";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import MiniCard from "components/common/MiniCard/MiniCard";
import images from "constans/images";
import Dropdown from "components/common/Dropdown/Dropdown";
import moment from "moment";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import SelectMenu from "components/common/SelectMenu";
import { IOption } from "types/global";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";
import Skeleton from "components/common/Skeleton";

interface IMarketConnectivityMap {
  filterIsOpen: boolean;
  selectedYear: string;
  setSelectedYear: Dispatch<SetStateAction<string>>;
  setNetworkTable: Dispatch<SetStateAction<ICustomAtarevTable>>;
  setNetworkTableLoader: React.Dispatch<React.SetStateAction<boolean>>;
  storyText: {
    network_connectivity: {
      content: string;
      title: string;
    };
    network_table: {
      content: string;
      title: string;
    };
  };
  setStoryText: Dispatch<
    SetStateAction<{
      network_connectivity: {
        content: string;
        title: string;
      };
      network_table: {
        content: string;
        title: string;
      };
    }>
  >;
}

export default function MarketConnectivityMap({
  filterIsOpen,
  setNetworkTable,
  setNetworkTableLoader,
  selectedYear,
  setSelectedYear,
  storyText,
  setStoryText,
}: IMarketConnectivityMap) {
  const [data, setData] = useState<any>([]);
  const [layout, setLayout] = useState<any>({});
  const [selectedComp, setSelectedComp] = useState<string[]>([]);
  const [selectOptionsComp, setSelectOptionsComp] = useState<string[]>([]);
  const [miniKpis, setMiniKpis] = useState<any>();
  const [colorScale, setColorScale] = useState([]);
  const [legendScaleMap, setLegendScaleMap] = useState<number[]>([]);
  const [inboundButton, setInboundButton] = useState(true);
  const [outboundButton, setOutboundButton] = useState(true);
  const [getCarriers, setGetCarriers] = useState();
  const [selectedCompError, setSelectedCompError] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [rangeLoader, setRangeLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const { theme } = useContext(ThemeContext);
  const { filterList } = useContext(FilterContext);
  const wrapperRef: any = useRef(null);

  useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setSelectedComp([JSON.parse(data).clientCode]);
      setSelectOptionsComp([
        JSON.parse(data).clientCode,
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList]);

  useEffect(() => {
    if (selectedComp.length === 0) {
      setSelectedCompError(true);
    } else {
      setSelectedCompError(false);
    }
  }, [selectedComp]);

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "yearly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const values = response?.values?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setOptions(values);
        setSelectedYear(moment(response.default_date).format("YYYY"));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && selectedYear !== "" && selectedComp.length > 0) {
      setSkeletonLoader(true);
      setNetworkTableLoader(true);
      NetworkSchedulingConnectivityMapTableClient.fetchNetworkSchedulingConnectivityMapTable(
        {
          filterList,
          selected_year: selectedYear,
          picked_airline: selectedComp.join(),
          bound_selection: getInOutBoundButtonValue(),
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setData(response.fig.data);
          setLegendScaleMap(response.legend_scale);
          setLayout(response.fig.layout);
          setNetworkTable({
            data: response.table.data,
            columns: getColumns(response.table.labels),
          });
          setMiniKpis(response.mini_kpis);
          const convertedData: any = {};
          const carriersData = response?.carriers;
          for (const key in carriersData) {
            convertedData[key] = { color: carriersData[key], name: `${key}` };
          }
          setGetCarriers(convertedData);
          setStoryText(response?.story_text);
        })
        .catch(() => {
          setNetworkTable({
            data: [],
            columns: [],
          });
          setData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
            setNetworkTableLoader(false);
          }, 2000);
        });
    }
  }, [
    filterIsOpen,
    selectedYear,
    selectedComp,
    inboundButton,
    outboundButton,
    theme,
  ]);

  useEffect(() => {
    if (Array.isArray(data) && data[0]?.marker && data[0]?.marker?.colorscale) {
      const array: any = [];
      data[0]?.marker?.colorscale.forEach((element: any) => {
        array.push(element[1]);
      });
      setColorScale(array);
    }
  }, [data]);

  const getColumns = (labels) => {
    const array: any = [];
    labels?.forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (field !== "dom_op_al_code") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip title={row[field]?.displayVal ?? row[field]}>
                <span className={"rdb-custom-cell"}>
                  {row[field]?.displayVal ?? row[field]}
                </span>
              </Tooltip>
            ),
            valueGetter: (row) => row[field]?.value ?? row[field],
          };
          array.push(obj);
        } else if (field === "dom_op_al_code") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip title={row[field]?.value}>
                <div
                  className={styles.custom_table_row}
                  style={{
                    backgroundColor: row[field]?.color || "rgba(255,0,0,0.2)",
                  }}
                >
                  <span className={styles.row}>{row[field]?.value}</span>
                </div>
              </Tooltip>
            ),
            valueGetter: (row) => row[field]?.value ?? row[field],
          };
          array.push(obj);
        }
      }
    });
    return array;
  };

  const getLegendScale = (value) => {
    if (value !== undefined) {
      return value;
    }
  };

  const getInOutBoundButtonValue = () => {
    if (inboundButton && outboundButton) {
      return ["inbound", "outbound"];
    } else if (inboundButton && outboundButton === false) {
      return ["inbound"];
    } else if (inboundButton === false && outboundButton) {
      return ["outbound"];
    } else {
      setInboundButton(true);
      return ["inbound"];
    }
  };

  return (
    <Card variant="secondary">
      <div className={styles.container}>
        <div className={styles.card_header}>
          <span className={styles.card_title} data-theme={theme}>
            Market Connectivity Map
            {/* <CardInfo
              title={storyText?.network_connectivity?.title ?? null}
              description={storyText?.network_connectivity?.content ?? null}
            /> */}
          </span>
          <div className={styles.options_wrapper} ref={wrapperRef}>
            <div className={styles.buttons_wrapper} data-theme={theme}>
              <span
                className={styles.inbound_button}
                data-active={inboundButton}
                onClick={() => setInboundButton(!inboundButton)}
                data-theme={theme}
              >
                Inbound
              </span>
              <span
                className={styles.outbound_button}
                data-active={outboundButton}
                onClick={() => setOutboundButton(!outboundButton)}
                data-theme={theme}
              >
                Outbound
              </span>
            </div>
            <div className={styles.dropdown_wrapper}>
              <Dropdown
                data={selectOptionsComp}
                added={selectedComp}
                setAdded={setSelectedComp}
                width={200}
                border
                getCarriers={getCarriers}
                required={selectedCompError}
              />
            </div>
            {rangeLoader ? (
              <Skeleton width={90} height={32} sx={{ marginLeft: 3 }} />
            ) : (
              <SelectMenu
                options={options}
                value={{
                  label: selectedYear,
                  value: selectedYear,
                  disabled: false,
                }}
                onSelect={(value) => {
                  setSelectedYear(value?.value ?? "");
                }}
                placeholder=""
                style={{
                  width: 90,
                  minWidth: 90,
                  borderRadius: 16,
                  marginLeft: 32,
                }}
              />
            )}
          </div>
        </div>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={580} />
        ) : (
          <Card variant="primary">
            <div className={styles.gradient_line_wrapper}>
              <p className={styles.legend_title} data-theme={theme}>
                Total Revenue
              </p>
              <span
                className={styles.line}
                style={{
                  borderImageSource: `linear-gradient(90deg, ${colorScale.join()} )`,
                }}
              />
              <span className={styles.text} data-theme={theme}>
                <span>{getLegendScale(legendScaleMap[0])}</span>
                <span>
                  {getLegendScale(
                    legendScaleMap[Math.round(legendScaleMap?.length / 4)]
                  )}
                </span>
                <span>
                  {getLegendScale(
                    legendScaleMap[Math.round(legendScaleMap?.length / 2)]
                  )}
                </span>
                <span>
                  {getLegendScale(legendScaleMap[legendScaleMap.length - 1])}
                </span>
              </span>
            </div>
            <div style={{ height: 430 }}>
              <Plotly
                data={data}
                layout={layout}
                margin={{ r: 0, l: 0, t: 0, b: 0, p: 4 }}
                height={430}
              />
            </div>
            <div className={styles.metrics_wrapper}>
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#ec4252"}
                title={miniKpis ? miniKpis[0]?.title : ""}
                total={miniKpis ? miniKpis[0]?.value : ""}
              />
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#9c00ed"}
                title={miniKpis ? miniKpis[1]?.title : ""}
                total={miniKpis ? miniKpis[1]?.value : ""}
              />
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#144aff"}
                title={miniKpis ? miniKpis[2]?.title : ""}
                total={miniKpis ? miniKpis[2]?.value : ""}
              />
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#da7706"}
                title={miniKpis ? miniKpis[3]?.title : ""}
                total={miniKpis ? miniKpis[3]?.value : ""}
              />
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#5504d9"}
                title={miniKpis ? miniKpis[4]?.title : ""}
                total={miniKpis ? miniKpis[4]?.value : ""}
              />
              <MiniCard
                width={166}
                height={72}
                icon={images.travel_airplane}
                iconBackgroundColor={"#00ad92"}
                title={miniKpis ? miniKpis[5]?.title : ""}
                total={miniKpis ? miniKpis[5]?.value : ""}
              />
            </div>
          </Card>
        )}
      </div>
    </Card>
  );
}
