/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { FareRevenueRevenueTrendsClient } from "lib/api/msd/fareRevenueRevenueTrends/fareRevenueRevenueTrends";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import Plotly from "components/common/Charts/Plotly";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./MonthlyRevenueAnalysis.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface IMonthlyRevenueAnalysis {
  filterIsOpen: boolean;
}

export default function MonthlyRevenueAnalysis({
  filterIsOpen,
}: IMonthlyRevenueAnalysis) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [rangeValue, setRangeValue] = useState({
    value: [23, 27],
    min: 0,
    max: 0,
    dateList: [],
  });
  const [rangeLoader, setRangeLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [currency, setCurrency] = useState<string>("");
  const [storyText, setStoryText] = useState({
    main_card: {
      content: "",
      title: "",
    },
  });
  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: [response.start_idx, response.end_idx],
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      setSkeletonLoader(true);
      FareRevenueRevenueTrendsClient.fetchFareRevenueRevenueTrends({
        filterList,
        graphCarriers: selectedCarriers.join(),
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setChartData(response.fig.data);
          setLayout(response.fig.layout);
          setCarriersJson(response?.carriers);
          setStoryText(response?.story_text);
          setCurrency(response?.currency ?? "");
        })
        .catch(() => {
          setChartData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList, selectedCarriers, rangeValue.value, theme]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterList.mainCompetitor, filterList.selectedCompetitors, filterIsOpen]);

  return (
    <Card
      variant="secondary"
      title="Monthly Revenue Analysis"
      width={"100%"}
      height={488}
      cardInfo={{
        title: storyText?.main_card?.title ?? null,
        description: storyText?.main_card?.content ?? null,
      }}
    >
      <div className={styles.carriers_wrapper}>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={48} />
        ) : (
          <Carriers
            data={GetCarriers()}
            added={selectedCarriers}
            setAdded={setSelectedCarriers}
            carriersJson={carriersJson}
          />
        )}
      </div>
      <div className={styles.container}>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={303} />
        ) : (
          <Card variant="primary" height={303}>
            <div className={styles.charts_wrapper}>
              <p className={styles.chart_title}>Revenue ({currency})</p>
              <div style={{ height: 212 }}>
                <Plotly
                  data={chartData}
                  height={212}
                  layout={layout}
                  margin={{ t: 10, b: 20, l: 30, r: 0, p: 4 }}
                />
              </div>
            </div>
          </Card>
        )}
        {rangeLoader ? (
          <Skeleton width={"100%"} height={46} />
        ) : (
          <Card variant="primary">
            <div className={styles.range_wrapper}>
              <RangeSlider
                data={{
                  max: rangeValue.max,
                  min: rangeValue.min,
                  value: rangeValue.value,
                  dateList: rangeValue.dateList,
                  setValue: setRangeValue,
                }}
              />
            </div>
          </Card>
        )}
      </div>
    </Card>
  );
}
