/* eslint-disable react-hooks/exhaustive-deps */
import AgencyPerformanceQuadrant from "components/msd/AgencyPerformanceQuadrant/AgencyPerformanceQuadrant";
import AgencyPerformanceTrends from "components/msd/AgencyPerformanceTrends/AgencyPerformanceTrends";
import ClassRBDBreakdown from "components/msd/ClassRBDBreakdown/ClassRBDBreakdown";
import DaysToDepartureBreakdown from "components/msd/DaysToDepartureBreakdown/DaysToDepartureBreakdown";
import DowRevenueBreakdown from "components/msd/DOWRevenueBreakdown/DowRevenueBreakdown";
import KPIMini from "components/msd/KPIMini/KPIMini";
import PassengerVolume from "components/msd/PassengerVolume/PassengerVolume";
import TicketTypeBreakdown from "components/msd/TicketTypeBreakdown/TicketTypeBreakdown";
import { useEffect, useState } from "react";
import Bookings from "components/msd/Bookings/Bookings";
import FareRevenue from "components/msd/FareRevenue/FareRevenue";
import Layout from "components/common/Layout/Layout";

export default function AGENCYANALYSIS() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [graphData, setGraphData] = useState<any>({});
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [passengerVolume, setPassengerVolume] = useState({
    data: [],
    layout: {},
  });
  const [ticketTypeBreakdown, setTicketTypeBreakdown] = useState({
    data: [],
    layout: {},
  });
  const [daysToDepartureBreakdown, setDaysToDepartureBreakdown] = useState({
    data: [],
    layout: {},
  });
  const [dowRevenueBreakdown, setDowRevenueBreakdown] = useState({
    data: [],
    layout: {},
  });
  const [classRBDBreakdown, setClassRBDBreakdown] = useState({
    competitors: [
      {
        carrier: "",
        fig: {
          data: [],
          layout: {},
        },
      },
    ],
    host: [
      {
        carrier: "",
        fig: {
          data: [],
          layout: {},
        },
      },
    ],
  });
  const [paxTrends, setPaxTrends] = useState({ data: [], layout: {} });
  const [fareTrends, setFareTrends] = useState({ data: [], layout: {} });

  const [selectedYearmonth, setSelectedYearMonth] = useState("");
  const [yearMonthly, setyearMonthly] = useState(true);
  const [forward, setForward] = useState(true);
  const [historical, setHistorical] = useState(true);
  const [storyText, setStoryText] = useState({
    bookings: {
      content: "",
      title: "",
    },
    class_rbd: {
      content: "",
      title: "",
    },
    days_to_departure: {
      content: "",
      title: "",
    },
    dow_revenue: {
      content: "",
      title: "",
    },
    fare_revenue: {
      content: "",
      title: "",
    },
    main_card: {
      content: "",
      title: "",
    },
    passenger_volume: {
      content: "",
      title: "",
    },
    ticket_type: {
      content: "",
      title: "",
    },
  });

  useEffect(() => {
    if (graphData.fig_fare_trends && yearMonthly) {
      setFareTrends({
        data: graphData.fig_fare_trends.data,
        layout: graphData.fig_fare_trends.layout,
      });
    } else {
      setFareTrends({
        data: [],
        layout: {},
      });
    }
    if (graphData.fig_pax_trends && yearMonthly) {
      setPaxTrends({
        data: graphData.fig_pax_trends.data,
        layout: graphData.fig_pax_trends.layout,
      });
    } else {
      setPaxTrends({ data: [], layout: {} });
    }
    if (graphData.fig_carrier_bd) {
      setPassengerVolume({
        data: graphData.fig_carrier_bd.data,
        layout: graphData.fig_carrier_bd.layout,
      });
    } else {
      setPassengerVolume({ data: [], layout: {} });
    }
    if (graphData.fig_ttype_bd) {
      setTicketTypeBreakdown({
        data: graphData.fig_ttype_bd.data,
        layout: graphData.fig_ttype_bd.layout,
      });
    } else {
      setTicketTypeBreakdown({ data: [], layout: {} });
    }
    if (graphData.fig_dtd_bd) {
      setDaysToDepartureBreakdown({
        data: graphData.fig_dtd_bd.data,
        layout: graphData.fig_dtd_bd.layout,
      });
    } else {
      setDaysToDepartureBreakdown({ data: [], layout: {} });
    }
    if (graphData.fig_dow_bd) {
      setDowRevenueBreakdown({
        data: graphData.fig_dow_bd.data,
        layout: graphData.fig_dow_bd.layout,
      });
    } else {
      setDowRevenueBreakdown({ data: [], layout: {} });
    }
    if (graphData.fig_rbd_bd) {
      setClassRBDBreakdown(graphData.fig_rbd_bd);
    } else {
      setClassRBDBreakdown({ competitors: [], host: [] });
    }
  }, [graphData]);
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Agency Analysis"
    >
      <KPIMini filterIsOpen={filterIsOpen} />

      <AgencyPerformanceQuadrant
        filterIsOpen={filterIsOpen}
        selectedYearmonth={selectedYearmonth}
        forward={forward}
        setForward={setForward}
        historical={historical}
        setHistorical={setHistorical}
        yearMonthly={yearMonthly}
        setYearMonthly={setyearMonthly}
      />

      <AgencyPerformanceTrends
        filterIsOpen={filterIsOpen}
        setGraphData={setGraphData}
        setGraphLoader={setSkeletonLoader}
        setSelectedYearMonth={setSelectedYearMonth}
        yearMonthly={yearMonthly}
        forward={forward}
        setForward={setForward}
        historical={historical}
        setHistorical={setHistorical}
        setStoryText={setStoryText}
      />
      {yearMonthly && (
        <div style={{ display: "flex", gap: 8 }}>
          <Bookings
            data={paxTrends}
            yearMonthly={yearMonthly}
            storyText={storyText}
            skeletonLoader={skeletonLoader}
          />
          <FareRevenue
            data={fareTrends}
            yearMonthly={yearMonthly}
            storyText={storyText}
            skeletonLoader={skeletonLoader}
          />
        </div>
      )}
      <div style={{ display: "flex", gap: 8 }}>
        <TicketTypeBreakdown
          data={ticketTypeBreakdown}
          storyText={storyText}
          skeletonLoader={skeletonLoader}
        />
        <DaysToDepartureBreakdown
          data={daysToDepartureBreakdown}
          storyText={storyText}
          skeletonLoader={skeletonLoader}
        />
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        <DowRevenueBreakdown
          data={dowRevenueBreakdown}
          storyText={storyText}
          skeletonLoader={skeletonLoader}
        />
        <PassengerVolume
          data={passengerVolume}
          storyText={storyText}
          skeletonLoader={skeletonLoader}
        />
      </div>
      <ClassRBDBreakdown
        data={classRBDBreakdown}
        storyText={storyText}
        skeletonLoader={skeletonLoader}
      />
    </Layout>
  );
}
