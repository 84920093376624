// import DropdownText from "components/common/Dropdown/DropdownText";
import styles from "./styles.module.css";
import React, { useState } from "react";
import Card from "components/common/Card/Card";
import { IoSettingsOutline } from "react-icons/io5";
import { PiChartBar } from "react-icons/pi";
import { ImArrowUp, ImArrowDown } from "react-icons/im";
import { IoAlarmOutline } from "react-icons/io5";
import { StyleSheet, css } from "aphrodite";
import CustomAtarevTable from "components/common/Table/CustomTable";
import images from "constans/images";
import alertData from "../../../constans/alert-rules-table-data.json";
import alertsReviewData from "../../../constans/alert-rules-table-alerts-review.json";
import { CiStar } from "react-icons/ci";
import { IoFootballOutline } from "react-icons/io5";
import { SiGotomeeting } from "react-icons/si";
import { CiMusicNote1 } from "react-icons/ci";
import { IoBusinessOutline } from "react-icons/io5";
import { IoSchoolOutline } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { Tooltip } from "components/common/Tooltip";
import moment from "moment";
import LazyLoad from "react-lazyload";

const icons = {
  national: {
    color: "#9c00ed",
    jsx: <CiStar size={24} />,
  },
  sport: {
    color: "#00ad92",
    jsx: <IoFootballOutline size={24} />,
  },
  conferences: {
    color: "#5504d9",
    jsx: <SiGotomeeting size={24} />,
  },
  concerts: {
    color: "#259eff",
    jsx: <CiMusicNote1 size={24} />,
  },
  trade_shows: {
    color: "#d77cbb",
    jsx: <IoBusinessOutline size={24} />,
  },
  school: {
    color: "#da7706",
    jsx: <IoSchoolOutline size={24} />,
  },
};

const Alerts = () => {
  const initial: any = alertData;
  const initialReview: any = alertsReviewData;
  const formatNumber = (number) => {
    const formattedNumber = number?.toString()?.padStart(8, "0"); // Sayıyı sekiz haneli bir stringe dönüştür
    const formattedString = `${formattedNumber?.slice(
      0,
      4
    )}-${formattedNumber?.slice(4, 6)}-${formattedNumber?.slice(6, 8)}`;
    return formattedString;
  };
  const [isCustomPeriod, setIsCustomPeriod] = useState(false);
  const [data] = useState<any[]>(initial?.data ?? []);
  const [dataReview] = useState<any[]>(initialReview?.data ?? []);
  const [columns] = useState<
    {
      name: string;
      field: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
    }[]
  >([
    {
      name: "EVENT",
      field: "event",
      cell: (row) => (
        <span className={"rdb-custom-cell"}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: icons[row?.type ?? ""]?.color ?? "red",
                padding: 8,
                marginRight: 8,
                borderRadius: 12,
              }}
            >
              {icons[row?.type ?? ""]?.jsx ?? ""}
            </span>
            <span>
              <Tooltip title={row["event"] ?? ""}>
                <span className={styles.long_text}>
                  {row["event"]?.toUpperCase()}
                </span>
              </Tooltip>
              <br />
              <span
                style={{
                  color: "var(--text-color-secondary)",
                  fontFamily: "var(--font-family-light)",
                  fontSize: 12,
                }}
              >
                {`${moment(formatNumber(row["start_date"])).format(
                  "DD MMM"
                )} - ${moment(formatNumber(row["end_date"])).format("DD MMM")}`}
              </span>
            </span>
          </span>
        </span>
      ),
    },

    {
      name: "TYPE",
      field: "type",
      cell: (row) => <span className={"rdb-custom-cell"}>{row["type"]}</span>,
    },
    {
      name: "LOCATION",
      field: "city",
      cell: (row) => <span className={"rdb-custom-cell"}>{row["city"]}</span>,
    },
    {
      name: "POC",
      field: "country",
      cell: (row) => (
        <span className={"rdb-custom-cell"}>{row["country"]}</span>
      ),
    },
    {
      name: "PU PAX",
      field: "pax",
      cell: (row) => (
        <span
          className={"rdb-custom-cell"}
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          <span style={{ fontSize: 24 }}>
            {`${generateRandomNumber(1, 10)}%`}
          </span>
          {generateRandomNumber() ? (
            <span style={{ position: "relative", top: -2 }}>
              <ImArrowUp color="#00ad92" size={12} />
            </span>
          ) : (
            <span style={{ position: "relative", top: -2 }}>
              <ImArrowDown color="#ec4252" size={12} />
            </span>
          )}
        </span>
      ),
    },
  ]);
  const [columnsReview] = useState<
    {
      name: string;
      field: string;
      cell: (row: any) => JSX.Element;
      width?: string | number;
    }[]
  >([
    {
      name: "EVENT",
      field: "event",
      cell: (row) => (
        <span className={"rdb-custom-cell"}>
          <span style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                backgroundColor: icons[row?.type ?? ""]?.color ?? "red",
                padding: 8,
                marginRight: 8,
                borderRadius: 12,
              }}
            >
              {icons[row?.type ?? ""]?.jsx ?? ""}
            </span>
            <span>
              <Tooltip title={row["event"] ?? ""}>
                <span className={styles.long_text}>
                  {row["event"]?.toUpperCase()}
                </span>
              </Tooltip>

              <br />
              <span
                style={{
                  color: "var(--text-color-secondary)",
                  fontFamily: "var(--font-family-light)",
                  fontSize: 12,
                }}
              >
                {`${moment(formatNumber(row["start_date"])).format(
                  "DD MMM"
                )} - ${moment(formatNumber(row["end_date"])).format("DD MMM")}`}
              </span>
            </span>
          </span>
        </span>
      ),
    },

    {
      name: "TYPE",
      field: "type",
      cell: (row) => <span className={"rdb-custom-cell"}>{row["type"]}</span>,
    },
    {
      name: "LOCATION",
      field: "city",
      cell: (row) => <span className={"rdb-custom-cell"}>{row["city"]}</span>,
    },
    {
      name: "POC",
      field: "country",
      cell: (row) => (
        <span className={"rdb-custom-cell"}>{row["country"]}</span>
      ),
    },
    {
      name: "DOW",
      field: "dow",
      cell: (row) => <span className={"rdb-custom-cell"}>{row["dow"]}</span>,
    },
    {
      name: "DTE",
      field: "dte",
      cell: (row) => <span className={"rdb-custom-cell"}></span>,
    },
    {
      name: "PU PAX",
      field: "pax",
      cell: (row) => (
        <span
          className={"rdb-custom-cell"}
          style={{ display: "flex", alignItems: "center", gap: 8 }}
        >
          <span style={{ fontSize: 24 }}>
            {`${generateRandomNumber(1, 10)}%`}
          </span>
          {generateRandomNumber() ? (
            <span style={{ position: "relative", top: -2 }}>
              <ImArrowUp color="#00ad92" size={12} />
            </span>
          ) : (
            <span style={{ position: "relative", top: -2 }}>
              <ImArrowDown color="#ec4252" size={12} />
            </span>
          )}
        </span>
      ),
    },
  ]);

  const generateRandomNumber = (max: number = 0, min: number = 0) => {
    let randomNumber = 0;
    if (max > 0 && min > 0) {
      randomNumber = Math.floor(Math.random() * (max - min + 1) + min);
    } else {
      randomNumber = Math.floor(Math.random() * 2);
    }
    return randomNumber;
  };
  const [isActive, setIsActive] = useState("lf");
  const getPosition = () => {
    if (isActive === "pax") {
      return 6;
    } else if (isActive === "avg") {
      return 64;
    } else if (isActive === "revenue") {
      return 142;
    } else if (isActive === "lf") {
      return 232;
    } else return 0;
  };
  const main_watched = StyleSheet.create({
    chart_color: {
      ":before": {
        background: `linear-gradient(to right, #9c00ed,#FFF1);`,
      },
    },
    chart_in_color: {
      ":before": {
        background: `conic-gradient(rgba(255, 255, 255, 0.15) calc(10 * 1%), #0000 0)`,
      },
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.world_image}>
        <LazyLoad height={200} offset={100}>
          <img src={images.world} alt="" loading="lazy" />
        </LazyLoad>
      </div>
      <div className={styles.header}>
        {/* <div className={styles.dropdown_wrapper}>
          <DropdownText
            options={["United Kingdom", "All"]}
            defaultSelection="All"
            handleSelectedOption={() => {}}
            width={200}
            border
          />
        </div> */}
        <div className={styles.selected_wrapper}>
          <span
            onClick={() => setIsActive("pax")}
            className={styles.selected_item}
            data-active={isActive === "pax"}
          >
            Pax
          </span>
          <span
            onClick={() => setIsActive("avg")}
            className={styles.selected_item}
            data-active={isActive === "avg"}
          >
            Avg Fare
          </span>
          <span
            onClick={() => setIsActive("revenue")}
            className={styles.selected_item}
            data-active={isActive === "revenue"}
          >
            Revenue
          </span>
          <span
            onClick={() => setIsActive("lf")}
            className={styles.selected_item}
            data-active={isActive === "lf"}
          >
            Load Factor
          </span>
          <span
            className={styles.selected}
            style={{ left: getPosition() }}
          ></span>
        </div>
      </div>
      <div>
        <Card variant="secondary">
          <div className={styles.card_header}>
            <span className={styles.card_title}>Events Dashboard</span>
            <span className={styles.settings_menu_wrapper}>
              <IoSettingsOutline size={24} />
            </span>
          </div>
          <div className={styles.card_body}>
            {[
              {
                title: "Previous Month",
                value: "20",
                kpi: [
                  { color: "#5216ca", value: "56%", description: "of target" },
                  {
                    color: "#ef4351",
                    value: "15%",
                    description: "cap TY vs LY",
                  },
                  {
                    color: "#144aff",
                    value: null,
                    description: "No comp changes",
                  },
                ],
                chart_color: "#360d8b",
                chart_value_color: "rgba(255, 255, 255, 0.15)",
                directory: "down",
              },
              {
                title: "This Month",
                value: "13",
                kpi: [
                  { color: "#5216ca", value: "56%", description: "of target" },
                  {
                    color: "#ef4351",
                    value: "15%",
                    description: "cap TY vs LY",
                  },
                  {
                    color: "#144aff",
                    value: null,
                    description: "No comp changes",
                  },
                ],
                kpi_color: "#ef4351",
                chart_color: "#880d54",
                chart_value_color: "rgba(255, 255, 255, 0.15)",
                directory: "up",
              },
              {
                title: "Next Month",
                value: "10",
                kpi: [
                  { color: "#5216ca", value: "56%", description: "of target" },
                  {
                    color: "#ef4351",
                    value: "15%",
                    description: "cap TY vs LY",
                  },
                  {
                    color: "#144aff",
                    value: null,
                    description: "No comp changes",
                  },
                ],
                chart_color: "#2103a4",
                chart_value_color: "rgba(255, 255, 255, 0.15)",
                directory: "down",
              },
              {
                title: "This Quarter",
                value: "20",
                kpi: [
                  { color: "#5216ca", value: "56%", description: "of target" },
                  {
                    color: "#ef4351",
                    value: "15%",
                    description: "cap TY vs LY",
                  },
                  {
                    color: "#144aff",
                    value: null,
                    description: "No comp changes",
                  },
                ],
                last_year: {
                  title: "Custom Period",
                  value: 12,
                  kpi: [
                    {
                      color: "#5216ca",
                      value: "24%",
                      description: "of target",
                    },
                    {
                      color: "#ef4351",
                      value: "18%",
                      description: "cap TY vs LY",
                    },
                    {
                      color: "#144aff",
                      value: null,
                      description: "No comp changes",
                    },
                    {
                      color: "#2fd9fb",
                      value: null,
                      description: "1 National, 5 Sports, 3 Trade shows",
                    },
                  ],
                },
                chart_color: "#0168cc",
                chart_value_color: "rgba(255, 255, 255, 0.15)",
                directory: "up",
              },
            ].map((el, index) => {
              const customStyle = StyleSheet.create({
                chart_color: {
                  ":before": {
                    // background: `conic-gradient(${el.chart_color} calc(var(--p) * 1%), #0000 0)`,
                    background: `linear-gradient(to right, ${el.chart_color},#FFF1);`,
                  },
                },
                chart_in_color: {
                  ":before": {
                    background: `conic-gradient(${el.chart_value_color} calc(${el.value} * 1%), #0000 0)`,
                  },
                },
              });
              return (
                <div key={index} className={styles.item_card_wrapper}>
                  <div className={styles.item_card_header}>
                    {el.last_year && (
                      <>
                        <IoIosArrowBack
                          size={18}
                          onClick={() => setIsCustomPeriod(false)}
                          style={{ cursor: "pointer" }}
                        />
                        <span style={{ margin: "0 8px", width: 120 }}>
                          {isCustomPeriod ? el?.last_year?.title : el.title}
                        </span>
                        <IoIosArrowForward
                          size={18}
                          onClick={() => setIsCustomPeriod(true)}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                    {!el.last_year && (
                      <span style={{ margin: "0 8px" }}>{el.title}</span>
                    )}
                  </div>
                  <div className={styles.item_card_body}>
                    <div className={styles.item_card_chart}>
                      <span
                        className={`${styles.chart} ${css(
                          customStyle.chart_color
                        )}`}
                      >
                        <span
                          className={`${styles.chart_in} ${css(
                            customStyle.chart_in_color
                          )}`}
                        >
                          <div className={styles.chart_in_wrapper}>
                            <span>
                              <PiChartBar />
                            </span>
                            <span className={styles.chart_in_value}>
                              {el?.last_year ? (
                                <h1 className={styles.chart_value}>
                                  {isCustomPeriod
                                    ? el?.last_year?.value
                                    : el.value}
                                </h1>
                              ) : (
                                <h1 className={styles.chart_value}>
                                  {el.value}
                                </h1>
                              )}

                              <span>
                                <span className={styles.chart_value_icon}>
                                  %
                                </span>
                                <br />
                                <span className={styles.chart_arrow_icon}>
                                  {el.directory === "up" ? (
                                    <ImArrowUp color="#00ad92" size={18} />
                                  ) : (
                                    <ImArrowDown color="#ec4252" size={18} />
                                  )}
                                </span>
                              </span>
                            </span>
                          </div>
                        </span>
                      </span>
                    </div>
                    {(el?.last_year
                      ? isCustomPeriod
                        ? el?.last_year?.kpi
                        : el.kpi
                      : el.kpi
                    ).map((kpi_item, index) => (
                      <div key={index} className={styles.card_kpi}>
                        <span
                          className={styles.kpi_icon}
                          style={{ backgroundColor: kpi_item.color }}
                        >
                          <IoSettingsOutline size={24} />
                        </span>
                        <span className={styles.kpi_value}>
                          {kpi_item.value && <span>{kpi_item.value}</span>}
                          {kpi_item.description.split(",").map((word, i) => (
                            <React.Fragment key={i}>
                              {word.trim()} <br />
                            </React.Fragment>
                          ))}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
      </div>
      <div className={styles.tree_card}>
        <Card variant="secondary" width={"45%"} height={840}>
          <div className={styles.card_header}>
            <span className={styles.card_title}>Followed Events</span>
            <span className={styles.settings_menu_wrapper}>
              <IoSettingsOutline size={24} />
            </span>
          </div>
          <Card variant="primary" height={260}>
            <div className={styles.main_watched_card_body}>
              <span
                className={`${styles.chart} ${css(main_watched.chart_color)}`}
              >
                <span
                  className={`${styles.chart_in} ${css(
                    main_watched.chart_in_color
                  )}`}
                >
                  <div className={styles.chart_in_wrapper}>
                    <span>
                      <PiChartBar />
                    </span>
                    <span className={styles.chart_in_value}>
                      <h1 className={styles.chart_value}>1</h1>
                      <span>
                        <span className={styles.chart_value_icon}>%</span>
                        <br />
                        <span className={styles.chart_arrow_icon}>
                          <ImArrowDown color="#ec4252" size={18} />
                        </span>
                      </span>
                    </span>
                  </div>
                </span>
              </span>
            </div>
          </Card>
          <div className={styles.table_wrapper}>
            <CustomAtarevTable
              data={data}
              columns={columns}
              height={430}
              pagination
            />
          </div>
        </Card>
        <Card variant="secondary" width={"100%"} height={840}>
          <div className={styles.card_header}>
            <span className={styles.card_title}>Alert Review</span>
          </div>
          <Card variant="primary" height={120}>
            <div className={styles.alert_review_body}>
              <IoAlarmOutline size={24} />
              <h1>35</h1>
            </div>
          </Card>
          <div className={styles.table_wrapper}>
            <CustomAtarevTable
              data={dataReview}
              columns={columnsReview}
              height={560}
              pagination
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Alerts;
