/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
// import CardInfo from "components/common/CardInfo/CardInfo";
import Plotly from "components/common/Charts/Plotly";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { ProductOverviewCosBreakdownClient } from "lib/api/msd/productOverviewCosBreakdown/ProductOverviewCosBreakdown";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.css";
import SelectMenu from "components/common/SelectMenu";
import { IOption } from "types/global";
import Skeleton from "components/common/Skeleton";

interface IPointOfSaleBreakdown {
  filterIsOpen: boolean;
  storyText: any;
  setStoryText: Dispatch<
    SetStateAction<{
      productMatrix: {};
      pos: {};
      passenger: {};
      cabin: {};
      channel: {};
    }>
  >;
}

export default function PointOfSaleBreakdown({
  filterIsOpen,
  storyText,
  setStoryText,
}: IPointOfSaleBreakdown) {
  const [data, setData] = useState({
    host: { data: [], layout: {} },
    comp: { data: [], layout: {} },
  });
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);
  const [options, setOptions] = useState<IOption[]>([]);
  const [optionsLoader, setOptionsLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState<IOption>();

  useEffect(() => {
    setOptionsLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "yearly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const values = response?.values?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setOptions(values);
        setSelectedOption({
          label: moment(response?.default_date).format("YYYY"),
          value: moment(response?.default_date).format("YYYY"),
          disabled: false,
        });
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setOptionsLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && selectedOption) {
      setSkeletonLoader(true);
      ProductOverviewCosBreakdownClient.fetchProductOverviewCosBreakdown({
        filterList,
        agg_view: "yearly",
        selected_yearmonth: selectedOption?.value ?? "",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setData({
            host: response.fig_host,
            comp: response.fig_comp,
          });
          setStoryText((state) => ({ ...state, pos: response?.story_text }));
        })
        .catch(() => {
          setData({
            host: { data: [], layout: {} },
            comp: { data: [], layout: {} },
          });
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, selectedOption, theme]);

  return (
    <Card variant="secondary">
      <div className={styles.card_header}>
        <span className={styles.card_title} data-theme={theme}>
          Point of Sale Breakdown
          {/* <CardInfo
            title={storyText?.pos?.main_card?.title ?? null}
            description={storyText?.pos?.main_card?.content ?? null}
          /> */}
        </span>
        {optionsLoader ? (
          <Skeleton width={90} height={32} />
        ) : (
          <SelectMenu
            options={options}
            value={selectedOption}
            onSelect={(value) => {
              setSelectedOption(value);
            }}
            placeholder=""
            style={{
              width: 90,
              minWidth: 90,
              borderRadius: 16,
            }}
          />
        )}
      </div>

      <div className={styles.container}>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={556} />
        ) : (
          <Card variant="primary" width={"100%"}>
            <Plotly
              data={data.host.data}
              layout={data.host.layout}
              height={540}
              margin={{ l: 30, r: 10, t: 60, b: 30, p: 4 }}
            />
          </Card>
        )}
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={556} />
        ) : (
          <Card variant="primary" width={"100%"}>
            <Plotly
              data={data.comp?.data}
              layout={data.comp?.layout}
              height={540}
              margin={{ l: 30, r: 10, t: 60, b: 30, p: 4 }}
            />
          </Card>
        )}
      </div>
    </Card>
  );
}
