/* eslint-disable react-hooks/exhaustive-deps */
// import LastUpdateDates from "components/msd/UpdateDates/UpdateDates";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import LeftMenu from "../LeftMenu/LeftMenu";
import MainFilter from "../MainFilter/MainFilter";
import styles from "./styles.module.css";
import SideDrawer from "../SideDrawer";
import React from "react";
import { ThemeContext } from "context-api/ThemeContext";
import images from "constans/images";
import MenuIcon from "@mui/icons-material/Menu";
import PDFExportButton from "../PDFExportButton";
import Skeleton from "../Skeleton";
import { UserProfileContext } from "context-api/UserProfile";

interface ILayout {
  filterIsOpen: boolean;
  setFilterIsOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  children: React.ReactNode;
  filterHidden?: boolean;
  id?: string;
  ref?: any;
}
export default function Layout({
  filterIsOpen,
  setFilterIsOpen,
  title,
  children,
  filterHidden = false,
}: ILayout) {
  const [logo, setLogo] = useState<any>();
  const { theme } = useContext(ThemeContext);
  const { userLoader } = useContext(UserProfileContext);
  const ref: any = useRef(null);

  useEffect(() => {
    document.title = title;
    defaultFilter().finally(() => {
      setFilterIsOpen(false);
    });
  }, []);

  useEffect(() => {
    setLogo(theme === "dark" ? images.logo : images.logo_light);
  }, [theme]);

  const defaultFilter = async () => {
    const storeUser = localStorage.getItem("user");
    if (storeUser) {
    } else {
      console.log("No default filter selection found");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.left_menu}>
        <LeftMenu />
      </div>
      <div className={styles.side_drawer}>
        <SideDrawer MenuButton={<MenuIcon />}>
          <LeftMenu />
        </SideDrawer>
        <img src={logo} alt="" />
      </div>
      <div className={styles.body}>
        {!filterHidden && (
          <div className={styles.filter_wrapper}>
            {userLoader ? (
              <Skeleton width={"100%"} height={144} />
            ) : (
              <MainFilter
                filterMenu={{
                  isOpen: filterIsOpen,
                  setIsOpen: setFilterIsOpen,
                }}
              />
            )}
          </div>
        )}
        <div className={styles.children} id={"child"} ref={ref}>
          {children}
        </div>
        <PDFExportButton fileName={title} />
      </div>
    </div>
  );
}
