import React, { useContext, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ThemeContext } from "context-api/ThemeContext";

interface IMenu {
  options: {
    title: string | JSX.Element;
    handleClick?: (e: any) => void;
  }[];
  id?: string;
  children: React.ReactNode;
}

const TableMenu = ({ id, options, children }: IMenu) => {
  const { theme } = useContext(ThemeContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <span onClick={handleClick}>{children}</span>
      <Menu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          ".MuiMenu-paper": {
            bgcolor:
              theme === "dark" ? "var(--card-third-color) !important" : "",
            color: theme === "dark" ? "#fff !important" : "",
          },
        }}
      >
        {options.map((item, index) => {
          const { title, handleClick } = item;
          return (
            <MenuItem
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                handleClick && handleClick(e);
                handleClose();
              }}
            >
              {title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default TableMenu;
