/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import Plotly from "components/common/Charts/Plotly";
import styles from "./ClassMix.module.css";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { FareRevenueClassMixClient } from "lib/api/msd/fareRevenueClassMix/fareRevenueClassMix";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface IClassMix {
  filterIsOpen: boolean;
}
export default function ClassMix({ filterIsOpen }: IClassMix) {
  const [rangeValue, setRangeValue] = useState({
    value: 0,
    min: 1,
    max: 20,
    dateList: [],
  });
  const [rangeLoader, setRangeLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [host, setHost] = useState({ data: [], layout: {} });
  const [comp, setComp] = useState<
    { carrier: string; fig: { data: any; layout: any } }[]
  >([]);
  const [activeStep, setActiveStep] = useState(0);
  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          min: 0,
          dateList: response.values,
          value: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      setSkeletonLoader(true);
      FareRevenueClassMixClient.fetchFareRevenueClassMix({
        filterList,
        selected_yearmonth: rangeValue.dateList[rangeValue.value],
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          if (
            Array.isArray(response.fig_host) &&
            response.fig_host.length > 0
          ) {
            setHost({
              data: response?.fig_host[0]?.fig?.data,
              layout: response?.fig_host[0]?.fig?.layout,
            });
          }
          if (
            Array.isArray(response?.fig_comp) &&
            response?.fig_comp?.length > 0
          ) {
            setComp(response.fig_comp);
            setActiveStep(0);
          }
        })
        .catch(() => {
          setHost({ data: [], layout: {} });
          setComp([]);
          setActiveStep(0);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList, rangeValue.value, theme]);
  return (
    <Card
      variant="secondary"
      height={581}
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <div className={styles.card_header}>
        <span className={styles.card_title}>Class Mix</span>
        <div className={styles.button_wrapper}>
          <div className={styles.slider}>
            <span
              className={styles.prev_arrow}
              onClick={() => {
                if (activeStep > 0) {
                  setActiveStep(activeStep - 1);
                } else {
                  setActiveStep(comp.length - 1);
                }
              }}
              data-theme={theme}
            >
              <span className={styles.arrow_left} data-theme={theme}></span>
            </span>
            <span className={styles.slider_title}>
              {`${comp[activeStep]?.carrier ?? "-"}`}
            </span>
            <span
              className={styles.next_arrow}
              onClick={() => {
                if (activeStep < comp?.length - 1) {
                  setActiveStep(activeStep + 1);
                } else {
                  setActiveStep(0);
                }
              }}
              data-theme={theme}
            >
              <span className={styles.arrow_right} data-theme={theme}></span>
            </span>
          </div>
          <div className={styles.active_wrapper}>
            {comp?.map((_, index) => (
              <span
                className={styles.active_slide}
                data-active={activeStep === index}
                data-theme={theme}
                key={index}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.charts_wrapper}>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={420} />
        ) : (
          <Card
            variant="primary"
            titlePosition="center"
            width={"100%"}
            height={420}
          >
            <div className={styles.charts_container}>
              <p className={styles.chart_title}>Class Ratio (%)</p>
              <Plotly
                data={host.data}
                layout={host.layout}
                height={284}
                margin={{ t: 10, b: 50, l: 50, r: 20, p: 4 }}
              />
            </div>
          </Card>
        )}
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={420} />
        ) : (
          <Card
            variant="primary"
            titlePosition="center"
            width={"100%"}
            height={420}
          >
            <div className={styles.charts_container}>
              <p className={styles.chart_title}>Class Ratio (%)</p>
              <Plotly
                data={comp[activeStep]?.fig?.data}
                layout={comp[activeStep]?.fig?.layout}
                height={284}
                margin={{ t: 10, b: 50, l: 50, r: 20, p: 4 }}
              />
            </div>
          </Card>
        )}
      </div>
      {rangeLoader ? (
        <Skeleton width={"100%"} height={46} />
      ) : (
        <div className={styles.range_wrapper}>
          <RangeSlider
            data={{
              max: rangeValue.max,
              min: rangeValue.min,
              value: rangeValue.value,
              dateList: rangeValue.dateList,
              setValue: setRangeValue,
            }}
          />
        </div>
      )}
    </Card>
  );
}
