import styles from "./styles.module.css";
import { useContext, useEffect, useState } from "react";
import { kpiOptions } from "./KpiOptions";
import LineMiniChart from "components/common/Charts/LineMini";
import { ThemeContext } from "context-api/ThemeContext";
import ColoredIcon from "utils/ColoredIcon";
import { ImArrowDown, ImArrowUp } from "react-icons/im";
import { Tooltip } from "components/common/Tooltip";

interface IKPICard {
  minimize?: boolean;
  cardData: any;
}
export default function KPICard({ minimize = false, cardData }: IKPICard) {
  const [metrics, setMetrics] = useState<any>([]);
  const [position, setPosition] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const { theme } = useContext(ThemeContext);

  const style = {
    hover: {
      borderImageSource: `linear-gradient(244deg,${
        kpiOptions[cardData.kpiType ? cardData.kpiType : "revenue"].lineColor
      } , rgba(19, 74, 239, 0) 70%)`,
    },
  };

  useEffect(() => {
    const array: any = [];
    if (cardData.metrics.length > 0) {
      cardData.metrics.forEach((element, index) => {
        if (index !== 0) {
          array.push(element);
        }
      });
      setMetrics(array);
      setPosition(0);
    }
  }, [cardData]);

  if (!minimize) {
    return (
      <div className={styles.container} data-theme={theme}>
        <div className={styles.title_and_icon_wrapper}>
          <span className={styles.card_title} data-theme={theme}>
            {cardData.kpiName}
          </span>
          <ColoredIcon
            imgPath={
              kpiOptions[cardData?.kpiType ? cardData?.kpiType : "revenue"]
                ?.icon
            }
            backgroundColor={
              kpiOptions[cardData?.kpiType ? cardData?.kpiType : "revenue"]
                ?.iconColor
            }
            wrapper={61}
            width={40}
            height={40}
          />
        </div>
        <div className={styles.top_mask} data-theme={theme}>
          <div className={styles.mask_title_wrapper}>
            <span className={styles.mask_title} data-theme={theme}>
              {cardData.metrics[0]?.metricType}
            </span>
            <div className={styles.mask_ratio_wrapper}>
              <span
                className={styles.ratio_text}
                style={{
                  color:
                    cardData.metrics[0]?.metricVsCurrentPrc < 0
                      ? "#ec4252"
                      : "#00ad92",
                }}
              >
                {`${cardData.metrics[0]?.metricVsCurrentPrc}% ${cardData.metrics[0]?.metricDetail}`}
              </span>
              {cardData.metrics[0]?.metricVsCurrentPrc >= 0 ? (
                <ImArrowUp
                  size={12}
                  color="#00ad92"
                  className={styles.ratio_img_green}
                />
              ) : (
                <ImArrowDown
                  size={12}
                  color="#ec4252"
                  className={styles.ratio_img_red}
                />
              )}
            </div>
          </div>
          <div className={styles.mask_content}>
            <span className={styles.price} data-theme={theme}>
              {`${cardData.metrics[0]?.metricSymbol} ${cardData.metrics[0]?.metricValue}`}
            </span>
            <div className={styles.mask_chart}>
              <LineMiniChart
                width={200}
                height={33}
                data={cardData.graphData}
                gradientColorArray={
                  kpiOptions[cardData.kpiType ? cardData.kpiType : "revenue"]
                    .lineColorGradient
                }
                lineColor={
                  kpiOptions[cardData.kpiType ? cardData.kpiType : "revenue"]
                    .lineColor
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.bottom_mask} data-theme={theme}>
          {metrics?.map((element: any) => {
            return (
              <div key={element} className={styles.bottom_mask_content}>
                <span className={styles.bottom_mask_title} data-theme={theme}>
                  {element.metricType}
                </span>
                <span className={styles.bottom_mask_price} data-theme={theme}>
                  {`${element.metricSymbol} ${element.metricValue}`}
                  {element?.metricVsCurrentPrc >= 0 ? (
                    <ImArrowUp
                      size={18}
                      color="#00ad92"
                      className={styles.bottom_mask_ratio_img_green}
                    />
                  ) : (
                    <ImArrowDown
                      size={18}
                      color="#ec4252"
                      className={styles.bottom_mask_ratio_img_red}
                    />
                  )}
                </span>
                <div className={styles.bottom_mask_ratio_wrapper}>
                  <Tooltip
                    title={`${element?.metricVsCurrentPrc}% ${element.metricDetail}`}
                  >
                    <span
                      className={styles.bottom_mask_ratio_text}
                      data-active={element?.metricVsCurrentPrc < 0}
                    >
                      {element?.metricVsCurrentPrc}% {element.metricDetail}
                    </span>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  } else
    return (
      <div
        className={styles.m_container}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <div
          className={styles.mini_kpi_container}
          style={{
            ...(isHover && style.hover),
          }}
          data-theme={theme}
        >
          <div className={styles.card_title_and_icon_wrapper}>
            <span className={styles.title}>{cardData.kpiName}</span>
            <ColoredIcon
              imgPath={
                kpiOptions[cardData?.kpiType ? cardData?.kpiType : "revenue"]
                  ?.icon
              }
              backgroundColor={
                kpiOptions[cardData?.kpiType ? cardData?.kpiType : "revenue"]
                  ?.iconColor
              }
              wrapper={48}
              width={24}
              height={24}
            />
          </div>
          <div className={styles.slider_wrapper}>
            <div className={styles.slider} style={{ right: position }}>
              <div className={styles.metrics_card}>
                <div className={styles.metrics_title_and_ratio}>
                  <span>{cardData.metrics[0]?.metricType}</span>
                  <div className={styles.ratio_and_icon_wrapper}>
                    <span
                      className={styles.raito}
                      style={{
                        color:
                          cardData.metrics[0]?.metricVsCurrentPrc < 0
                            ? "#ec4252"
                            : "#00ad92",
                      }}
                    >
                      {`${cardData.metrics[0]?.metricVsCurrentPrc}% ${cardData.metrics[0]?.metricDetail}`}
                    </span>
                    {cardData.metrics[0]?.metricVsCurrentPrc >= 0 ? (
                      <ImArrowUp
                        size={11}
                        color="#00ad92"
                        className={styles.ratio_icon_green}
                      />
                    ) : (
                      <ImArrowDown
                        size={11}
                        color="#ec4252"
                        className={styles.ratio_icon_red}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.price_and_chart_wrapper}>
                  <span className={styles.price}>
                    {`${cardData.metrics[0]?.metricSymbol} ${cardData.metrics[0]?.metricValue}`}
                  </span>
                  <div className={styles.chart_wrapper}>
                    <LineMiniChart
                      width={186}
                      height={26}
                      data={cardData.graphData}
                      gradientColorArray={
                        kpiOptions[
                          cardData.kpiType ? cardData.kpiType : "revenue"
                        ].lineColorGradient
                      }
                      lineColor={
                        kpiOptions[
                          cardData.kpiType ? cardData.kpiType : "revenue"
                        ].lineColor
                      }
                    />
                  </div>
                </div>
              </div>

              <div className={styles.metrics_card}>
                <div className={styles.metrics_card_content}>
                  {metrics?.map((element: any, index) => {
                    if (index < 3) {
                      return (
                        <div
                          key={`second${Math.random() * index}`}
                          className={styles.bottom_mask_content}
                        >
                          <span className={styles.bottom_mask_title}>
                            {element.metricType}
                          </span>

                          <span
                            className={styles.bottom_mask_current_pct}
                            data-active={element.metricVsCurrentPrc < 0}
                          >
                            {element?.metricVsCurrentPrc}%
                            {element?.metricVsCurrentPrc >= 0 ? (
                              <ImArrowUp
                                size={11}
                                color="#00ad92"
                                className={styles.bottom_mask_ratio_img_green}
                              />
                            ) : (
                              <ImArrowDown
                                size={11}
                                color="#ec4252"
                                className={styles.bottom_mask_ratio_img_red}
                              />
                            )}
                          </span>

                          <span className={styles.bottom_mask_price}>
                            {`${element.metricSymbol} ${element.metricValue}`}
                          </span>
                          <div className={styles.bottom_mask_ratio_wrapper}>
                            <span className={styles.bottom_mask_ratio_text}>
                              {element.metricDetail}
                            </span>
                          </div>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              </div>
              <div className={styles.metrics_card}>
                <div className={styles.metrics_card_content}>
                  {metrics?.map((element: any, index) => {
                    if (index > 2) {
                      return (
                        <div
                          key={`third${Math.random() * index}`}
                          className={styles.bottom_mask_content}
                        >
                          <span className={styles.bottom_mask_title}>
                            {element.metricType}
                          </span>
                          <span
                            className={styles.bottom_mask_current_pct}
                            data-active={element.metricVsCurrentPrc < 0}
                          >
                            {element?.metricVsCurrentPrc}%
                            {element?.metricVsCurrentPrc >= 0 ? (
                              <ImArrowUp
                                size={11}
                                color="#00ad92"
                                className={styles.bottom_mask_ratio_img_green}
                              />
                            ) : (
                              <ImArrowDown
                                size={11}
                                color="#ec4252"
                                className={styles.bottom_mask_ratio_img_red}
                              />
                            )}
                          </span>
                          <span className={styles.bottom_mask_price}>
                            {`${element.metricSymbol} ${element.metricValue}`}
                          </span>
                          <div className={styles.bottom_mask_ratio_wrapper}>
                            <span className={styles.bottom_mask_ratio_text}>
                              {element.metricDetail}
                            </span>
                          </div>
                        </div>
                      );
                    } else return null;
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.slider_buttons}>
          <span
            className={styles.prev_arrow}
            onClick={() => {
              if (position > 0) {
                setPosition(position - 341);
              } else {
                setPosition(682);
              }
            }}
            data-theme={theme}
          >
            <span className={styles.arrow_left} data-theme={theme}></span>
          </span>
          <div className={styles.active_wrapper}>
            <span
              className={styles.active_slide}
              data-active={position === 0}
              data-theme={theme}
            ></span>
            <span
              className={styles.active_slide}
              data-active={position === 341}
              data-theme={theme}
            ></span>
            <span
              className={styles.active_slide}
              data-active={position === 682}
              data-theme={theme}
            ></span>
          </div>
          <span
            className={styles.next_arrow}
            onClick={() => {
              if (position < 682) {
                setPosition(position + 341);
              } else {
                setPosition(0);
              }
            }}
            data-theme={theme}
          >
            <span className={styles.arrow_right} data-theme={theme}></span>
          </span>
        </div>
      </div>
    );
}
