import { RestClient } from "../RestClient";

export class UserActivityClient {
  private static _client: RestClient;

  static fetchUserActivity(data) {
    return UserActivityClient.getClient().postCall(
      `/api/msdv2/user-activity/session`,
      data
    );
  }

  static getClient() {
    if (UserActivityClient._client == null) {
      UserActivityClient._client = new RestClient();
    }
    return UserActivityClient._client;
  }
}
