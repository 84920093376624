import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useContext,
} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import styles from "./styles.module.css";
import { EAFilterContext } from "context-api/FilterContextEA";

export interface IEvent {
  event_name: string;
  event_id: string;
  selected: boolean;
  group_id: null | number;
}

export interface ISubCategory {
  label: string;
  value: string;
  events: IEvent[];
}

export interface ICategory {
  label: string;
  value: string;
  sub_categories: ISubCategory[];
}
interface ICategories {
  label: string;
  value: string;
  sub_categories: {
    label: string;
    value: string;
    events: {
      event_name: string;
      event_id: string;
      selected: boolean;
      group_id: null | number;
    }[];
  }[];
}

interface IMenu {
  category: ICategories;
  expandSubCategory: {
    [key: string]: boolean;
  };
  setExpandSubCategory: Dispatch<
    SetStateAction<{
      [key: string]: boolean;
    }>
  >;
}

const MenuItem = ({
  category,
  expandSubCategory,
  setExpandSubCategory,
}: IMenu) => {
  const [expand, setExpand] = useState(false);
  const { eaFilterList, setEaFilterList } = useContext(EAFilterContext);

  const toggleSubCategoryExpand = (subCategoryValue: string) => {
    setExpandSubCategory((prevState) => ({
      ...prevState,
      [subCategoryValue]: !prevState[subCategoryValue],
    }));
  };

  const handleCategoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    category: ICategories
  ) => {
    const subCategoryEventIds = category.sub_categories.flatMap((subCategory) =>
      subCategory.events.map((event) => event.event_id)
    );
    const subCategoryGroupIds = category.sub_categories.flatMap((subCategory) =>
      subCategory.events.map((event) => event.group_id)
    );

    if (event.target.checked) {
      const filteredGroups = subCategoryGroupIds
        .map((value) => (value ? value : -1))
        .filter((val) => val !== -1 && !eaFilterList.group_ids.includes(val));
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: Array.from(
          new Set([...prevState.event_ids, ...subCategoryEventIds])
        ),
        group_ids: Array.from(
          new Set([...prevState.group_ids, ...filteredGroups])
        ),
      }));
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter(
          (value) => !subCategoryEventIds.includes(value)
        ),
        group_ids: prevState?.group_ids.filter(
          (value) => !subCategoryGroupIds.includes(value)
        ),
      }));
    }
  };

  const handleSubCategoryCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    subCategory: ISubCategory
  ) => {
    const eventIds = subCategory.events.map((event) => event.event_id);
    const eventGroupIds = subCategory.events.map((event) => event.group_id);
    if (event.target.checked) {
      const filteredGroup = eventGroupIds
        .map((el) => (el ? el : -1))
        .filter(
          (value) => value !== -1 && !eaFilterList.group_ids.includes(value)
        );
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: Array.from(new Set([...prevState?.event_ids, ...eventIds])),
        group_ids: Array.from(
          new Set([...prevState?.group_ids, ...filteredGroup])
        ),
      }));
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter(
          (value) => !eventIds.includes(value)
        ),
        group_ids: prevState?.group_ids.filter(
          (value) => !eventGroupIds.includes(value)
        ),
      }));
    }
  };

  // Her bir olayın seçilme durumunu belirleyen fonksiyon.
  const handleEventCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    eventValue: string,
    groupId: number | null
  ) => {
    if (event.target.checked) {
      if (groupId) {
        setEaFilterList((prevState) => ({
          ...prevState,
          event_ids: Array.from(new Set([...prevState?.event_ids, eventValue])),
          // ids: Array.from(new Set([...prevState?.event_ids, eventValue])),
          group_ids: Array.from(new Set([...prevState?.group_ids, groupId])),
        }));
      } else
        setEaFilterList((prevState) => ({
          ...prevState,
          event_ids: Array.from(new Set([...prevState?.event_ids, eventValue])),
          // ids: Array.from(new Set([...prevState?.event_ids, eventValue])),
        }));
    } else {
      setEaFilterList((prevState) => ({
        ...prevState,
        event_ids: prevState?.event_ids.filter((id) => id !== eventValue),
        // ids: prevState?.event_ids.filter((id) => id !== eventValue),
        group_ids: prevState?.group_ids.filter((id) => id !== groupId),
      }));
    }
  };

  useEffect(() => {
    // Seçilen kategoriyi getirir
    let selectedCategory = category.sub_categories.every((sub) =>
      sub.events.every((event) =>
        eaFilterList.event_ids.includes(event.event_id)
      )
    )
      ? category
      : null;

    // Mevcut kagegoriyi getirir categori seçilmemiş koşullarda kullanılır
    let unSelectedCategory = category.value;

    // Seçilen alt kategorileri getirir
    let selectedSubCategorys = category.sub_categories.filter((sub) =>
      sub.events.every((event) =>
        eaFilterList.event_ids.includes(event.event_id)
      )
    );

    // Seçilmemiş alt kategorileri getirir
    let unSelectedSubCategorys = category.sub_categories.filter((sub) =>
      sub.events.some(
        (event) => !eaFilterList.event_ids.includes(event.event_id)
      )
    );

    // Mevcut kategori seçildiyse alt kategorileri siler
    selectedSubCategorys =
      selectedSubCategorys.length === category.sub_categories.length
        ? []
        : selectedSubCategorys;

    // Seçilen event_id'leri getirir
    let selectedIds = unSelectedSubCategorys
      .flatMap((el) => el.events.map((event) => event.event_id))
      .filter((id) => eaFilterList.event_ids.includes(id));

    // Seçilmemiş event_id'leri getirir
    let deletedIds = category.sub_categories.flatMap((el) =>
      el.events.map((event) => event.event_id)
    );
    if (selectedIds.length !== 0) {
      deletedIds = deletedIds.filter((id) => !selectedIds.includes(id));
    }

    // Seçilen group_id'leri getirir
    let selectedGroupIds = unSelectedSubCategorys
      .flatMap((el) =>
        el.events.map((event) => (event.group_id ? event.group_id : -1))
      )
      .filter((id) => id !== -1 && eaFilterList.group_ids.includes(id));

    // Seçilmemiş group_id'leri getirir
    let deletedGroupIds = category.sub_categories.flatMap((el) =>
      el.events.map((event) => (event.group_id ? event.group_id : -1))
    );

    if (selectedGroupIds.length !== 0) {
      deletedGroupIds = deletedGroupIds.filter(
        (id) => !selectedGroupIds.includes(id)
      );
    }

    // Eğer kategori seçildiyse
    if (selectedCategory !== null) {
      const newSelectedCategory = selectedCategory;
      const deletedSubCategorys = newSelectedCategory.sub_categories.map(
        (sub) => sub.value
      );
      setEaFilterList((prevState) => ({
        ...prevState,
        category: Array.from(
          new Set([...prevState.category, newSelectedCategory.value])
        ),
        subCategory: prevState.subCategory.filter(
          (sub) => !deletedSubCategorys.includes(sub)
        ),
        ids: prevState.ids.filter((id) => !deletedIds.includes(id)),
        groupIds: prevState.groupIds.filter(
          (id) => !deletedGroupIds.includes(id)
        ),
      }));
    }

    //Eğer alt kategori seçildiyse
    else if (selectedSubCategorys.length > 0) {
      setEaFilterList((prevState) => ({
        ...prevState,
        category: prevState.category.filter(
          (cat) => cat !== unSelectedCategory
        ),
        subCategory: Array.from(
          new Set([
            ...prevState.subCategory,
            ...selectedSubCategorys.map((sub) => sub.value),
          ])
        ).filter((subValue) =>
          unSelectedSubCategorys.every((subCat) => subCat.value !== subValue)
        ),
        ids: Array.from(new Set([...prevState.ids, ...selectedIds])).filter(
          (id) => !deletedIds.includes(id)
        ),
        groupIds: Array.from(
          new Set([...prevState.groupIds, ...selectedGroupIds])
        ).filter((id) => !deletedGroupIds.includes(id)),
      }));
    }

    // Eğer event seçiliyse
    else {
      setEaFilterList((prevState) => ({
        ...prevState,
        category: prevState.category.filter(
          (cat) => cat !== unSelectedCategory
        ),
        subCategory: prevState.subCategory.filter((subValue) =>
          unSelectedSubCategorys.every((subCat) => subCat.value !== subValue)
        ),
        ids: Array.from(new Set([...prevState.ids, ...selectedIds])).filter(
          (id) => !deletedIds.includes(id)
        ),
        groupIds: Array.from(
          new Set([...prevState.groupIds, ...selectedGroupIds])
        ).filter((id) => !deletedGroupIds.includes(id)),
      }));
    }
  }, [
    eaFilterList.event_ids,
    eaFilterList.group_ids,
    category,
    setEaFilterList,
  ]);

  const isCategoryChecked = (category: ICategory): boolean => {
    return category.sub_categories.every((subCategory) =>
      subCategory.events.every((event) =>
        eaFilterList.event_ids.includes(event.event_id)
      )
    );
  };

  const isSubCategoryChecked = (subCategory: ISubCategory): boolean => {
    return subCategory.events.every((event) =>
      eaFilterList.event_ids.includes(event.event_id)
    );
  };

  const categorySelectedCount = (category: ICategories) => {
    let allId: string[] = [];
    category.sub_categories.forEach((sub) => {
      sub.events.forEach((event) => {
        allId.push(event.event_id);
      });
    });
    return allId.filter((el) => eaFilterList.event_ids.includes(el)).length;
  };
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.category}>
          <span
            className={styles.selected_count_category}
            data-active={categorySelectedCount(category) === 0}
          >
            {categorySelectedCount(category)}
          </span>
          <input
            type="checkbox"
            checked={isCategoryChecked(category)}
            onChange={(e) => handleCategoryCheckboxChange(e, category)}
          />
          <div className={styles.title}>
            <span>{category.label}</span>
            {expand ? (
              <IoIosArrowUp
                size={24}
                onClick={() => {
                  setExpand(!expand);
                }}
              />
            ) : (
              <IoIosArrowDown
                size={24}
                onClick={() => {
                  setExpand(!expand);
                }}
              />
            )}
          </div>
        </div>
        {expand &&
          category?.sub_categories?.map((subCategory, index) => (
            <div key={subCategory?.value}>
              <div className={styles.sub_categories}>
                <span
                  className={styles.selected_count}
                  data-active={
                    subCategory.events.filter((el) =>
                      eaFilterList.event_ids.includes(el.event_id)
                    ).length === 0
                  }
                >
                  {
                    subCategory.events.filter((el) =>
                      eaFilterList.event_ids.includes(el.event_id)
                    ).length
                  }
                </span>
                <input
                  type="checkbox"
                  checked={isSubCategoryChecked(subCategory)}
                  onChange={(e) =>
                    handleSubCategoryCheckboxChange(e, subCategory)
                  }
                />
                <div className={styles.sub_title}>
                  <span>{subCategory?.label}</span>
                  {expandSubCategory[subCategory?.value] ? (
                    <IoIosArrowUp
                      size={24}
                      onClick={() =>
                        toggleSubCategoryExpand(subCategory?.value)
                      }
                    />
                  ) : (
                    <IoIosArrowDown
                      size={24}
                      onClick={() => toggleSubCategoryExpand(subCategory.value)}
                    />
                  )}
                </div>
              </div>
              {category?.sub_categories?.length - 1 !== index &&
                !expandSubCategory[subCategory?.value] && (
                  <hr className={styles.hr} />
                )}
              {expandSubCategory[subCategory?.value] &&
                subCategory?.events?.map((event) => (
                  <div key={event.event_id} className={styles.event}>
                    <input
                      type="checkbox"
                      value={event.event_id}
                      checked={eaFilterList.event_ids.includes(event.event_id)}
                      onChange={(e) =>
                        handleEventCheckboxChange(
                          e,
                          event.event_id,
                          event.group_id
                        )
                      }
                    />
                    <span>{event.event_name}</span>
                  </div>
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default MenuItem;
