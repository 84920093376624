import { Skeleton as SkeletonComponent, SkeletonProps } from "@mui/material";

const Skeleton = ({ ...props }: SkeletonProps) => {
  const { sx, variant = "rounded" } = props;
  return (
    <SkeletonComponent
      {...props}
      sx={{
        bgcolor: "var(--skeleton-bg-color)",
        backdropFilter: "blur(5px) saturate(130%)",
        ...sx,
      }}
      variant={variant}
    />
  );
};

export default Skeleton;
