import React, { useState } from "react"; // useState hook'unu React'ten içe aktarıyoruz.
import html2canvas from "html2canvas"; // HTML içeriğini canvas formatına dönüştürmek için gerekli kütüphaneyi içe aktarıyoruz.
import jsPDF from "jspdf"; // jsPDF kütüphanesini içe aktarıyoruz, bu kütüphane PDF oluşturmak için kullanılır.
import { PiFilePdfBold } from "react-icons/pi"; // PDF ikonunu içe aktarıyoruz.
import styles from "./styles.module.css"; // Stil dosyasını içe aktarıyoruz.
import { CircularProgress } from "@mui/material";

// PDFExportButton adında bir React fonksiyon bileşeni oluşturuyoruz. Bu bileşen, elementId ve fileName adında iki prop alır.
const PDFExportButton = ({ fileName }) => {
  // Loading durumunu yönetmek için useState hook'unu kullanıyoruz.
  const [loading, setLoading] = useState(false);

  // PDF dışa aktarma işlemini gerçekleştirecek olan fonksiyon
  const exportToPDF = async () => {
    // Yükleme durumunu başlatıyoruz
    setLoading(true);

    // Belirtilen elementId'ye sahip HTML elementini seçiyoruz
    const element: any = document.getElementById("child");
    if (!element) {
      throw new Error(`Element with id child not found`);
    }

    // Seçilen elementi canvas'a dönüştürmek için html2canvas fonksiyonunu çağırıyoruz
    html2canvas(element, {
      scale: 2,
      backgroundColor: "rgb(17, 12, 32)",
    }).then((canvas) => {
      // Canvas'ı PNG formatında veri URL'sine dönüştürüyoruz
      const imgData = canvas.toDataURL("image/png");

      // Yeni bir jsPDF örneği oluşturuyoruz, burada PDF'in boyutu A4 ve dikey ("p") olarak belirlenmiştir
      const pdf = new jsPDF("p", "mm", "a4");

      // Görüntü özelliklerini elde ediyoruz
      const imgProps = pdf.getImageProperties(imgData);

      // PDF'in genişliğini elde ediyoruz
      const pdfWidth = pdf.internal.pageSize.getWidth();

      // Görüntünün genişliğine göre yüksekliğini oranlayarak hesaplıyoruz
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      // Görüntüyü PDF'e ekliyoruz, burada 0, 0 pozisyonunda ve hesaplanan genişlik ve yükseklikle
      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Oluşturulan PDF dosyasını belirtilen dosya adıyla kaydediyoruz
      pdf.save(fileName);

      // Yükleme durumunu bitiriyoruz
      setLoading(false);
    });
  };

  // exportToPDF fonksiyonunu tetikleyen bir buton render ediyoruz
  return (
    <button className={styles.button} onClick={exportToPDF} disabled={loading}>
      {loading ? (
        <i className={styles.progress}>
          <CircularProgress color="inherit" size={20} />
        </i>
      ) : (
        <>
          <PiFilePdfBold size={24} /> <span>Export to PDF</span>
        </>
      )}
    </button>
  );
};

export default PDFExportButton; // PDFExportButton bileşenini dışa aktarıyoruz
