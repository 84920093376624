import Layout from "components/common/Layout/Layout";
import AlertRules from "components/ea/AlertRules";
import { useState } from "react";

const ALERTSRULES = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Alert Rules"}
      filterHidden
    >
      <AlertRules />
    </Layout>
  );
};

export default ALERTSRULES;
