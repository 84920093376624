import Layout from "components/common/Layout/Layout";
import { useState } from "react";
import ComparisonViewComponent from "components/ea/ComparisonView";
import HolidaysFilter from "components/ea/HolidayFilter";
import moment from "moment";

const COMPARISONVIEW = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [currentDate, setCurrentDate] = useState(moment());
  const [prevDate, setPrevDate] = useState(moment().subtract(1, "year"));

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Comparison View"}
      filterHidden
    >
      <HolidaysFilter
        dest_required
        currentDate={currentDate}
        prevDate={prevDate}
      />
      <ComparisonViewComponent
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        prevDate={prevDate}
        setPrevDate={setPrevDate}
      />
    </Layout>
  );
};

export default COMPARISONVIEW;
