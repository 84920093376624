import Layout from "components/common/Layout/Layout";

import KPIMini from "components/msd/KPIMini/KPIMini";
import { useState } from "react";
import styles from "./styles.module.css";
import DistributionChannels from "components/clevel/Dashboard/DistributionChannels/DistributionChannels";
import CurrentMonthBookingVarience from "components/clevel/Dashboard/CurrentMonthBookingVarience/CurrentMonthBookingVarience";
import MTDLoadFactor from "components/clevel/Dashboard/MTDLoadFactor/MTDLoadFactor";
import DaysAverage from "components/clevel/Dashboard/DaysAverage/DaysAverage";

export default function DASHBOARD() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="C-Level Dashboard"
    >
      <div className={styles.container}>
        <KPIMini filterIsOpen={filterIsOpen} />
        <DistributionChannels />
        <div className={styles.wrapper}>
          <CurrentMonthBookingVarience />
          <MTDLoadFactor />
        </div>

        <DaysAverage />
      </div>
    </Layout>
  );
}
