/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { ProductOverviewCabinMixClient } from "lib/api/msd/productOverviewCabinMix/ProductOverviewCabinMix";
import { ProductOverviewChannelMixClient } from "lib/api/msd/productOverviewChannelMix/ProductOverviewChannelMix";
import { ProductOverviewPaxMixClient } from "lib/api/msd/productOverviewPaxMix/ProductOverviewPaxMix";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "components/common/Card/Card";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./styles.module.css";
import { ProductOverviewProductMatrixClient } from "lib/api/msd/productOverviewProductMatrix/ProductOverviewProductMatrix";
import images from "constans/images";
import MiniCard from "components/common/MiniCard/MiniCard";
import { DistanceAndDurationClient } from "lib/api/msd/distanceAndDuration/DistanceAndDuration";
import ColumnControl from "utils/ColumnControl";
import moment from "moment";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import SelectMenu from "components/common/SelectMenu";
import { IOption } from "types/global";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";
import LazyLoad from "react-lazyload";
import Skeleton from "components/common/Skeleton";

interface IProductMatrix {
  filterIsOpen: boolean;
  setPassengersMix: React.Dispatch<
    React.SetStateAction<{
      data: any[];
      layout: any;
    }>
  >;
  setCabinMix: React.Dispatch<
    React.SetStateAction<{
      data: any[];
      layout: any;
    }>
  >;
  setChannelMix: React.Dispatch<
    React.SetStateAction<{
      data: any[];
      layout: any;
    }>
  >;
  setPassengersMixLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setCabinMixLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setChannelMixLoader: React.Dispatch<React.SetStateAction<boolean>>;
  storyText: any;
  setStoryText: Dispatch<
    SetStateAction<{
      productMatrix: {};
      pos: {};
      passenger: {};
      cabin: {};
      channel: {};
    }>
  >;
}
interface IRangeSlider {
  value: null | number;
  min: number;
  max: number;
  dateList: string[];
}

export default function ProductMatrix({
  filterIsOpen,
  setPassengersMix,
  setCabinMix,
  setChannelMix,
  setPassengersMixLoader,
  setCabinMixLoader,
  setChannelMixLoader,
  storyText,
  setStoryText,
}: IProductMatrix) {
  const [rangeValue, setRangeValue] = useState<IRangeSlider>({
    value: null,
    min: 0,
    max: 11,
    dateList: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ],
  });
  const [rangeLoader, setRangeLoader] = useState(false);
  const { filterList } = useContext(FilterContext);
  const [matrixTable, setMatrixTable] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [distanceAndDuration, setDistanceAndDuration] = useState<any>({});
  const [distanceDuraitonLoader, setDistanceDurationLoader] = useState(false);
  const user: any = JSON.parse(localStorage.getItem("user") ?? "");
  const { theme } = useContext(ThemeContext);
  const [options, setOptions] = useState<IOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption>();
  const [cityCode, setCityCode] = useState({ orig: "", dest: "" });

  useEffect(() => {
    setRangeLoader(true);

    RangeSliderClient.fetchRangeSlider({
      agg_type: "yearly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        const values = response?.values?.map((el) => ({
          label: el,
          value: el,
          disabled: false,
        }));
        setOptions(values);
        setSelectedOption({
          label: moment(response?.default_date).format("YYYY"),
          value: moment(response?.default_date).format("YYYY"),
          disabled: false,
        });
        setRangeValue((prevState) => ({
          ...prevState,
          value: Number(moment(response?.default_date).format("M")) - 1,
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (
      !filterIsOpen &&
      rangeValue.dateList.length > 0 &&
      selectedOption &&
      rangeValue.value !== null
    ) {
      setDistanceDurationLoader(true);
      DistanceAndDurationClient.fetchDistanceAndDuration({ filterList })
        .then((res) => setDistanceAndDuration(res))
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          setTimeout(() => {
            setDistanceDurationLoader(false);
          }, 2000);
        });
      setSkeletonLoader(true);
      ProductOverviewProductMatrixClient.fetchProductOverviewProductMatrix({
        filterList,
        selected_yearmonth: `${selectedOption.value}-${
          rangeValue.dateList[rangeValue.value]
        }`,
      })
        .then((response: any) => {
          setCityCode({
            orig: response?.orig_city ?? "",
            dest: response?.dest_city ?? "",
          });
          setMatrixTable({
            data: response.table.data,
            columns: getColumns(response.table.labels),
          });
          setStoryText((state) => ({
            ...state,
            productMatrix: response?.story_text,
          }));
        })
        .catch(() => {
          setMatrixTable({ data: [], columns: [] });
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
      setPassengersMixLoader(true);
      ProductOverviewPaxMixClient.fetchProductOverviewPaxMix({
        filterList,
        agg_view: "yearly",
        selected_yearmonth: `${selectedOption.value}-${
          rangeValue.dateList[rangeValue.value]
        }`,
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setPassengersMix({ data: response?.data, layout: response?.layout });
          setStoryText((state) => ({
            ...state,
            passenger: response?.story_text,
          }));
        })
        .catch(() => {
          setPassengersMix({ data: [], layout: {} });
        })
        .finally(() => {
          setTimeout(() => {
            setPassengersMixLoader(false);
          }, 2000);
        });
      setCabinMixLoader(true);
      ProductOverviewCabinMixClient.fetchProductOverviewCabinMix({
        filterList,
        agg_view: "yearly",
        selected_yearmonth: `${selectedOption.value}-${
          rangeValue.dateList[rangeValue.value]
        }`,
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setCabinMix({ data: response?.data, layout: response?.layout });
          setStoryText((state) => ({ ...state, cabin: response?.story_text }));
        })
        .catch(() => {
          setCabinMix({ data: [], layout: {} });
        })
        .finally(() => {
          setTimeout(() => {
            setCabinMixLoader(false);
          }, 2000);
        });
      setChannelMixLoader(true);
      ProductOverviewChannelMixClient.fetchProductOverviewChannelMix({
        filterList,
        agg_view: "yearly",
        selected_yearmonth: `${selectedOption.value}-${
          rangeValue.dateList[rangeValue.value]
        }`,
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setChannelMix({ data: response?.data, layout: response?.layout });
          setStoryText((state) => ({
            ...state,
            channel: response?.story_text,
          }));
        })
        .catch(() => {
          setChannelMix({ data: [], layout: {} });
        })
        .finally(() => {
          setTimeout(() => {
            setChannelMixLoader(false);
          }, 2000);
        });
    }
  }, [
    filterIsOpen,
    rangeValue.value,
    rangeValue.dateList,
    selectedOption,
    theme,
  ]);
  const getColumns = (labels) => {
    const array: any = [];
    labels.forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (field !== "carrier") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip
                title={ColumnControl({ value: row[field], isView: true })}
              >
                <span className={"rdb-custom-cell"}>
                  {ColumnControl({ value: row[field], isView: true })}
                </span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "carrier") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <div
                className={styles.custom_table_row}
                style={{
                  backgroundColor: row[field].color || "rgba(255,0,0,0.2)",
                }}
              >
                <span className={styles.row}>{row[field].value}</span>
              </div>
            ),
            valueGetter: (row) => row[field]["value"],
          };
          array.push(obj);
        }
      }
    });
    return array;
  };

  const getDistanceAndDuration = (bool) => {
    if (bool) {
      if (
        filterList.origCityAirport.length > 1 &&
        filterList.destCityAirport.length > 1
      ) {
        return "-";
      } else return distanceAndDuration.distance;
    } else {
      if (
        filterList.origCityAirport.length > 1 &&
        filterList.destCityAirport.length > 1
      ) {
        return "-";
      } else return distanceAndDuration.duration;
    }
  };
  return (
    <Card variant="secondary">
      <div className={styles.card}>
        <div className={styles.card_header}>
          <div className={styles.card_title} data-theme={theme}>
            <span>
              Product Matrix
              {/* <CardInfo
                title={storyText?.productMatrix?.main_card?.title ?? null}
                description={
                  storyText?.productMatrix?.main_card?.content ?? null
                }
              /> */}
            </span>
          </div>
          <div className={styles.card_header_range_and_select_options}>
            <div className={styles.card_header_range}>
              {rangeLoader ? (
                <Skeleton width={410} height={32} />
              ) : (
                <RangeSlider
                  data={{
                    max: rangeValue.max,
                    min: rangeValue.min,
                    value: rangeValue.value ?? 0,
                    dateList: rangeValue.dateList,
                    setValue: setRangeValue,
                  }}
                  format="MMM"
                />
              )}
            </div>
            {rangeLoader ? (
              <Skeleton
                width={90}
                height={32}
                sx={{
                  marginLeft: 4,
                }}
              />
            ) : (
              <SelectMenu
                options={options}
                value={selectedOption}
                onSelect={(value) => {
                  setSelectedOption(value);
                }}
                placeholder=""
                style={{
                  width: 90,
                  minWidth: 90,
                  borderRadius: 16,
                  marginLeft: 32,
                }}
              />
            )}
          </div>
        </div>

        <div className={styles.card_body}>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={416} />
          ) : (
            <CustomAtarevTable
              data={matrixTable.data}
              columns={matrixTable.columns}
              height={416}
            />
          )}

          <div className={styles.image_and_card_wrapper}>
            {distanceDuraitonLoader ? (
              <Skeleton width={340} height={72} />
            ) : (
              <div className={styles.mini_card_wrapper}>
                <MiniCard
                  width={166}
                  height={72}
                  icon={images.a_to_b}
                  iconBackgroundColor={"#9c00ed"}
                  title={"Distance"}
                  total={getDistanceAndDuration(true)}
                />
                <MiniCard
                  width={166}
                  height={72}
                  icon={images.clock_time}
                  iconBackgroundColor={"#144aff"}
                  title={"Duration"}
                  total={getDistanceAndDuration(false)}
                />
              </div>
            )}
            {skeletonLoader ? (
              <Skeleton
                width={340}
                height={343}
                variant="circular"
                sx={{ marginTop: 1 }}
              />
            ) : (
              <div className={styles.word_image_wrapper}>
                <span className={styles.comp1}>
                  {filterList.mainCompetitor[0]}
                </span>
                <span className={styles.comp2}>
                  {filterList.mainCompetitor[0]}
                </span>
                <span className={styles.host1}>{user?.clientCode}</span>
                <span className={styles.host2}>{user?.clientCode}</span>
                <span className={styles.orig}>{cityCode.orig}</span>
                <span className={styles.dest}>{cityCode.dest}</span>
                <LazyLoad height={200} offset={100}>
                  <img
                    src={
                      theme === "dark" ? images.po_world : images.po_world_light
                    }
                    alt=""
                    loading="lazy"
                    className={styles.world}
                  />
                </LazyLoad>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
