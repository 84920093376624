import { RestClient } from "../../RestClient";

export class CLevelFareStructureFareStructureTablesClient {
  private static _client: RestClient;

  static fetchCLevelFareStructureFareStructureTables(params) {
    return CLevelFareStructureFareStructureTablesClient.getClient().getCall(
      `/api/msdv2/fare-structure/fare-structure-tables?${new URLSearchParams(
        params
      )}`
    );
  }

  static getClient() {
    if (CLevelFareStructureFareStructureTablesClient._client == null) {
      CLevelFareStructureFareStructureTablesClient._client = new RestClient();
    }
    return CLevelFareStructureFareStructureTablesClient._client;
  }
}
