import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import { useContext, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Modal,
} from "@mui/material";
import { Add, HorizontalRule } from "@mui/icons-material";
import images from "constans/images";
import PricingSummary from "../PricingSummary/PricingSummary";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface IRecommendedMarketActions {
  data: { title: string; value: any[] };
  skeletonLoader: boolean;
  storyText: {
    comp_environ: {
      content: string;
      title: string;
    };
    curr_month_perf: {
      content: string;
      title: string;
    };
    market_profile: {
      content: string;
      title: string;
    };
    rec_market_actions: {
      content: string;
      title: string;
    };
  };
}
export default function RecommendedMarketActions({
  data,
  storyText,
  skeletonLoader,
}: IRecommendedMarketActions) {
  const [recommendationText, setRecommendationText] = useState<string>("");
  const [expand, setExpand] = useState<string | false>(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpand(isExpanded ? panel : false);
    };

  return (
    <Card
      variant="secondary"
      title="Recommended Market Actions"
      icon={images.target_goal}
      iconBackgroundColor={"rgba(20, 74, 255, 0.2)"}
      borderColor="#144aff"
      // cardInfo={{
      //   title: storyText?.rec_market_actions?.title ?? null,
      //   description: storyText?.rec_market_actions?.content ?? null,
      // }}
    >
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.modal_container} data-theme={theme}>
          <div className={styles.modal_buttons_wrapper}>
            <div
              className={styles.back_button}
              onClick={() => setModalIsOpen(false)}
            >
              <span className={styles.back_button_arrow}></span>
              <span className={styles.back_button_text}>Back</span>
            </div>
            {/* <div className={styles.download_button}></div> */}
          </div>
          <div className={styles.content}>
            <PricingSummary recommendationText={recommendationText} />
          </div>
        </div>
      </Modal>
      <div className={styles.container}>
        {skeletonLoader
          ? [...Array(3)].map((_, i) => (
              <div key={i}>
                <Skeleton
                  width={"100%"}
                  height={48}
                  key={i}
                  sx={{ margin: "4px 0" }}
                />
              </div>
            ))
          : [
              {
                title:
                  "Maintain strict monitoring and inventory matching practices",
                sub_titles: [],
              },
              ...data?.value,
            ].map((item, index) => {
              return (
                <Accordion
                  className={styles.accordion}
                  expanded={expand === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  key={index}
                  data-theme={theme}
                >
                  <AccordionSummary
                    expandIcon={
                      expand === `panel${index}` ? (
                        <HorizontalRule
                          className={styles.icon}
                          data-theme={theme}
                        />
                      ) : (
                        <Add className={styles.icon} data-theme={theme} />
                      )
                    }
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                    className={styles.accordion_summary}
                  >
                    <span className={styles.summary_text} data-theme={theme}>
                      {item.title}
                    </span>
                  </AccordionSummary>
                  {item.sub_titles.map(
                    (element) =>
                      element && (
                        <AccordionDetails
                          className={styles.accordion_details}
                          onClick={() => {
                            setModalIsOpen(true);
                            setRecommendationText(element);
                          }}
                          data-theme={theme}
                        >
                          <span>{item.sub_titles}</span>
                        </AccordionDetails>
                      )
                  )}
                </Accordion>
              );
            })}
      </div>
    </Card>
  );
}
