/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
// import CardInfo from "components/common/CardInfo/CardInfo";
import Plotly from "components/common/Charts/Plotly";
import images from "constans/images";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { PerformanceTrendsLoadFactorCurveClient } from "lib/api/msd/performanceTrendsLoadFactorCurve/performanceTrendsLoadFactorCurve";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useOutsideClick } from "hooks/useOutsideClick";

// Geçici Resimler

import part1 from "../../../assets/temporary/perf1.webp";
import part2 from "../../../assets/temporary/perf2.webp";
import part3 from "../../../assets/temporary/perf3.webp";

interface IPerformanceTrendsLoadFactorCurve {
  filterIsOpen: boolean;
}
export default function PerformanceTrendsLoadFactorCurve({
  filterIsOpen,
}: IPerformanceTrendsLoadFactorCurve) {
  const { filterList } = useContext(FilterContext);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [layout, setLayout] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveTotalAndPercent, setIsActiveTotalAndPercent] = useState(false);
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    const month = new Date();
    if (filterIsOpen === false && filterList.mainCompetitor.length > 0) {
      PerformanceTrendsLoadFactorCurveClient.fetchPerformanceTrendsLoadFactorCurveClient(
        {
          filterList,
          selected_yearmonth: moment(month).format("YYYY-MM-DD"),
          val_type: isActiveTotalAndPercent ? "load-factor" : "total-bookings",
          dtd: "365",
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setData(response.fig.data);
          setLayout(response.fig.layout);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setData([]);
        });
      PerformanceTrendsLoadFactorCurveClient.fetchPerformanceTrendsLoadFactorCurveClient(
        {
          filterList,
          selected_yearmonth: moment(month).add(1, "M").format("YYYY-MM-DD"),
          val_type: isActiveTotalAndPercent ? "load-factor" : "total-bookings",
          dtd: "365",
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setData2(response.fig.data);
        })
        .catch(() => {
          setData2([]);
        });
      PerformanceTrendsLoadFactorCurveClient.fetchPerformanceTrendsLoadFactorCurveClient(
        {
          filterList,
          selected_yearmonth: moment(month).add(2, "M").format("YYYY-MM-DD"),
          val_type: isActiveTotalAndPercent ? "load-factor" : "total-bookings",
          dtd: "365",
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setData3(response.fig.data);
        })
        .catch(() => {
          setData3([]);
        });
    }
  }, [filterIsOpen, isActiveTotalAndPercent, theme]);

  // Geçici olarak resimler eklenmiştir.
  if (true) {
    return (
      <div className={styles.temporary_container}>
        <div>
          <img src={part1} alt="" />
        </div>
        <div>
          <img src={part2} alt="" />
        </div>
        <div>
          <img src={part3} alt="" />
        </div>
      </div>
    );
  }

  return (
    <Card variant="secondary">
      <div className={styles.open_menu_wrapper} ref={wrapperRef}>
        <div className={styles.card_header}>
          <span className={styles.card_title}>
            Performance TrendsLoad FactorCurve
          </span>
          <img
            src={
              theme === "dark"
                ? images.setting_button
                : images.setting_button_light
            }
            alt=""
            className={styles.setting_icon}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
        </div>

        <div
          className={styles.open_menu}
          data-theme={theme}
          data-active={isOpen}
        >
          <div className={styles.open_menu_top} data-theme={theme}>
            <div className={styles.open_menu_title_wrapper}>
              <span className={styles.open_menu_title} data-theme={theme}>
                Bookings
              </span>
              <img
                src={images.travel_plane_boarding_pass}
                alt=""
                className={styles.open_menu_icons}
                data-theme={theme}
              />
            </div>
            <div className={styles.open_menu_buttons_wrapper}>
              <span
                className={styles.open_menu_buttons}
                data-active={!isActiveTotalAndPercent}
                onClick={() =>
                  setIsActiveTotalAndPercent(!isActiveTotalAndPercent)
                }
                data-theme={theme}
              >
                Total
              </span>
              <span
                className={styles.open_menu_buttons}
                data-active={isActiveTotalAndPercent}
                onClick={() =>
                  setIsActiveTotalAndPercent(!isActiveTotalAndPercent)
                }
                data-theme={theme}
              >
                Percent
              </span>
            </div>
          </div>
        </div>
      </div>
      <Card variant="primary">
        <div style={{ display: "flex" }}>
          <div style={{ width: "100%", height: 450 }}>
            <Plotly
              data={data}
              layout={layout}
              height={450}
              margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
            />
          </div>
          <div style={{ width: "100%", height: 450 }}>
            <Plotly
              data={data2}
              layout={layout}
              height={450}
              margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
            />
          </div>

          <div style={{ width: "100%", height: 450 }}>
            <Plotly
              data={data3}
              layout={layout}
              height={450}
              margin={{ t: 20, b: 20, l: 20, r: 20, p: 4 }}
            />
          </div>
        </div>
      </Card>
    </Card>
  );
}
