import styles from "./styles.module.css";
import Card from "components/common/Card/Card";
import images from "constans/images";
import { useContext } from "react";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface ICompetitiveEnvironment {
  data: string[];
  skeletonLoader: boolean;
  storyText: {
    comp_environ: {
      content: string;
      title: string;
    };
    curr_month_perf: {
      content: string;
      title: string;
    };
    market_profile: {
      content: string;
      title: string;
    };
    rec_market_actions: {
      content: string;
      title: string;
    };
  };
}
export default function CompetitiveEnvironment({
  data,
  storyText,
  skeletonLoader,
}: ICompetitiveEnvironment) {
  const { theme } = useContext(ThemeContext);

  // const array = [
  //   "Strong presence by TK with 8 daily flights each way (6 from IST and 2 from SAW) \n	Fare structure from SAW is more competitive \n	Fare structure from IST is aligned with AF",
  //   "AF has one daily frequency applying a traditional fare structure from IST",
  //   "Highly competitive beyond markets mainly from the Middle East and CIS countries",
  //   "Capacity on O&D puts pressure on fares in the lowest RBDs",
  //   "PC fares are not aligned with TK fares, though PC manages to gain market share through aggressive pricing",
  // ];
  // const array = [
  //   "Strong presence by XX with 8 daily flights each way (6 from AAA and 2 from BBB) \n CC/YY have taken market share with an aggressive economy average fare of $350 \n CC has a 1:10 business class passenger ratio v XX 1:12 with higher average fares \n XX has managed to gain market share through aggressive pricing ",
  // ];
  return (
    <Card
      variant="secondary"
      title="Competitive Environment"
      icon={images.money_analytics}
      iconBackgroundColor={"rgba(37, 158, 255, 0.1)"}
      width={"100%"}
      height={394}
      borderColor={"#259eff"}
      // cardInfo={{
      //   title: storyText?.comp_environ?.title ?? null,
      //   description: storyText?.comp_environ?.content ?? null,
      // }}
    >
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={288} />
      ) : (
        <Card variant="primary" height={288}>
          <div className={styles.market_content} data-theme={theme}>
            {data?.map((item, index) => {
              return (
                <div key={index}>
                  <span>
                    {item?.split("\n")?.map((str) => (
                      <p>{str}</p>
                    ))}
                  </span>
                </div>
              );
            })}
          </div>
        </Card>
      )}
    </Card>
  );
}
