import Layout from "components/common/Layout/Layout";
import Calendar from "components/ea/Calendar";
import HolidaysFilter from "components/ea/HolidayFilter";
import MiniKpi from "components/ea/MiniKpi";
import moment from "moment";
import { useState } from "react";

const HOLIDAYS = () => {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [date, setDate] = useState(moment());
  const [detailDate, setDetailDate] = useState(moment());
  const [isSummaryDetail, setIsSummaryDetail] = useState(false);

  return (
    <Layout
      filterIsOpen={filterIsOpen}
      setFilterIsOpen={setFilterIsOpen}
      title={"Holidays"}
      filterHidden
    >
      <HolidaysFilter
        date={date}
        detailDate={detailDate}
        setDetailDate={setDetailDate}
        isSummaryDetail={isSummaryDetail}
      />
      <MiniKpi />
      <Calendar
        date={date}
        setDate={setDate}
        detailDate={detailDate}
        setDetailDate={setDetailDate}
        isSummaryDetail={isSummaryDetail}
        setIsSummaryDetail={setIsSummaryDetail}
      />
    </Layout>
  );
};

export default HOLIDAYS;
