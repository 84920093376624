import { useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import Skeleton from "components/common/Skeleton";

interface ISchedulingDetails {
  filterIsOpen: boolean;
  skeletonLoader: boolean;
  schedulingTable: {
    data: never[];
    columns: never[];
  };
  storyText: {
    scheduling_comparison: {
      content: string;
      title: string;
    };
    scheduling_details: {
      content: string;
      title: string;
    };
  };
}

export default function SchedulingDetails({
  filterIsOpen,
  schedulingTable,
  skeletonLoader,
  storyText,
}: ISchedulingDetails) {
  useEffect(() => {}, [filterIsOpen]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.calendar-full-screen`);
      selectedElement?.requestFullscreen();
    }
  }

  return (
    <div
      className={`${styles.container} calendar-full-screen`}
      style={{
        backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <Card
        title="Scheduling Details"
        variant="secondary"
        height={564}
        // cardInfo={{
        //   title: storyText?.scheduling_details?.title ?? null,
        //   description: storyText?.scheduling_details?.content ?? null,
        // }}
      >
        <div className={styles.fullscreen_button_wrapper}>
          <span
            onClick={() => {
              setIsFullScreen(!isFullScreen);
              toggleFullScreen();
            }}
            className={styles.fullscreen_button}
          >
            {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
          </span>
        </div>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={460} />
        ) : (
          <CustomAtarevTable
            data={schedulingTable.data}
            columns={schedulingTable.columns}
            pagination
            isShowing={false}
            height={250}
          />
        )}
      </Card>
    </div>
  );
}
