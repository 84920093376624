import AllPassengers from "components/msd/AllPassengers/AllPassengers";
import KPIMini from "components/msd/KPIMini/KPIMini";
import TopRevenueGroups from "components/msd/TopRevenueGroups/TopRevenueGroups";
import { useState } from "react";
import Layout from "components/common/Layout/Layout";

export default function CUSTOMERSEGMENTATION() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [topRevenueGroupsData, setTopRevenueGroupsData] = useState([]);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Customer Segmentation"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <AllPassengers
        filterIsOpen={filterIsOpen}
        setTopRevenueGroupsData={setTopRevenueGroupsData}
        skeletonLoader={skeletonLoader}
        setSkeletonLoader={setSkeletonLoader}
      />
      <TopRevenueGroups data={topRevenueGroupsData} skeletonLoader={skeletonLoader} />
    </Layout>
  );
}
