/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "context-api/ThemeContext";
import {
  ArrowBack,
  Cancel,
  Clear,
  Delete,
  KeyboardArrowDown,
  MoreVert,
  Search,
  Tune,
} from "@mui/icons-material";
import TableMenu from "./menu";
import ExcelExport from "./excel-export";
import styles from "./styles.module.css";
import React from "react";
import { useOutsideClick } from "hooks/useOutsideClick";
import { Switch } from "@mui/material";
import Input from "components/common/Input/input";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import moment from "moment";
import { Tooltip } from "components/common/Tooltip";

interface IThead {
  data: {
    [key: string]: string | number | boolean | any[];
  }[];
  columns: {
    name: string;
    field: string;
    cell: (row: any) => JSX.Element;
    width?: string | number | undefined;
    valueGetter?: ((row: any) => any) | undefined;
    disabledHeader?: boolean | undefined;
  }[];
  colGroups?: {
    title: string;
    fields: { name: string; field: string; checked: boolean }[];
  }[];
  filterMenuRefs: any;
  openMenuRef: React.MutableRefObject<null>;
  expand: boolean;
  selectableRows: ((rows: any) => void) | undefined;
  selectedRows: {};
  selectAllChecked: boolean;
  handleSelectAllChange: () => void;
  handleCheckboxChange: (rowId: any) => void;
  selectedFilters: {};
  sortData: (column: any) => void;
  sortStatus: {
    [key: string]: number;
  };
  multiFilter: {};
  setMultiFilter: React.Dispatch<React.SetStateAction<{}>>;
  handleClearFilterChange: (field: any, isAll?: boolean) => void;
  isResetAll: boolean;
  getUniqValues: (
    field: any,
    search?: string | undefined,
    isCleared?: boolean
  ) => void;
  filterUniqValues: {};
  handleSelectFilterChange: (field: any, item: any, isChecked: any) => void;
  handleFilterOpenMenu: (field: any) => void;

  multiSort: {
    field: string;
    value: string;
  }[];
  setMultiSort: React.Dispatch<
    React.SetStateAction<
      {
        field: string;
        value: string;
      }[]
    >
  >;

  setIsSortMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  scrollLeft: number;
  isSortMenuOpen: boolean;
  addMultiSort: () => void;
  handleMultiSort: () => void;
  selectedGroupFields: string[];
  setSelectedGroupFields: React.Dispatch<React.SetStateAction<string[]>>;
  columnVisibility: {
    [key: string]: boolean;
  };
  setColumnVisibility: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >;
  toggleColumnVisibility: (field: string, bool: boolean) => void;
  moveColumns: {
    name: string;
    field: string;
    cell: (row: any) => JSX.Element;
    width?: string | number | undefined;
    valueGetter?: ((row: any) => any) | undefined;
    disabledHeader?: boolean | undefined;
  }[];

  onSortEnd: ({ oldIndex, newIndex }: { oldIndex: any; newIndex: any }) => void;
  date: moment.Moment;
}

const Thead = ({
  data,
  columns,
  colGroups,
  openMenuRef,
  expand,
  selectableRows,
  selectAllChecked,
  handleSelectAllChange,
  filterMenuRefs,
  selectedFilters,
  sortData,
  sortStatus,
  multiFilter,
  setMultiFilter,
  handleClearFilterChange,
  isResetAll,
  getUniqValues,
  filterUniqValues,
  handleSelectFilterChange,
  handleFilterOpenMenu,
  multiSort,
  setMultiSort,
  setIsSortMenuOpen,
  scrollLeft,
  isSortMenuOpen,
  addMultiSort,
  handleMultiSort,
  selectedGroupFields,
  setSelectedGroupFields,
  selectedRows,
  handleCheckboxChange,
  columnVisibility,
  setColumnVisibility,
  toggleColumnVisibility,
  moveColumns,
  onSortEnd,
  date,
}: IThead) => {
  const { theme } = useContext(ThemeContext);
  const [expanded, setExpanded] = useState<null | number>(null);
  const [search, setSearch] = useState("");
  const [groupMenu, setGroupMenu] = useState(false);
  const [colGroupsState, setColGroupsState] = useState(colGroups);
  const [isCheckboxMenu, setIsCheckboxMenu] = useState(false);
  const [isColumnVisibleMenu, setIsColumnVisibleMenu] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const checkboxMenuRef = useRef(null);
  useOutsideClick(checkboxMenuRef, () => {
    setIsCheckboxMenu(false);
  });
  const columnMenuRef = useRef(null);
  useOutsideClick(columnMenuRef, () => {
    setIsColumnVisibleMenu(false);
  });

  useEffect(() => {
    handleSelectReset();
    setColGroupsState(colGroups);
  }, [colGroups]);

  const handleSelectReset = () => {
    if (colGroups) {
      // API'den gelen verilerde değişiklik olduğunda, başlangıç seçimlerini kontrol et
      const initialSelectedFields = colGroups.reduce((acc: string[], group) => {
        group.fields.forEach((field) => {
          if (field.checked) {
            acc.push(field.field);
          }
        });
        return acc;
      }, []);
      setSelectedGroupFields(initialSelectedFields);
    }
  };
  const handleSelectAll = () => {
    if (colGroups) {
      const initialSelectedFields = colGroups.reduce((acc: string[], group) => {
        group.fields.forEach((field) => {
          acc.push(field.field);
        });
        return acc;
      }, []);
      setSelectedGroupFields(initialSelectedFields);
    }
  };

  const handleFieldSelect = (field) => {
    const index = selectedGroupFields.indexOf(field);
    if (index === -1) {
      setSelectedGroupFields([...selectedGroupFields, field]);
    } else {
      const newSelectedFields = [...selectedGroupFields];
      newSelectedFields.splice(index, 1);
      setSelectedGroupFields(newSelectedFields);
    }
  };
  const handleGroupSelect = (e, group) => {
    if (e.currentTarget.checked) {
      const selecteds = group.fields.filter(
        (field) => !selectedGroupFields.includes(field.field)
      );
      setSelectedGroupFields([
        ...selectedGroupFields,
        ...selecteds.map((field) => field.field),
      ]);
    } else {
      const clear = selectedGroupFields.filter(
        (field) => !group.fields.some((item) => item.field === field)
      );
      setSelectedGroupFields(clear);
    }
  };

  const handleSearch = (e) => {
    const str = e.target.value;
    setSearch(str);
    const aranacakyer = colGroupsState?.filter((group) => {
      if (group.title.toLowerCase().indexOf(str) === 0) {
        return true;
      } else if (
        group.fields.some((el) => el.name.toLowerCase().indexOf(str) === 0)
      ) {
        return true;
      }
      return false;
    });
    if (str === "") {
      setColGroupsState(colGroups);
    } else setColGroupsState(aranacakyer);
  };

  const selectAllColumns = () => {
    setColumnVisibility((prevVisibility) =>
      Object.fromEntries(columns.map((column) => [column.field, true]))
    );
  };

  const hideAllColumns = () => {
    setColumnVisibility((prevVisibility) =>
      Object.fromEntries(columns.map((column) => [column.field, false]))
    );
  };

  const filteredColumns = columns.filter((column) =>
    column.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <thead className={styles.thead} data-theme={theme} ref={openMenuRef}>
      {colGroups && (
        <tr className={styles.group_tr}>
          {expand && <th className={styles.th} style={{ width: 50 }} />}
          {selectableRows && <th className={styles.th} style={{ width: 50 }} />}
          {colGroupsState?.map((group, index) => (
            <th
              key={index}
              className={styles.th}
              colSpan={
                group.fields.filter((field) =>
                  selectedGroupFields.includes(field.field)
                ).length
              }
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                <span>{group.title}</span>
                <TableMenu
                  id="test"
                  options={[
                    {
                      title: "Column Menu",
                      handleClick: () => setGroupMenu(true),
                    },
                  ]}
                >
                  <span className={styles.menu_icon_wrapper}>
                    <MoreVert className={styles.mui_icon} />
                  </span>
                </TableMenu>
              </span>
            </th>
          ))}
          <div className={styles.group_menu_container} data-active={groupMenu}>
            <div className={styles.group_menu_search} data-theme={theme}>
              <Search className={styles.search_icon} />
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearch}
              />
            </div>
            <hr className={styles.hr} />
            <div className={styles.group_menu_buttons}>
              <button onClick={handleSelectReset}>Reset</button>
              <button onClick={() => setSelectedGroupFields([])}>
                Clear All
              </button>
              <button onClick={handleSelectAll}>Select All</button>
            </div>
            <div className={styles.group_menu_body}>
              {colGroupsState?.map((group, index) => (
                <React.Fragment key={index}>
                  <div className={styles.item}>
                    <div>
                      <input
                        type="checkbox"
                        checked={
                          !group.fields.some(
                            (field) =>
                              !selectedGroupFields.includes(field.field)
                          )
                        }
                        onChange={(e) => handleGroupSelect(e, group)}
                      />
                      <label className={styles.label}>{group.title}</label>
                    </div>
                    <div
                      className={styles.expand_icon}
                      onClick={() => {
                        if (expanded === index) {
                          setExpanded(null);
                        } else setExpanded(index);
                      }}
                    >
                      <KeyboardArrowDown
                        sx={{
                          transition: "ease-in-out 300ms",
                          transform: `${
                            expanded === index
                              ? "rotate(180deg)"
                              : "rotate(0deg)"
                          }`,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    {group.fields.map((col, subIndex) => (
                      <div
                        key={subIndex}
                        className={styles.sub_items}
                        style={{
                          display: index === expanded ? "block" : "none",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedGroupFields.includes(col.field)}
                          onChange={() => handleFieldSelect(col.field)}
                        />
                        <label className={styles.label}>{col.name}</label>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <div className={styles.group_menu_apply_button}>
              <button onClick={() => setGroupMenu(false)}>
                Apply ({`${selectedGroupFields.length} Metrics`})
              </button>
            </div>
          </div>
        </tr>
      )}

      <SortableComponent
        columns={moveColumns}
        onSortEnd={onSortEnd}
        colGroups={colGroups}
        selectedGroupFields={selectedGroupFields}
        filterMenuRefs={filterMenuRefs}
        selectedFilters={selectedFilters}
        theme={theme}
        sortData={sortData}
        sortStatus={sortStatus}
        multiFilter={multiFilter}
        setMultiFilter={setMultiFilter}
        handleClearFilterChange={handleClearFilterChange}
        isResetAll={isResetAll}
        getUniqValues={getUniqValues}
        filterUniqValues={filterUniqValues}
        handleSelectFilterChange={handleSelectFilterChange}
        handleFilterOpenMenu={handleFilterOpenMenu}
        multiSort={multiSort}
        setMultiSort={setMultiSort}
        setIsSortMenuOpen={setIsSortMenuOpen}
        initialData={data}
        initialColumns={columns}
        setIsColumnVisibleMenu={setIsColumnVisibleMenu}
        columnVisibility={columnVisibility}
        expand={expand}
        selectableRows={selectableRows}
        selectAllChecked={selectAllChecked}
        handleSelectAllChange={handleSelectAllChange}
        setIsCheckboxMenu={setIsCheckboxMenu}
        isCheckboxMenu={isCheckboxMenu}
        checkboxMenuRef={checkboxMenuRef}
        selectedRows={selectedRows}
        handleCheckboxChange={handleCheckboxChange}
        date={date}
      />
      <div
        className={styles.filter_sort_menu_wrapper}
        style={{ left: scrollLeft }}
      >
        <div
          className={styles.filter_sort_menu}
          data-active={isSortMenuOpen}
          data-theme={theme}
        >
          {multiSort?.map((sort, index) => (
            <div className={styles.menu_items} key={index}>
              <button
                className={styles.multi_sort_button}
                onClick={(e) => {
                  e.stopPropagation();
                  const updatedMultiSort = [...multiSort];
                  if (updatedMultiSort.length > 1) {
                    updatedMultiSort.splice(index, 1);
                    setMultiSort(updatedMultiSort);
                  } else setIsSortMenuOpen(false);
                }}
              >
                <Clear />
              </button>
              <span>Column</span>
              <select
                className={styles.select_input}
                value={sort.field}
                onChange={(e) => {
                  const updateMultiSort = {
                    ...sort,
                    field: e.target.value,
                  };
                  const updatedMultiSort = [...multiSort];
                  updatedMultiSort[index] = updateMultiSort;
                  setMultiSort(updatedMultiSort);
                }}
              >
                {columns?.map((column, index) => (
                  <option key={index} value={column.field}>
                    {column.name}
                  </option>
                ))}
              </select>
              <span>Value</span>
              <select
                className={styles.select_input}
                value={sort.value}
                onChange={(e) => {
                  const updatedFilter = {
                    ...sort,
                    value: e.target.value,
                  };
                  const updatedMultiSort = [...multiSort];
                  updatedMultiSort[index] = updatedFilter;
                  setMultiSort(updatedMultiSort);
                }}
              >
                <option>Sort by ASC</option>
                <option>Sort by DESC</option>
              </select>
            </div>
          ))}

          <div className={styles.add_filter_wrapper}>
            <span>
              <button
                className={styles.add_filter_button}
                onClick={(e) => {
                  e.stopPropagation();
                  addMultiSort();
                }}
              >
                Add Filter
              </button>
            </span>
            <span>
              <button
                className={styles.add_filter_button}
                onClick={(e) => {
                  e.stopPropagation();
                  handleMultiSort();
                }}
              >
                Apply
              </button>
            </span>
          </div>
        </div>
      </div>
      <div
        className={styles.column_visible_menu_wrapper}
        style={{ left: scrollLeft }}
        data-theme={theme}
        data-active={isColumnVisibleMenu}
        ref={columnMenuRef}
      >
        <div className={styles.column_visible_input}>
          <Input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search columns..."
          />
        </div>
        <div className={styles.columns_items}>
          {filteredColumns.map((column) => (
            <p key={column.field}>
              <Switch
                inputProps={{ "aria-label": "Switch demo" }}
                id={column.field}
                onChange={(e) =>
                  toggleColumnVisibility(column.field, e.target.checked)
                }
                checked={columnVisibility[column.field] ?? false}
              />
              <label htmlFor={column.field}>{column.name}</label>
            </p>
          ))}
        </div>
        <div className={styles.column_visible_buttons}>
          <span onClick={selectAllColumns}>Select All</span>
          <span onClick={hideAllColumns}>Hide All</span>
        </div>
      </div>
    </thead>
  );
};

export default Thead;

const SortableComponent = ({
  columns,
  onSortEnd,
  colGroups,
  selectedGroupFields,
  filterMenuRefs,
  selectedFilters,
  theme,
  sortData,
  sortStatus,
  multiFilter,
  setMultiFilter,
  handleClearFilterChange,
  isResetAll,
  getUniqValues,
  filterUniqValues,
  handleSelectFilterChange,
  handleFilterOpenMenu,
  multiSort,
  setMultiSort,
  setIsSortMenuOpen,
  initialData,
  initialColumns,
  setIsColumnVisibleMenu,
  columnVisibility,
  expand,
  selectableRows,
  selectAllChecked,
  handleSelectAllChange,
  setIsCheckboxMenu,
  isCheckboxMenu,
  checkboxMenuRef,
  selectedRows,
  handleCheckboxChange,
  date,
}) => {
  return (
    <SortableList
      helperClass={"SortableHelperWithOverride"}
      columns={columns}
      onSortEnd={onSortEnd}
      axis="xy"
      distance={1}
      colGroups={colGroups}
      selectedGroupFields={selectedGroupFields}
      filterMenuRefs={filterMenuRefs}
      selectedFilters={selectedFilters}
      theme={theme}
      sortData={sortData}
      sortStatus={sortStatus}
      multiFilter={multiFilter}
      setMultiFilter={setMultiFilter}
      handleClearFilterChange={handleClearFilterChange}
      isResetAll={isResetAll}
      getUniqValues={getUniqValues}
      filterUniqValues={filterUniqValues}
      handleSelectFilterChange={handleSelectFilterChange}
      handleFilterOpenMenu={handleFilterOpenMenu}
      multiSort={multiSort}
      setMultiSort={setMultiSort}
      setIsSortMenuOpen={setIsSortMenuOpen}
      initialData={initialData}
      initialColumns={initialColumns}
      setIsColumnVisibleMenu={setIsColumnVisibleMenu}
      columnVisibility={columnVisibility}
      expand={expand}
      selectableRows={selectableRows}
      selectAllChecked={selectAllChecked}
      handleSelectAllChange={handleSelectAllChange}
      setIsCheckboxMenu={setIsCheckboxMenu}
      isCheckboxMenu={isCheckboxMenu}
      checkboxMenuRef={checkboxMenuRef}
      selectedRows={selectedRows}
      handleCheckboxChange={handleCheckboxChange}
      date={date}
    />
  );
};

const SortableList = SortableContainer(
  ({
    columns,
    colGroups,
    selectedGroupFields,
    filterMenuRefs,
    selectedFilters,
    theme,
    sortData,
    sortStatus,
    multiFilter,
    setMultiFilter,
    handleClearFilterChange,
    isResetAll,
    getUniqValues,
    filterUniqValues,
    handleSelectFilterChange,
    handleFilterOpenMenu,
    multiSort,
    setMultiSort,
    setIsSortMenuOpen,
    initialData,
    initialColumns,
    setIsColumnVisibleMenu,
    columnVisibility,
    expand,
    selectableRows,
    selectAllChecked,
    handleSelectAllChange,
    setIsCheckboxMenu,
    isCheckboxMenu,
    checkboxMenuRef,
    selectedRows,
    handleCheckboxChange,
    date,
  }) => {
    return (
      <tr>
        <th>
          <TableMenu
            options={[
              {
                title: "Manage Columns",
                handleClick: () => setIsColumnVisibleMenu(true),
              },
            ]}
          >
            <span>
              <MoreVert className={styles.mui_icon} />
            </span>
          </TableMenu>
        </th>

        {expand && (
          <th
            className={styles.th}
            style={{ width: 170, display: "inline-flex", alignItems: "center" }}
          >
            <span className={styles.col_name} data-theme={theme}>
              <span style={{ marginRight: 16 }}>Days</span>{" "}
              {moment(date).format("MMM YYYY")}
            </span>
          </th>
        )}
        {selectableRows && (
          <th className={styles.th} style={{ width: 50 }}>
            <input
              type="checkbox"
              checked={selectAllChecked ?? false}
              onChange={handleSelectAllChange}
            />
            <div style={{ display: "inline", position: "relative" }}>
              <span
                className={styles.menu_icon_wrapper}
                onClick={() => setIsCheckboxMenu(true)}
              >
                <MoreVert className={styles.mui_icon} />
              </span>
              <div
                className={styles.checkbox_menu_wrapper}
                data-theme={theme}
                data-active={isCheckboxMenu}
                ref={checkboxMenuRef}
              >
                {Object.keys(selectedRows).map(
                  (item, index) =>
                    selectedRows[item] && (
                      <div key={index} className={styles.checkbox_item}>
                        <span
                          className={styles.checkbox_delete_icon}
                          onClick={() => handleCheckboxChange(item)}
                        >
                          <Delete />
                        </span>
                        <span>{item}</span>
                      </div>
                    )
                )}
              </div>
            </div>
          </th>
        )}
        {columns
          .filter((column) => columnVisibility[column.field])
          .map((column, index) => (
            <SortableItem
              key={`item-${index}`}
              disabled={colGroups}
              index={index}
              colGroups={colGroups}
              selectedGroupFields={selectedGroupFields}
              column={column}
              filterMenuRefs={filterMenuRefs}
              selectedFilters={selectedFilters}
              theme={theme}
              sortData={sortData}
              sortStatus={sortStatus}
              multiFilter={multiFilter}
              setMultiFilter={setMultiFilter}
              handleClearFilterChange={handleClearFilterChange}
              isResetAll={isResetAll}
              getUniqValues={getUniqValues}
              filterUniqValues={filterUniqValues}
              handleSelectFilterChange={handleSelectFilterChange}
              handleFilterOpenMenu={handleFilterOpenMenu}
              multiSort={multiSort}
              setMultiSort={setMultiSort}
              setIsSortMenuOpen={setIsSortMenuOpen}
              initialData={initialData}
              initialColumns={initialColumns}
              setIsColumnVisibleMenu={setIsColumnVisibleMenu}
            />
          ))}
      </tr>
    );
  }
);

const SortableItem = SortableElement(
  ({
    index,
    colGroups,
    selectedGroupFields,
    column,
    filterMenuRefs,
    selectedFilters,
    theme,
    sortData,
    sortStatus,
    multiFilter,
    setMultiFilter,
    handleClearFilterChange,
    isResetAll,
    getUniqValues,
    filterUniqValues,
    handleSelectFilterChange,
    handleFilterOpenMenu,
    multiSort,
    setMultiSort,
    setIsSortMenuOpen,
    initialData,
    initialColumns,
    setIsColumnVisibleMenu,
  }) => {
    return (
      <th
        key={index}
        className={`${styles.th} ${styles.hoc}`}
        style={{
          display: colGroups
            ? selectedGroupFields.includes(column.field)
              ? ""
              : "none"
            : "",
        }}
      >
        <div
          className={styles.col_wrapper}
          ref={filterMenuRefs.current[index]}
          style={{
            width: column.width ?? "auto",
          }}
        >
          {selectedFilters[column.field] &&
            selectedFilters[column.field]?.length > 0 && (
              <Tune className={styles.filtered_icon} />
            )}
          <Tooltip title={column.name}>
            <span className={styles.col_name} data-theme={theme}>
              {column.name}
            </span>
          </Tooltip>
          <span
            className={styles.menu_icon_wrapper}
            onClick={(e) => {
              e.stopPropagation();
              sortData(column);
            }}
            style={{
              display: column.disabledHeader ? "none" : "inline",
            }}
          >
            <ArrowBack
              className={styles.mui_icon}
              style={{
                transform:
                  sortStatus[column.field] === 0 ||
                  sortStatus[column.field] === 1
                    ? "rotate(90deg)"
                    : "rotate(270deg)",
                opacity: sortStatus[column.field] === 0 ? 0.5 : 1,
              }}
            />
          </span>
          <div
            className={styles.filter_menu_container}
            data-theme={theme}
            style={{
              display: multiFilter[column.field] ? "block" : "none",
              zIndex: 10,
            }}
          >
            <div>
              <div className={styles.filter_menu_close}>
                <Cancel
                  className={styles.mui_icon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMultiFilter({});
                  }}
                />
              </div>
              <div className={styles.filter_menu_reset_button_wrapper}>
                <span
                  className={styles.filter_menu_reset_button}
                  data-active={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClearFilterChange(column.field);
                  }}
                >
                  Reset
                </span>
                <span
                  className={styles.filter_menu_reset_button}
                  data-active={isResetAll}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (isResetAll) {
                      handleClearFilterChange("", true);
                      getUniqValues(column.field, "", true);
                    }
                  }}
                >
                  Reset All
                </span>
              </div>
              <input
                className={styles.filter_menu_search_input}
                data-theme={theme}
                type="text"
                placeholder={`Filter ${column.name}`}
                onChange={(e) => {
                  getUniqValues(column.field, e.target.value);
                }}
              />
            </div>
            <div className={styles.filter_menu_items}>
              {filterUniqValues[column.field]?.map((item) => (
                <div
                  key={item}
                  className={styles.filter_item}
                  data-theme={theme}
                >
                  {typeof item !== "object" && (
                    <input
                      disabled={typeof item === "object"}
                      type="checkbox"
                      name={item}
                      id={item}
                      checked={
                        selectedFilters[column.field]?.includes(
                          item?.toString()
                        ) || false
                      }
                      onChange={(e) => {
                        handleSelectFilterChange(
                          column.field,
                          e.target.name,
                          e.target.checked
                        );
                      }}
                    />
                  )}
                  <label htmlFor={item} className={styles.filter_item_label}>
                    {typeof item !== "object" && item}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <span
            style={{
              display: column.disabledHeader ? "none" : "inline",
            }}
          >
            <TableMenu
              id={column.field}
              options={[
                {
                  title: "Filter",
                  handleClick: (e) => {
                    handleFilterOpenMenu(column.field);
                  },
                },
                {
                  title: "Multi Sort",
                  handleClick: () => {
                    let index = multiSort.findIndex(
                      (item) => item.field === column.field
                    );
                    if (index < 0) {
                      setMultiSort([
                        ...multiSort,
                        {
                          field: column.field,
                          value: "Sort by ASC",
                        },
                      ]);
                    } else {
                      let updated = {
                        ...multiSort[index],
                        field: column.field,
                      };
                      let newSort = multiSort;
                      newSort[index] = updated;
                      setMultiSort(newSort);
                    }
                    setIsSortMenuOpen(true);
                    setMultiFilter({});
                  },
                },
                {
                  title: (
                    <ExcelExport data={initialData} columns={initialColumns} />
                  ),
                },
                // {
                //   title: "Manage Columns",
                //   handleClick: () => setIsColumnVisibleMenu(true),
                // },
              ]}
            >
              <span className={styles.menu_icon_wrapper}>
                <MoreVert className={styles.mui_icon} />
              </span>
            </TableMenu>
          </span>
        </div>
      </th>
    );
  }
);
