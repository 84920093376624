/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { NetworkSchedulingBeyondPointsClient } from "lib/api/msd/networkSchedulingBeyondPoints/NetworkSchedulingBeyondPoints";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import images from "constans/images";
import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import MiniCard from "components/common/MiniCard/MiniCard";
import { ThemeContext } from "context-api/ThemeContext";
import Skeleton from "components/common/Skeleton";

interface IBeyondPoints {
  filterIsOpen: boolean;
  selectedYear: string;
}

export default function BeyondPoints({
  filterIsOpen,
  selectedYear,
}: IBeyondPoints) {
  const [colorScale, setColorScale] = useState([]);
  const [legendScale, setLegendScale] = useState<number[]>([]);
  const [layout, setLayout] = useState<any>({});
  const [chartData, setChartData] = useState<any>();
  const [isActive, setIsActive] = useState(false);
  const [position, setPosition] = useState(0);
  const [metrics, setMetrics] = useState<any>([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useEffect(() => {
    if (!filterIsOpen && selectedYear !== "") {
      setSkeletonLoader(true);
      NetworkSchedulingBeyondPointsClient.fetchNetworkSchedulingBeyondPoints({
        filterList,
        selected_year: selectedYear,
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setMetrics(response.metrics_cart);
          if (isActive) {
            setChartData(response.fig_inbound.data);
            setLayout(response.fig_inbound.layout);
            setLegendScale(response.legend_scale);
          } else {
            setChartData(response.fig_outbound.data);
            setLayout(response.fig_outbound.layout);
            setLegendScale(response.legend_scale);
          }
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          console.log("Error");
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, isActive, selectedYear, theme]);

  useEffect(() => {
    if (layout?.coloraxis && layout?.coloraxis?.colorscale) {
      const array: any = [];
      layout?.coloraxis.colorscale.forEach((element: any) => {
        array.push(element[1]);
      });
      setColorScale(array);
    }
  }, [layout]);

  const getLegendScale = (value) => {
    if (value !== undefined) {
      return value;
    }
    return "";
  };
  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title="Beyond Points"
        // cardInfo={{
        //   title: storyText?.main_card?.title ?? null,
        //   description: storyText?.main_card?.content ?? null,
        // }}
      >
        {skeletonLoader ? (
          <Skeleton width={333} height={93} />
        ) : (
          <Card variant="primary">
            <div className={styles.card_container}>
              <span className={styles.title} data-theme={theme}>
                Average Fare
              </span>
              <div>
                <div className={styles.gradient_line_wrapper}>
                  <span
                    className={styles.line}
                    style={{
                      borderImageSource: `linear-gradient(90deg, ${colorScale.join()} )`,
                    }}
                  />
                  <span className={styles.text} data-theme={theme}>
                    <span>{getLegendScale(legendScale[0])}</span>
                    <span>
                      {getLegendScale(
                        legendScale[Math.round(legendScale?.length / 4)]
                      )}
                    </span>
                    <span>
                      {getLegendScale(
                        legendScale[Math.round(legendScale?.length / 2)]
                      )}
                    </span>
                    <span>
                      {getLegendScale(legendScale[legendScale.length - 1])}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </Card>
        )}
        <div className={styles.bottom_card}>
          {skeletonLoader ? (
            <Skeleton width={333} height={368} />
          ) : (
            <Card variant="primary" height={368}>
              <div className={styles.bound_switch_wrapper}>
                <span
                  className={styles.switch_button}
                  data-active={isActive}
                  onClick={() => setIsActive(!isActive)}
                  data-theme={theme}
                >
                  Inbound
                </span>
                <span
                  className={styles.switch_button}
                  data-active={!isActive}
                  onClick={() => setIsActive(!isActive)}
                  data-theme={theme}
                >
                  Outbound
                </span>
              </div>
              <div>
                <Plotly
                  data={chartData}
                  layout={layout}
                  width={317}
                  height={280}
                  margin={{ r: 0, l: 0, t: 10, b: 40, p: 4 }}
                />
              </div>
            </Card>
          )}
        </div>
      </Card>
      <div className={styles.slider_card}>
        <Card variant="secondary" width={258} height={550}>
          {skeletonLoader ? (
            <Skeleton width={242} height={80} />
          ) : (
            <div className={styles.slider_buttons}>
              <span
                className={styles.prev_arrow}
                onClick={() => {
                  if (position > 0) {
                    setPosition(position - 210);
                  } else {
                    setPosition(420);
                  }
                }}
                data-theme={theme}
              >
                <span className={styles.arrow_left} data-theme={theme}></span>
              </span>
              <span className={styles.slider_title} data-theme={theme}>
                {position < 1
                  ? metrics[0]?.cart_title
                  : position > 0 && position < 211
                  ? metrics[1]?.cart_title
                  : metrics[2]?.cart_title}
              </span>
              <span
                className={styles.next_arrow}
                onClick={() => {
                  if (position < 420) {
                    setPosition(position + 210);
                  } else {
                    setPosition(0);
                  }
                }}
                data-theme={theme}
              >
                <span className={styles.arrow_right} data-theme={theme}></span>
              </span>
            </div>
          )}
          {skeletonLoader ? (
            <Skeleton width={242} height={448} sx={{ marginTop: 1 }} />
          ) : (
            <Card variant="primary" width={242} height={454}>
              <div className={styles.slider_wrapper}>
                <div className={styles.slider} style={{ right: position }}>
                  {metrics?.map((metric: any, index) => {
                    return (
                      <div className={styles.items} key={index}>
                        <MiniCard
                          title={metric.metrics[0]?.title}
                          total={metric.metrics[0]?.value}
                          icon={
                            [
                              images.group_user,
                              images.money_coint_growth,
                              images.money_coint_growth,
                            ][index]
                          }
                          iconBackgroundColor={"#9c00ed"}
                          width={194}
                          height={72}
                        />
                        <MiniCard
                          title={metric.metrics[1]?.title}
                          total={metric.metrics[1]?.value}
                          icon={
                            [
                              images.group_user,
                              images.money_coint_growth,
                              images.money_coint_growth,
                            ][index]
                          }
                          iconBackgroundColor={"#144aff"}
                          width={194}
                          height={72}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={styles.active_wrapper}>
                  <span
                    className={styles.active_slide}
                    data-active={position === 0}
                    data-theme={theme}
                  ></span>
                  <span
                    className={styles.active_slide}
                    data-active={position === 210}
                    data-theme={theme}
                  ></span>
                  <span
                    className={styles.active_slide}
                    data-active={position === 420}
                    data-theme={theme}
                  ></span>
                </div>
              </div>
            </Card>
          )}
        </Card>
      </div>
    </div>
  );
}
