/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { NetworkSchedulingSchedulingComparisonDetailsClient } from "lib/api/msd/networkSchedulingSchedulingComparisonDetails/NetworkSchedulingSchedulingComparisonDetails";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "components/common/Card/Card";
import Plotly from "components/common/Charts/Plotly";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import { Tooltip } from "components/common/Tooltip";
import Skeleton from "components/common/Skeleton";

interface ISchedulingComparison {
  filterIsOpen: boolean;
  setSchedulingTableLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setSchedulingTable: Dispatch<
    SetStateAction<{
      data: never[];
      columns: never[];
    }>
  >;
  storyText: {
    scheduling_comparison: {
      content: string;
      title: string;
    };
    scheduling_details: {
      content: string;
      title: string;
    };
  };
  setStoryText: Dispatch<
    SetStateAction<{
      scheduling_comparison: {
        content: string;
        title: string;
      };
      scheduling_details: {
        content: string;
        title: string;
      };
    }>
  >;
}
interface IRangeSlider {
  value: null | number;
  min: number;
  max: number;
  dateList: string[];
}
export default function SchedulingComparison({
  filterIsOpen,
  setSchedulingTable,
  setSchedulingTableLoader,
  storyText,
  setStoryText,
}: ISchedulingComparison) {
  const [data, setData] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [layout, setLayout] = useState({});
  const [rangeValue, setRangeValue] = useState<IRangeSlider>({
    value: null,
    min: 0,
    max: 0,
    dateList: [],
  });
  const [rangeLoader, setRangeLoader] = useState(false);

  const { theme } = useContext(ThemeContext);
  const { filterList } = useContext(FilterContext);

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (
      !filterIsOpen &&
      rangeValue.value !== null &&
      rangeValue.dateList.length > 0
    ) {
      setSkeletonLoader(true);
      setSchedulingTableLoader(true);
      NetworkSchedulingSchedulingComparisonDetailsClient.fetchNetworkSchedulingSchedulingComparisonDetails(
        {
          filterList,
          selected_yearmonth: moment(
            rangeValue.dateList[rangeValue.value]
          ).format("YYYY-MM"),
          dark_theme: theme === "dark",
        }
      )
        .then((response: any) => {
          setData(response.fig.data);
          setLayout(response.fig.layout);
          setSchedulingTable({
            data: response.table.data,
            columns: getColumns(response.table.labels),
          });
          setStoryText(response?.story_text);
        })
        .catch(() => {
          setSchedulingTable({
            data: [],
            columns: [],
          });
          setData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
            setSchedulingTableLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, rangeValue, theme]);

  const getColumns = (labels) => {
    const array: any = [];
    labels.forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (field !== "dom_op_al_code" && field !== "next_dest") {
          const obj = {
            name,
            field,
            valueGetter: (row) => row[field]?.value ?? row[field],
            cell: (row) => (
              <Tooltip title={row[field].displayVal ?? row[field]}>
                <span className={"rdb-custom-cell"}>
                  {row[field].displayVal ?? row[field]}
                </span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "next_dest") {
          const obj = {
            name,
            field,
            valueGetter: (row) => row[field]?.value ?? row[field],
            cell: (row) => (
              <Tooltip title={row[field].displayVal ?? row[field]}>
                <span className={"rdb-custom-cell"}>
                  {row[field].displayVal ?? row[field]}
                </span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "dom_op_al_code") {
          const obj = {
            name,
            field,
            valueGetter: (row) => row[field].value,
            cell: (row) => (
              <Tooltip title={row[field]?.value}>
                <div
                  className={styles.custom_table_row}
                  style={{
                    backgroundColor: row[field]?.color || "rgba(255,0,0,0.2)",
                  }}
                >
                  <span className={styles.row}>{row[field]?.value}</span>
                </div>
              </Tooltip>
            ),
          };
          array.push(obj);
        }
      }
    });
    return array;
  };
  return (
    <div className={styles.container}>
      <Card
        title="Scheduling Comparison"
        variant="secondary"
        height={564}
        // cardInfo={{
        //   title: storyText?.scheduling_comparison?.title ?? null,
        //   description: storyText?.scheduling_comparison?.content ?? null,
        // }}
      >
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={438} />
        ) : (
          <Card variant="primary" height={438}>
            <Plotly
              data={data}
              layout={layout}
              height={422}
              margin={{ r: 35, l: 25, t: 50, b: 25, p: 4 }}
            />
          </Card>
        )}
        {rangeLoader ? (
          <Skeleton width={"100%"} height={46} />
        ) : (
          <Card variant="primary">
            <div className={styles.range_slider}>
              <RangeSlider
                data={{
                  max: rangeValue.max,
                  min: rangeValue.min,
                  value: rangeValue.value ?? 0,
                  dateList: rangeValue.dateList,
                  setValue: setRangeValue,
                }}
              />
            </div>
          </Card>
        )}
      </Card>
    </div>
  );
}
