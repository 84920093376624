import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IMarketShareTrends {
  filterList: IFilterContext;
  graphCarriers: string;
  date_range_start: string;
  date_range_end: string;
  time_direction: string;
  dark_theme: boolean;
}

export class MarketShareTrendsClient {
  private static _client: RestClient;

  static fetchMarketShareTrends(params: IMarketShareTrends) {
    const {
      filterList,
      date_range_start,
      date_range_end,
      time_direction,
      dark_theme,
      graphCarriers,
    } = params;
    const qp = QueryParameterStringBuilder.apply(filterList);
    return MarketShareTrendsClient.getClient().getCall(
      `/api/msdv2/bookings/trends?${qp}&date_range_start=${date_range_start}&date_range_end=${date_range_end}&time_direction=${time_direction}&dark_theme=${dark_theme}&graph_carriers=${graphCarriers}`
    );
  }

  static getClient() {
    if (!MarketShareTrendsClient._client) {
      MarketShareTrendsClient._client = new RestClient();
    }
    return MarketShareTrendsClient._client;
  }
}
