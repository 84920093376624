import { IFilterContext } from "types/types";
import { QueryParameterStringBuilder } from "lib/query-parameter-string-builder";
import { RestClient } from "../../RestClient";

interface IStrategyActionsCompetitiveDiagnostic {
  filterList: IFilterContext;
  selected_yearmonth: string;
  graphCarriers: string;
  dark_theme: boolean;
}

export class StrategyActionsCompetitiveDiagnosticClient {
  private static _client: RestClient;

  static fetchStrategyActionsCompetitiveDiagnostic({
    filterList,
    selected_yearmonth,
    graphCarriers,
    dark_theme,
  }: IStrategyActionsCompetitiveDiagnostic) {
    const qp = QueryParameterStringBuilder.apply(filterList);
    return StrategyActionsCompetitiveDiagnosticClient.getClient().getCall(
      `/api/msdv1/strategyActions_competitiveDiagnostic?${qp}&selected_yearmonth=${selected_yearmonth}&dark_theme=${dark_theme}&graph_carriers=${graphCarriers}`
    );
  }

  static getClient() {
    if (StrategyActionsCompetitiveDiagnosticClient._client == null) {
      StrategyActionsCompetitiveDiagnosticClient._client = new RestClient();
    }
    return StrategyActionsCompetitiveDiagnosticClient._client;
  }
}
