/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./AverageAndFareTrends.module.css";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x.webp";
import { useContext, useEffect, useRef, useState } from "react";
import { FilterContext } from "context-api/FilterContext";
import { FareRevenueClient } from "lib/api/msd/fareRevenueFareTrends/fareRevenueFareTrends";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import images from "constans/images";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import LineChart from "components/common/Charts/Line";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import Skeleton from "components/common/Skeleton";

interface IAverageAndFareTrends {
  filterIsOpen: boolean;
}
export default function AverageAndFareTrends({
  filterIsOpen,
}: IAverageAndFareTrends) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [rangeValue, setRangeValue] = useState({
    value: [0, 0],
    min: 0,
    max: 20,
    dateList: [],
  });
  const [rangeLoader, setRangeLoader] = useState(false);
  const [viewIsActiveHistorical, setViewIsActiveHistorical] = useState(true);
  const [viewIsActiveForward, setViewIsActiveForward] = useState(true);
  const [timeRangeIsActive, setTimeRangeIsActive] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [charData, setCharData] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const { filterList } = useContext(FilterContext);
  const { theme } = useContext(ThemeContext);

  const wrapperRef: any = useRef(null);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: timeRangeIsActive ? "daily" : "monthly",
      time_direction: getViewButtonValue(),
    })
      .then((response: any) => {
        setRangeValue((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: [response.start_idx, response.end_idx],
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, [timeRangeIsActive, viewIsActiveForward, viewIsActiveHistorical]);

  useEffect(() => {
    const bool = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].includes("All");
    const allParams = localStorage.getItem("allCompetitors");
    if (bool && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors]);

  useEffect(() => {
    if (!filterIsOpen && rangeValue.dateList.length > 0) {
      setSkeletonLoader(true);
      FareRevenueClient.fetchfareRevenueFareTrends({
        filterList,
        graphCarriers: selectedCarriers.join(),
        date_range_start: rangeValue.dateList[rangeValue.value[0]],
        date_range_end: rangeValue.dateList[rangeValue.value[1]],
        time_direction: getViewButtonValue(),
        agg_type: timeRangeIsActive ? "daily" : "monthly",
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setCarriersJson(response?.carriers);
          setCharData(response.fig.data);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setCharData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList, selectedCarriers, rangeValue.value, theme]);

  const getViewButtonValue = () => {
    if (viewIsActiveForward && viewIsActiveHistorical) {
      return "historical-forward";
    } else if (viewIsActiveForward && viewIsActiveHistorical === false) {
      return "forward";
    } else if (viewIsActiveForward === false && viewIsActiveHistorical) {
      return "historical";
    } else {
      setViewIsActiveForward(true);
      return "forward";
    }
  };
  return (
    <div className={styles.container}>
      <Card
        variant="secondary"
        title="Average Fare Trends"
        height={514}
        zIndex={2}
        // cardInfo={{
        //   title: storyText?.main_card?.title ?? null,
        //   description: storyText?.main_card?.content ?? null,
        // }}
      >
        <div className={styles.carriers_wrapper}>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={48} />
          ) : (
            <Carriers
              data={GetCarriers()}
              added={selectedCarriers}
              setAdded={setSelectedCarriers}
              carriersJson={carriersJson}
            />
          )}
          <div className={styles.open_menu_wrapper} ref={wrapperRef}>
            <img
              src={theme === "dark" ? settingIcon : images.setting_button_light}
              alt=""
              className={styles.setting_button}
              onClick={() => setIsOpen(!isOpen)}
            />
            <div
              className={styles.open_menu}
              data-theme={theme}
              data-active={isOpen}
            >
              <div className={styles.top} data-theme={theme}>
                <div className={styles.top_text_wrapper}>
                  <span
                    className={styles.open_menu_view_text}
                    data-theme={theme}
                  >
                    View
                  </span>
                  <img
                    src={images.eye}
                    alt=""
                    className={styles.view_icon}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.top_buttons_wrapper}>
                  <div
                    className={styles.open_menu_buttons}
                    data-active={viewIsActiveForward}
                    onClick={() => setViewIsActiveForward(!viewIsActiveForward)}
                    data-theme={theme}
                  >
                    Forward
                  </div>
                  <div
                    className={styles.open_menu_buttons}
                    data-active={viewIsActiveHistorical}
                    onClick={() =>
                      setViewIsActiveHistorical(!viewIsActiveHistorical)
                    }
                    data-theme={theme}
                  >
                    Historical
                  </div>
                </div>
              </div>
              <div className={styles.top} data-theme={theme}>
                <div className={styles.top_text_wrapper}>
                  <span
                    className={styles.open_menu_view_text}
                    data-theme={theme}
                  >
                    Time Range
                  </span>
                  <img
                    src={images.calendar}
                    alt=""
                    className={styles.view_icon}
                    data-theme={theme}
                  />
                </div>
                <div className={styles.top_buttons_wrapper}>
                  <div
                    className={styles.open_menu_buttons}
                    data-active={!timeRangeIsActive}
                    onClick={() => setTimeRangeIsActive(!timeRangeIsActive)}
                    data-theme={theme}
                  >
                    Monthly
                  </div>
                  <div
                    className={styles.open_menu_buttons}
                    data-active={timeRangeIsActive}
                    onClick={() => setTimeRangeIsActive(!timeRangeIsActive)}
                    data-theme={theme}
                  >
                    Daily
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={332} />
        ) : (
          <Card variant="primary" zIndex={-1} height={332}>
            <div className={styles.charts_wrapper}>
              <LineChart
                data={charData}
                height={318}
                chartTitle="Average Fare ($)"
                id="1"
              />
            </div>
          </Card>
        )}
        {rangeLoader ? (
          <Skeleton width={"100%"} height={46} />
        ) : (
          <Card variant="primary">
            <div className={styles.range_wrapper}>
              <RangeSlider
                data={{
                  max: rangeValue.max,
                  min: rangeValue.min,
                  value: rangeValue.value,
                  dateList: rangeValue.dateList,
                  setValue: setRangeValue,
                }}
                format={timeRangeIsActive ? "DD MMM YY" : "MMM YY"}
              />
            </div>
          </Card>
        )}
      </Card>
    </div>
  );
}
