import { TriggerTooltip } from "components/common/Tooltip";
import styles from "./styles.module.css";

const HolidaysTooltip = ({ children, data, other }) => {
  return (
    <TriggerTooltip
      title={
        data && data.some((item) => item.event_name) ? (
          <TooltipCard data={data} other={other} />
        ) : null
      }
    >
      {children}
    </TriggerTooltip>
  );
};

export default HolidaysTooltip;

const TooltipCard = ({ data, other }) => {
  const keyToStringAndJoin = (inputKey) => {
    const stringValue = inputKey.replace(/_/g, " ");
    const wordsArray = stringValue.split(" ");
    const resultString = wordsArray.join(" ");
    return resultString;
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {Object.entries(other).map(
          ([key, value]) =>
            key !== "events" && (
              <div key={key}>
                <span className={styles.key}>{keyToStringAndJoin(key)}</span>
                <span>:</span>
                <span className={styles.value}>{`${value ?? "-"}`}</span>
              </div>
            )
        )}
      </div>
      {data?.map((item, index) => (
        <div key={index} className={styles.content}>
          {Object.entries(item).map(([key, value]) => (
            <div key={key}>
              <span className={styles.key}>{keyToStringAndJoin(key)}</span>
              <span>:</span>
              <span className={styles.value}>{`${value ?? "-"}`}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
