/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { CustomerSegmentationGraphsClient } from "lib/api/msd/customerSegmentationGraphs/CustomerSegmentationGraphs";
import { CustomerSegmentationTableClient } from "lib/api/msd/customerSegmentationTable/CustomerSegmentationTable";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import Card from "../../common/Card/Card";
import Plotly from "../../common/Charts/Plotly";
import styles from "./styles.module.css";
import images from "constans/images";
import ColumnControl from "utils/ColumnControl";
import ColoredIcon from "utils/ColoredIcon";
// import CardInfo from "components/common/CardInfo/CardInfo";
import { ThemeContext } from "context-api/ThemeContext";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";
import Skeleton from "components/common/Skeleton";

interface IAllPassengers {
  filterIsOpen: boolean;
  skeletonLoader: boolean;
  setSkeletonLoader: React.Dispatch<React.SetStateAction<boolean>>;
  setTopRevenueGroupsData: Dispatch<SetStateAction<never[]>>;
}
export default function AllPassengers({
  filterIsOpen,
  skeletonLoader,
  setTopRevenueGroupsData,
  setSkeletonLoader,
}: IAllPassengers) {
  const { filterList } = useContext(FilterContext);
  const [isActive, setIsActive] = useState(false);
  const [chartData, setChartData] = useState({
    arr_time_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    cos_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    dow_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    dtd_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    inbound_breakdown: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    outbound_breakdown: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    stop_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
    ticket_type_bd: {
      cart: {
        title: "",
        value: "",
      },
      fig: {
        data: [],
        layout: {},
      },
    },
  });
  const [fieldsLoader, setFieldsLoader] = useState({
    stop_bd: true,
    arr_time_bd: true,
    dow_bd: true,
    outbound_breakdown: true,
    inbound_breakdown: true,
    dtd_bd: true,
    cos_bd: true,
    ticket_type_bd: true,
  });
  const [tableData, setTableData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const chartMargin = {
    t: 0,
    b: 0,
    l: 0,
    r: 0,
    p: 4,
  };
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });

  useEffect(() => {
    if (!filterIsOpen) {
      const fields = [
        { key: "stop_bd", value: "dep_time" },
        { key: "arr_time_bd", value: "arr_time" },
        { key: "dow_bd", value: "dow" },
        { key: "outbound_breakdown", value: "next_dest" },
        { key: "inbound_breakdown", value: "prev_dest" },
        { key: "dtd_bd", value: "dtd" },
        { key: "cos_bd", value: "cos" },
        { key: "ticket_type_bd", value: "t_type" },
      ];
      fields.forEach((field) => {
        setFieldsLoader((prevState) => ({ ...prevState, [field.key]: true }));
        CustomerSegmentationGraphsClient.fetchCustomerSegmentationGraphs({
          filterList,
          dark_theme: theme === "dark",
          field: field.value,
        })
          .then((response: any) => {
            setChartData((prevState) => ({
              ...prevState,
              [field.key]: response,
            }));
            // setStoryText(response?.story_text);
          })
          .catch(() => {
            console.log("Error");
          })
          .finally(() => {
            setTimeout(() => {
              setFieldsLoader((prevState) => ({
                ...prevState,
                [field.key]: false,
              }));
            }, 2000);
          });
      });
      setSkeletonLoader(true);
      CustomerSegmentationTableClient.fetchCustomerSegmentationTable({
        filterList,
      })
        .then((response: any) => {
          getColumns(response.labels);
          setTableData({
            data: response.data,
            columns: getColumns(response.labels),
          });
          setTopRevenueGroupsData(response.text.data);
        })
        .catch(() => {
          setTableData({ data: [], columns: [] });
          setTopRevenueGroupsData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen, filterList, theme]);

  const getColumns = (labels) => {
    const array: any = [];
    labels.forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (field !== "ped") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip
                title={ColumnControl({ value: row[field], isView: true })}
              >
                <span className={"rdb-custom-cell"}>
                  {ColumnControl({ value: row[field], isView: true })}
                </span>
              </Tooltip>
            ),
            valueGetter: (row) =>
              ColumnControl({ value: row[field], isView: false }),
          };
          array.push(obj);
        } else if (field === "ped") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip title={row[field].value}>
                <div className={styles.custom_table_row}>
                  <span className={styles.row}>{row[field].value}</span>
                  <span
                    className={styles.border}
                    style={{ backgroundColor: row[field].color }}
                  />
                </div>
              </Tooltip>
            ),
            valueGetter: (row) => row[field].value,
          };
          array.push(obj);
        }
      }
    });
    return array;
  };
  return (
    <div className={styles.container}>
      <Card variant="secondary" height={624}>
        <div className={styles.content}>
          <div className={styles.card_header}>
            <span className={styles.card_title}>
              All Passengers{" "}
              {/* <CardInfo
                title={storyText?.main_card?.title ?? null}
                description={storyText?.main_card?.content ?? null}
              /> */}
            </span>
            <div className={styles.title_button_wrapper} data-theme={theme}>
              <span
                onClick={() => setIsActive(!isActive)}
                data-active={!isActive}
                data-theme={theme}
              >
                Summary
              </span>
              <span
                onClick={() => setIsActive(!isActive)}
                data-active={isActive}
                data-theme={theme}
              >
                Detail
              </span>
            </div>
          </div>
          {!isActive ? (
            <div className={styles.chart_wrapper}>
              <div className={styles.chart_content}>
                {fieldsLoader.arr_time_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.travel_plane_land}
                          backgroundColor={"#144aff"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.arr_time_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.arr_time_bd?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.arr_time_bd?.fig?.data ?? []}
                        layout={chartData?.arr_time_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.stop_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.a_to_b}
                          backgroundColor={"#5504d9"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.stop_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.stop_bd?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.stop_bd?.fig?.data ?? []}
                        layout={chartData?.stop_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.cos_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.travel_map_point}
                          backgroundColor={"#ec4252"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.cos_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.cos_bd?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.cos_bd?.fig?.data ?? []}
                        layout={chartData?.cos_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.dow_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.calendar_checkmark}
                          backgroundColor={"#00ad92"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.dow_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.dow_bd?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.dow_bd?.fig?.data ?? []}
                        layout={chartData?.dow_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
              </div>
              <div className={styles.chart_content}>
                {fieldsLoader.dtd_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.calendar_schedule}
                          backgroundColor={"#d77cbb"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.dtd_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData.dtd_bd.cart.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.dtd_bd?.fig?.data ?? []}
                        layout={chartData?.dtd_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.inbound_breakdown ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.user_profile}
                          backgroundColor={"#9c00ed"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.inbound_breakdown?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.inbound_breakdown?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.inbound_breakdown?.fig?.data ?? []}
                        layout={chartData?.inbound_breakdown?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.outbound_breakdown ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.travel_plane_check_travel}
                          backgroundColor={"#da7706"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.outbound_breakdown?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.outbound_breakdown?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.outbound_breakdown?.fig?.data ?? []}
                        layout={
                          chartData?.outbound_breakdown?.fig?.layout ?? []
                        }
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
                {fieldsLoader.ticket_type_bd ? (
                  <Skeleton width={"100%"} height={260} />
                ) : (
                  <Card variant="primary" width={"100%"} height={260}>
                    <div className={styles.chart}>
                      <div className={styles.chart_title_card_wrapper}>
                        <ColoredIcon
                          imgPath={images.boarding_pass_white}
                          backgroundColor={"#259eff"}
                        />
                        <div>
                          <p className={styles.top_title} data-theme={theme}>
                            {chartData?.ticket_type_bd?.cart?.title}
                          </p>
                          <p className={styles.content_text}>
                            {chartData?.ticket_type_bd?.cart?.value}
                          </p>
                        </div>
                      </div>
                      <Plotly
                        data={chartData?.ticket_type_bd?.fig?.data ?? []}
                        layout={chartData?.ticket_type_bd?.fig?.layout ?? []}
                        width={144}
                        height={144}
                        margin={chartMargin}
                      />
                    </div>
                  </Card>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.table_wrapper}>
              {skeletonLoader ? (
                <Skeleton width={"100%"} height={480} />
              ) : (
                <CustomAtarevTable
                  data={tableData.data}
                  columns={tableData.columns}
                  pagination
                  height={480}
                />
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
