import Card from "components/common/Card/Card";
import { ThemeContext } from "context-api/ThemeContext";
import { useContext } from "react";
import styles from "./DaysAverage.module.css";
import PlotlyComponent from "components/common/Charts/Plotly";

export default function DaysAverage() {
  const { theme } = useContext(ThemeContext);
  const data = {
    data: [
      {
        customdata: [
          [4, 0.5],
          [8, 1.1],
          [11, 1.5],
          [12, 1.6],
          [14, 1.9],
          [17, 2.3],
          [22, 2.9],
          [25, 3.4],
          [27, 3.6],
          [30, 4],
          [39, 5.2],
          [46, 6.2],
          [52, 7],
          [59, 7.9],
          [68, 9.1],
          [75, 10.1],
          [84, 11.3],
          [86, 11.5],
          [104, 13.9],
          [119, 16],
          [145, 19.4],
          [170, 22.8],
          [185, 24.8],
          [206, 27.6],
          [254, 34],
          [317, 42.5],
          [390, 52.3],
          [455, 61],
          [572, 76.7],
          [619, 83],
          [746, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "620",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "620",
        orientation: "v",
        showlegend: true,
        x: [
          -33, -32, -31, -28, -27, -26, -25, -24, -23, -22, -21, -20, -19, -18,
          -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3,
          -2, -1,
        ],
        xaxis: "x",
        y: [
          4, 8, 11, 12, 14, 17, 22, 25, 27, 30, 39, 46, 52, 59, 68, 75, 84, 86,
          104, 119, 145, 170, 185, 206, 254, 317, 390, 455, 572, 619, 746,
        ],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [[3, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "645",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "645",
        orientation: "v",
        showlegend: true,
        x: [-7],
        xaxis: "x3",
        y: [3],
        yaxis: "y3",
        type: "scatter",
      },
      {
        customdata: [
          [3, 1.8],
          [8, 4.8],
          [11, 6.7],
          [24, 14.5],
          [26, 15.8],
          [38, 23],
          [47, 28.5],
          [51, 30.9],
          [68, 41.2],
          [87, 52.7],
          [117, 70.9],
          [154, 93.3],
          [165, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "645",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "645",
        orientation: "v",
        showlegend: false,
        x: [-13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1],
        xaxis: "x5",
        y: [3, 8, 11, 24, 26, 38, 47, 51, 68, 87, 117, 154, 165],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [
          [1, 10],
          [2, 20],
          [5, 50],
          [6, 60],
          [10, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "645",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "645",
        orientation: "v",
        showlegend: false,
        x: [-6, -4, -3, -2, -1],
        xaxis: "x7",
        y: [1, 2, 5, 6, 10],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [
          [3, 21.4],
          [8, 57.1],
          [12, 85.7],
          [14, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "715",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "715",
        orientation: "v",
        showlegend: true,
        x: [-5, -3, -2, -1],
        xaxis: "x",
        y: [3, 8, 12, 14],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [
          [2, 0.5],
          [4, 1.1],
          [5, 1.4],
          [6, 1.6],
          [8, 2.2],
          [10, 2.7],
          [11, 3],
          [13, 3.5],
          [15, 4.1],
          [17, 4.6],
          [18, 4.9],
          [22, 6],
          [24, 6.5],
          [25, 6.8],
          [26, 7],
          [31, 8.4],
          [44, 11.9],
          [48, 13],
          [49, 13.3],
          [50, 13.6],
          [52, 14.1],
          [63, 17.1],
          [66, 17.9],
          [68, 18.4],
          [83, 22.5],
          [88, 23.8],
          [90, 24.4],
          [101, 27.4],
          [117, 31.7],
          [123, 33.3],
          [135, 36.6],
          [152, 41.2],
          [157, 42.5],
          [161, 43.6],
          [165, 44.7],
          [178, 48.2],
          [189, 51.2],
          [202, 54.7],
          [206, 55.8],
          [209, 56.6],
          [218, 59.1],
          [236, 64],
          [251, 68],
          [263, 71.3],
          [267, 72.4],
          [271, 73.4],
          [301, 81.6],
          [367, 99.5],
          [369, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: true,
        x: [
          -232, -182, -55, -54, -52, -48, -47, -45, -43, -42, -41, -39, -37,
          -36, -35, -34, -33, -32, -31, -30, -29, -28, -27, -26, -25, -24, -23,
          -22, -21, -20, -19, -18, -17, -16, -14, -13, -12, -11, -10, -9, -8,
          -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x9",
        y: [
          2, 4, 5, 6, 8, 10, 11, 13, 15, 17, 18, 22, 24, 25, 26, 31, 44, 48, 49,
          50, 52, 63, 66, 68, 83, 88, 90, 101, 117, 123, 135, 152, 157, 161,
          165, 178, 189, 202, 206, 209, 218, 236, 251, 263, 267, 271, 301, 367,
          369,
        ],
        yaxis: "y9",
        type: "scatter",
      },
      {
        customdata: [
          [2, 0.3],
          [3, 0.5],
          [4, 0.7],
          [5, 0.8],
          [6, 1],
          [7, 1.2],
          [9, 1.5],
          [12, 2],
          [14, 2.4],
          [15, 2.5],
          [17, 2.9],
          [18, 3],
          [19, 3.2],
          [20, 3.4],
          [25, 4.2],
          [26, 4.4],
          [27, 4.5],
          [28, 4.7],
          [30, 5.1],
          [31, 5.2],
          [33, 5.6],
          [36, 6.1],
          [38, 6.4],
          [39, 6.6],
          [43, 7.2],
          [49, 8.2],
          [53, 8.9],
          [59, 9.9],
          [69, 11.6],
          [70, 11.8],
          [75, 12.6],
          [76, 12.8],
          [80, 13.5],
          [86, 14.5],
          [96, 16.2],
          [104, 17.5],
          [114, 19.2],
          [126, 21.2],
          [136, 22.9],
          [140, 23.6],
          [149, 25.1],
          [164, 27.6],
          [176, 29.6],
          [188, 31.6],
          [192, 32.3],
          [201, 33.8],
          [214, 36],
          [226, 38],
          [238, 40.1],
          [249, 41.9],
          [263, 44.3],
          [290, 48.8],
          [300, 50.5],
          [311, 52.4],
          [330, 55.6],
          [349, 58.8],
          [377, 63.5],
          [404, 68],
          [423, 71.2],
          [447, 75.3],
          [471, 79.3],
          [515, 86.7],
          [589, 99.2],
          [594, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: false,
        x: [
          -222, -212, -209, -182, -92, -91, -90, -77, -75, -63, -60, -59, -54,
          -53, -51, -50, -49, -48, -47, -46, -45, -44, -42, -41, -40, -39, -38,
          -36, -35, -34, -33, -32, -31, -30, -29, -28, -27, -26, -25, -24, -23,
          -22, -21, -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9,
          -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x10",
        y: [
          2, 3, 4, 5, 6, 7, 9, 12, 14, 15, 17, 18, 19, 20, 25, 26, 27, 28, 30,
          31, 33, 36, 38, 39, 43, 49, 53, 59, 69, 70, 75, 76, 80, 86, 96, 104,
          114, 126, 136, 140, 149, 164, 176, 188, 192, 201, 214, 226, 238, 249,
          263, 290, 300, 311, 330, 349, 377, 404, 423, 447, 471, 515, 589, 594,
        ],
        yaxis: "y10",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.3],
          [2, 0.6],
          [3, 0.9],
          [4, 1.2],
          [5, 1.5],
          [6, 1.7],
          [7, 2],
          [9, 2.6],
          [11, 3.2],
          [12, 3.5],
          [13, 3.8],
          [18, 5.2],
          [20, 5.8],
          [21, 6.1],
          [22, 6.4],
          [24, 7],
          [25, 7.3],
          [28, 8.1],
          [30, 8.7],
          [34, 9.9],
          [36, 10.5],
          [37, 10.8],
          [38, 11],
          [40, 11.6],
          [42, 12.2],
          [44, 12.8],
          [46, 13.4],
          [50, 14.5],
          [54, 15.7],
          [59, 17.2],
          [65, 18.9],
          [73, 21.2],
          [80, 23.3],
          [88, 25.6],
          [94, 27.3],
          [97, 28.2],
          [98, 28.5],
          [108, 31.4],
          [110, 32],
          [117, 34],
          [123, 35.8],
          [126, 36.6],
          [129, 37.5],
          [133, 38.7],
          [151, 43.9],
          [159, 46.2],
          [163, 47.4],
          [169, 49.1],
          [174, 50.6],
          [179, 52],
          [184, 53.5],
          [189, 54.9],
          [196, 57],
          [214, 62.2],
          [223, 64.8],
          [231, 67.2],
          [233, 67.7],
          [244, 70.9],
          [275, 79.9],
          [342, 99.4],
          [344, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: false,
        x: [
          -219, -178, -106, -105, -94, -76, -68, -67, -60, -59, -55, -54, -53,
          -52, -50, -48, -46, -45, -44, -43, -42, -41, -40, -39, -38, -37, -36,
          -35, -34, -33, -30, -29, -28, -27, -26, -25, -24, -23, -22, -21, -20,
          -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5,
          -4, -3, -2, -1, 0,
        ],
        xaxis: "x11",
        y: [
          1, 2, 3, 4, 5, 6, 7, 9, 11, 12, 13, 18, 20, 21, 22, 24, 25, 28, 30,
          34, 36, 37, 38, 40, 42, 44, 46, 50, 54, 59, 65, 73, 80, 88, 94, 97,
          98, 108, 110, 117, 123, 126, 129, 133, 151, 159, 163, 169, 174, 179,
          184, 189, 196, 214, 223, 231, 233, 244, 275, 342, 344,
        ],
        yaxis: "y11",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.2],
          [2, 0.3],
          [3, 0.5],
          [7, 1.2],
          [9, 1.6],
          [10, 1.7],
          [11, 1.9],
          [15, 2.6],
          [19, 3.3],
          [20, 3.5],
          [25, 4.3],
          [26, 4.5],
          [27, 4.7],
          [31, 5.4],
          [33, 5.7],
          [36, 6.2],
          [37, 6.4],
          [42, 7.3],
          [44, 7.6],
          [46, 7.9],
          [52, 9],
          [58, 10],
          [61, 10.5],
          [65, 11.2],
          [73, 12.6],
          [75, 13],
          [79, 13.6],
          [80, 13.8],
          [87, 15],
          [94, 16.2],
          [97, 16.8],
          [119, 20.6],
          [124, 21.4],
          [128, 22.1],
          [132, 22.8],
          [138, 23.8],
          [146, 25.2],
          [159, 27.5],
          [175, 30.2],
          [179, 30.9],
          [190, 32.8],
          [201, 34.7],
          [216, 37.3],
          [228, 39.4],
          [244, 42.1],
          [260, 44.9],
          [261, 45.1],
          [274, 47.3],
          [298, 51.5],
          [314, 54.2],
          [343, 59.2],
          [375, 64.8],
          [402, 69.4],
          [420, 72.5],
          [430, 74.3],
          [445, 76.9],
          [470, 81.2],
          [496, 85.7],
          [578, 99.8],
          [579, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: false,
        x: [
          -195, -192, -182, -181, -119, -112, -72, -67, -66, -64, -63, -62, -58,
          -50, -49, -46, -44, -43, -42, -41, -40, -39, -38, -36, -35, -34, -33,
          -32, -31, -30, -29, -28, -27, -26, -25, -24, -23, -22, -21, -20, -19,
          -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4,
          -3, -2, -1, 0,
        ],
        xaxis: "x12",
        y: [
          1, 2, 3, 7, 9, 10, 11, 15, 19, 20, 25, 26, 27, 31, 33, 36, 37, 42, 44,
          46, 52, 58, 61, 65, 73, 75, 79, 80, 87, 94, 97, 119, 124, 128, 132,
          138, 146, 159, 175, 179, 190, 201, 216, 228, 244, 260, 261, 274, 298,
          314, 343, 375, 402, 420, 430, 445, 470, 496, 578, 579,
        ],
        yaxis: "y12",
        type: "scatter",
      },
      {
        customdata: [
          [2, 0.4],
          [3, 0.6],
          [4, 0.8],
          [5, 1],
          [18, 3.7],
          [21, 4.3],
          [24, 4.9],
          [25, 5.1],
          [26, 5.3],
          [29, 5.9],
          [30, 6.1],
          [31, 6.4],
          [32, 6.6],
          [33, 6.8],
          [35, 7.2],
          [36, 7.4],
          [37, 7.6],
          [38, 7.8],
          [48, 9.8],
          [49, 10],
          [52, 10.7],
          [54, 11.1],
          [59, 12.1],
          [62, 12.7],
          [65, 13.3],
          [75, 15.4],
          [78, 16],
          [84, 17.2],
          [93, 19.1],
          [112, 23],
          [119, 24.4],
          [129, 26.4],
          [134, 27.5],
          [139, 28.5],
          [146, 29.9],
          [156, 32],
          [166, 34],
          [179, 36.7],
          [188, 38.5],
          [205, 42],
          [209, 42.8],
          [220, 45.1],
          [238, 48.8],
          [245, 50.2],
          [261, 53.5],
          [278, 57],
          [290, 59.4],
          [306, 62.7],
          [324, 66.4],
          [338, 69.3],
          [348, 71.3],
          [354, 72.5],
          [363, 74.4],
          [371, 76],
          [378, 77.5],
          [389, 79.7],
          [425, 87.1],
          [482, 98.8],
          [488, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: false,
        x: [
          -228, -202, -115, -85, -69, -58, -57, -54, -53, -52, -51, -50, -49,
          -48, -47, -46, -45, -44, -43, -42, -41, -40, -38, -37, -36, -34, -33,
          -32, -31, -30, -29, -28, -27, -26, -25, -24, -23, -22, -21, -20, -19,
          -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -6, -5, -4, -3,
          -2, -1, 0,
        ],
        xaxis: "x13",
        y: [
          2, 3, 4, 5, 18, 21, 24, 25, 26, 29, 30, 31, 32, 33, 35, 36, 37, 38,
          48, 49, 52, 54, 59, 62, 65, 75, 78, 84, 93, 112, 119, 129, 134, 139,
          146, 156, 166, 179, 188, 205, 209, 220, 238, 245, 261, 278, 290, 306,
          324, 338, 348, 354, 363, 371, 378, 389, 425, 482, 488,
        ],
        yaxis: "y13",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.3],
          [3, 0.9],
          [7, 2.1],
          [8, 2.4],
          [10, 3],
          [11, 3.3],
          [12, 3.6],
          [19, 5.7],
          [23, 6.9],
          [26, 7.9],
          [27, 8.2],
          [32, 9.7],
          [36, 10.9],
          [43, 13],
          [47, 14.2],
          [49, 14.8],
          [56, 16.9],
          [65, 19.6],
          [69, 20.8],
          [73, 22.1],
          [78, 23.6],
          [86, 26],
          [90, 27.2],
          [93, 28.1],
          [97, 29.3],
          [104, 31.4],
          [116, 35],
          [119, 36],
          [125, 37.8],
          [136, 41.1],
          [140, 42.3],
          [149, 45],
          [155, 46.8],
          [161, 48.6],
          [174, 52.6],
          [179, 54.1],
          [188, 56.8],
          [200, 60.4],
          [211, 63.7],
          [235, 71],
          [248, 74.9],
          [251, 75.8],
          [257, 77.6],
          [262, 79.2],
          [269, 81.3],
          [283, 85.5],
          [315, 95.2],
          [329, 99.4],
          [331, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "720",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "720",
        orientation: "v",
        showlegend: false,
        x: [
          -240, -72, -70, -65, -63, -50, -47, -44, -42, -41, -40, -39, -38, -37,
          -35, -34, -33, -32, -31, -30, -29, -28, -27, -26, -25, -24, -23, -21,
          -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6,
          -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x14",
        y: [
          1, 3, 7, 8, 10, 11, 12, 19, 23, 26, 27, 32, 36, 43, 47, 49, 56, 65,
          69, 73, 78, 86, 90, 93, 97, 104, 116, 119, 125, 136, 140, 149, 155,
          161, 174, 179, 188, 200, 211, 235, 248, 251, 257, 262, 269, 283, 315,
          329, 331,
        ],
        yaxis: "y14",
        type: "scatter",
      },
      {
        customdata: [
          [2, 33.3],
          [4, 66.7],
          [6, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "755",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "755",
        orientation: "v",
        showlegend: true,
        x: [-3, -2, -1],
        xaxis: "x4",
        y: [2, 4, 6],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [
          [3, 18.8],
          [7, 43.8],
          [8, 50],
          [11, 68.8],
          [12, 75],
          [14, 87.5],
          [16, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "825",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "825",
        orientation: "v",
        showlegend: true,
        x: [-230, -218, -216, -204, -191, -190, -183],
        xaxis: "x3",
        y: [3, 7, 8, 11, 12, 14, 16],
        yaxis: "y3",
        type: "scatter",
      },
      {
        customdata: [
          [3, 0.4],
          [4, 0.6],
          [5, 0.7],
          [11, 1.6],
          [13, 1.9],
          [14, 2],
          [15, 2.2],
          [17, 2.5],
          [19, 2.8],
          [22, 3.2],
          [23, 3.3],
          [24, 3.5],
          [25, 3.6],
          [33, 4.8],
          [41, 5.9],
          [47, 6.8],
          [52, 7.5],
          [54, 7.8],
          [56, 8.1],
          [58, 8.4],
          [63, 9.1],
          [66, 9.6],
          [70, 10.1],
          [77, 11.2],
          [84, 12.2],
          [87, 12.6],
          [90, 13],
          [96, 13.9],
          [107, 15.5],
          [113, 16.4],
          [120, 17.4],
          [133, 19.3],
          [143, 20.7],
          [150, 21.7],
          [159, 23],
          [175, 25.4],
          [183, 26.5],
          [202, 29.3],
          [222, 32.2],
          [230, 33.3],
          [240, 34.8],
          [288, 41.7],
          [320, 46.4],
          [375, 54.3],
          [454, 65.8],
          [506, 73.3],
          [558, 80.9],
          [614, 89],
          [690, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "845",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "845",
        orientation: "v",
        showlegend: true,
        x: [
          -230, -87, -63, -59, -54, -50, -49, -47, -45, -42, -40, -38, -37, -35,
          -34, -33, -32, -31, -30, -29, -28, -27, -26, -25, -24, -23, -22, -21,
          -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6,
          -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x9",
        y: [
          3, 4, 5, 11, 13, 14, 15, 17, 19, 22, 23, 24, 25, 33, 41, 47, 52, 54,
          56, 58, 63, 66, 70, 77, 84, 87, 90, 96, 107, 113, 120, 133, 143, 150,
          159, 175, 183, 202, 222, 230, 240, 288, 320, 375, 454, 506, 558, 614,
          690,
        ],
        yaxis: "y9",
        type: "scatter",
      },
      {
        customdata: [
          [5, 0.6],
          [8, 0.9],
          [11, 1.2],
          [13, 1.4],
          [18, 2],
          [21, 2.3],
          [23, 2.6],
          [27, 3],
          [29, 3.2],
          [30, 3.3],
          [31, 3.4],
          [36, 4],
          [41, 4.6],
          [43, 4.8],
          [45, 5],
          [47, 5.2],
          [50, 5.5],
          [54, 6],
          [67, 7.4],
          [83, 9.2],
          [84, 9.3],
          [92, 10.2],
          [99, 11],
          [111, 12.3],
          [118, 13.1],
          [125, 13.9],
          [132, 14.7],
          [134, 14.9],
          [145, 16.1],
          [151, 16.8],
          [164, 18.2],
          [179, 19.9],
          [196, 21.8],
          [208, 23.1],
          [211, 23.4],
          [226, 25.1],
          [237, 26.3],
          [271, 30.1],
          [285, 31.6],
          [308, 34.2],
          [317, 35.2],
          [328, 36.4],
          [355, 39.4],
          [389, 43.2],
          [448, 49.7],
          [487, 54.1],
          [530, 58.8],
          [594, 65.9],
          [622, 69],
          [695, 77.1],
          [793, 88],
          [901, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "845",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "845",
        orientation: "v",
        showlegend: false,
        x: [
          -254, -244, -209, -108, -107, -91, -79, -73, -70, -66, -55, -48, -47,
          -44, -41, -40, -39, -35, -34, -33, -32, -30, -29, -28, -27, -26, -25,
          -24, -23, -22, -21, -20, -19, -18, -17, -16, -15, -14, -13, -12, -11,
          -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x11",
        y: [
          5, 8, 11, 13, 18, 21, 23, 27, 29, 30, 31, 36, 41, 43, 45, 47, 50, 54,
          67, 83, 84, 92, 99, 111, 118, 125, 132, 134, 145, 151, 164, 179, 196,
          208, 211, 226, 237, 271, 285, 308, 317, 328, 355, 389, 448, 487, 530,
          594, 622, 695, 793, 901,
        ],
        yaxis: "y11",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.1],
          [2, 0.3],
          [4, 0.5],
          [6, 0.8],
          [8, 1.1],
          [11, 1.5],
          [12, 1.6],
          [13, 1.8],
          [19, 2.6],
          [22, 3],
          [32, 4.3],
          [33, 4.5],
          [35, 4.7],
          [38, 5.1],
          [39, 5.3],
          [40, 5.4],
          [43, 5.8],
          [44, 6],
          [47, 6.4],
          [50, 6.8],
          [59, 8],
          [62, 8.4],
          [68, 9.2],
          [69, 9.3],
          [71, 9.6],
          [73, 9.9],
          [77, 10.4],
          [80, 10.8],
          [83, 11.2],
          [85, 11.5],
          [88, 11.9],
          [91, 12.3],
          [103, 14],
          [111, 15],
          [118, 16],
          [121, 16.4],
          [135, 18.3],
          [143, 19.4],
          [152, 20.6],
          [156, 21.1],
          [168, 22.8],
          [177, 24],
          [187, 25.3],
          [195, 26.4],
          [206, 27.9],
          [225, 30.5],
          [252, 34.1],
          [277, 37.5],
          [317, 43],
          [346, 46.9],
          [376, 50.9],
          [402, 54.5],
          [480, 65],
          [539, 73],
          [589, 79.8],
          [676, 91.6],
          [738, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "845",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "845",
        orientation: "v",
        showlegend: false,
        x: [
          -240, -184, -155, -71, -67, -65, -64, -59, -58, -57, -56, -53, -50,
          -47, -46, -45, -44, -43, -41, -39, -38, -37, -35, -34, -33, -32, -31,
          -30, -29, -28, -26, -25, -24, -23, -22, -21, -20, -19, -18, -17, -16,
          -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x13",
        y: [
          1, 2, 4, 6, 8, 11, 12, 13, 19, 22, 32, 33, 35, 38, 39, 40, 43, 44, 47,
          50, 59, 62, 68, 69, 71, 73, 77, 80, 83, 85, 88, 91, 103, 111, 118,
          121, 135, 143, 152, 156, 168, 177, 187, 195, 206, 225, 252, 277, 317,
          346, 376, 402, 480, 539, 589, 676, 738,
        ],
        yaxis: "y13",
        type: "scatter",
      },
      {
        customdata: [
          [4, 0.6],
          [5, 0.8],
          [7, 1.1],
          [9, 1.4],
          [10, 1.5],
          [13, 2],
          [14, 2.1],
          [17, 2.6],
          [25, 3.8],
          [28, 4.2],
          [30, 4.6],
          [31, 4.7],
          [32, 4.9],
          [35, 5.3],
          [40, 6.1],
          [42, 6.4],
          [47, 7.1],
          [52, 7.9],
          [58, 8.8],
          [69, 10.5],
          [77, 11.7],
          [81, 12.3],
          [82, 12.4],
          [87, 13.2],
          [95, 14.4],
          [100, 15.2],
          [111, 16.8],
          [118, 17.9],
          [130, 19.7],
          [135, 20.5],
          [140, 21.2],
          [145, 22],
          [165, 25],
          [173, 26.3],
          [176, 26.7],
          [181, 27.5],
          [182, 27.6],
          [196, 29.7],
          [222, 33.7],
          [246, 37.3],
          [264, 40.1],
          [284, 43.1],
          [308, 46.7],
          [327, 49.6],
          [377, 57.2],
          [424, 64.3],
          [488, 74.1],
          [551, 83.6],
          [593, 90],
          [659, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "845",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "845",
        orientation: "v",
        showlegend: false,
        x: [
          -225, -200, -192, -82, -66, -65, -60, -59, -49, -48, -47, -45, -42,
          -41, -40, -38, -35, -33, -32, -31, -30, -29, -28, -27, -26, -25, -24,
          -23, -22, -21, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9,
          -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x14",
        y: [
          4, 5, 7, 9, 10, 13, 14, 17, 25, 28, 30, 31, 32, 35, 40, 42, 47, 52,
          58, 69, 77, 81, 82, 87, 95, 100, 111, 118, 130, 135, 140, 145, 165,
          173, 176, 181, 182, 196, 222, 246, 264, 284, 308, 327, 377, 424, 488,
          551, 593, 659,
        ],
        yaxis: "y14",
        type: "scatter",
      },
      {
        customdata: [
          [1, 2.4],
          [3, 7.1],
          [4, 9.5],
          [5, 11.9],
          [6, 14.3],
          [7, 16.7],
          [9, 21.4],
          [16, 38.1],
          [17, 40.5],
          [19, 45.2],
          [20, 47.6],
          [23, 54.8],
          [26, 61.9],
          [27, 64.3],
          [29, 69],
          [33, 78.6],
          [35, 83.3],
          [38, 90.5],
          [40, 95.2],
          [41, 97.6],
          [42, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: true,
        x: [
          -251, -216, -209, -208, -207, -195, -48, -40, -38, -37, -34, -32, -31,
          -30, -29, -28, -27, -19, -18, -17, -15,
        ],
        xaxis: "x",
        y: [
          1, 3, 4, 5, 6, 7, 9, 16, 17, 19, 20, 23, 26, 27, 29, 33, 35, 38, 40,
          41, 42,
        ],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [
          [2, 7.4],
          [4, 14.8],
          [6, 22.2],
          [7, 25.9],
          [13, 48.1],
          [14, 51.9],
          [15, 55.6],
          [17, 63],
          [21, 77.8],
          [24, 88.9],
          [25, 92.6],
          [26, 96.3],
          [27, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [-43, -38, -35, -32, -28, -26, -24, -20, -19, -18, -17, -16, -15],
        xaxis: "x2",
        y: [2, 4, 6, 7, 13, 14, 15, 17, 21, 24, 25, 26, 27],
        yaxis: "y2",
        type: "scatter",
      },
      {
        customdata: [
          [4, 3],
          [11, 8.3],
          [16, 12.1],
          [22, 16.7],
          [24, 18.2],
          [28, 21.2],
          [29, 22],
          [32, 24.2],
          [33, 25],
          [35, 26.5],
          [36, 27.3],
          [42, 31.8],
          [48, 36.4],
          [49, 37.1],
          [50, 37.9],
          [51, 38.6],
          [53, 40.2],
          [54, 40.9],
          [62, 47],
          [67, 50.8],
          [80, 60.6],
          [84, 63.6],
          [87, 65.9],
          [93, 70.5],
          [96, 72.7],
          [107, 81.1],
          [131, 99.2],
          [132, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [
          -38, -35, -34, -33, -32, -31, -29, -28, -26, -25, -24, -23, -22, -21,
          -20, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6,
        ],
        xaxis: "x3",
        y: [
          4, 11, 16, 22, 24, 28, 29, 32, 33, 35, 36, 42, 48, 49, 50, 51, 53, 54,
          62, 67, 80, 84, 87, 93, 96, 107, 131, 132,
        ],
        yaxis: "y3",
        type: "scatter",
      },
      {
        customdata: [
          [1, 1],
          [3, 3],
          [8, 8.1],
          [12, 12.1],
          [14, 14.1],
          [15, 15.2],
          [19, 19.2],
          [21, 21.2],
          [26, 26.3],
          [27, 27.3],
          [29, 29.3],
          [32, 32.3],
          [36, 36.4],
          [38, 38.4],
          [41, 41.4],
          [43, 43.4],
          [48, 48.5],
          [49, 49.5],
          [50, 50.5],
          [53, 53.5],
          [55, 55.6],
          [57, 57.6],
          [58, 58.6],
          [59, 59.6],
          [62, 62.6],
          [67, 67.7],
          [75, 75.8],
          [79, 79.8],
          [83, 83.8],
          [85, 85.9],
          [90, 90.9],
          [97, 98],
          [99, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [
          -218, -51, -50, -44, -42, -40, -39, -38, -34, -33, -32, -31, -30, -29,
          -28, -27, -26, -25, -24, -23, -22, -20, -19, -18, -17, -16, -15, -14,
          -13, -12, -11, -10, -9,
        ],
        xaxis: "x4",
        y: [
          1, 3, 8, 12, 14, 15, 19, 21, 26, 27, 29, 32, 36, 38, 41, 43, 48, 49,
          50, 53, 55, 57, 58, 59, 62, 67, 75, 79, 83, 85, 90, 97, 99,
        ],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [
          [1, 1.6],
          [2, 3.3],
          [4, 6.6],
          [5, 8.2],
          [8, 13.1],
          [9, 14.8],
          [10, 16.4],
          [11, 18],
          [12, 19.7],
          [13, 21.3],
          [14, 23],
          [17, 27.9],
          [18, 29.5],
          [19, 31.1],
          [26, 42.6],
          [27, 44.3],
          [32, 52.5],
          [36, 59],
          [40, 65.6],
          [47, 77],
          [54, 88.5],
          [55, 90.2],
          [58, 95.1],
          [60, 98.4],
          [61, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [
          -198, -42, -36, -34, -33, -30, -29, -28, -27, -26, -24, -23, -22, -21,
          -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10,
        ],
        xaxis: "x5",
        y: [
          1, 2, 4, 5, 8, 9, 10, 11, 12, 13, 14, 17, 18, 19, 26, 27, 32, 36, 40,
          47, 54, 55, 58, 60, 61,
        ],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.7],
          [2, 1.4],
          [4, 2.8],
          [6, 4.3],
          [7, 5],
          [8, 5.7],
          [15, 10.6],
          [28, 19.9],
          [37, 26.2],
          [42, 29.8],
          [43, 30.5],
          [44, 31.2],
          [48, 34],
          [49, 34.8],
          [52, 36.9],
          [57, 40.4],
          [59, 41.8],
          [68, 48.2],
          [70, 49.6],
          [71, 50.4],
          [75, 53.2],
          [79, 56],
          [86, 61],
          [99, 70.2],
          [117, 83],
          [125, 88.7],
          [135, 95.7],
          [138, 97.9],
          [141, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [
          -43, -39, -38, -37, -36, -35, -34, -32, -31, -30, -29, -28, -27, -26,
          -25, -24, -23, -22, -21, -20, -19, -18, -17, -16, -15, -14, -13, -12,
          -11,
        ],
        xaxis: "x6",
        y: [
          1, 2, 4, 6, 7, 8, 15, 28, 37, 42, 43, 44, 48, 49, 52, 57, 59, 68, 70,
          71, 75, 79, 86, 99, 117, 125, 135, 138, 141,
        ],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [
          [2, 0.7],
          [4, 1.4],
          [5, 1.8],
          [6, 2.2],
          [7, 2.5],
          [9, 3.2],
          [11, 4],
          [14, 5.1],
          [16, 5.8],
          [19, 6.9],
          [26, 9.4],
          [32, 11.6],
          [40, 14.4],
          [46, 16.6],
          [49, 17.7],
          [55, 19.9],
          [61, 22],
          [65, 23.5],
          [67, 24.2],
          [68, 24.5],
          [72, 26],
          [84, 30.3],
          [90, 32.5],
          [97, 35],
          [104, 37.5],
          [106, 38.3],
          [114, 41.2],
          [122, 44],
          [129, 46.6],
          [136, 49.1],
          [152, 54.9],
          [166, 59.9],
          [178, 64.3],
          [192, 69.3],
          [213, 76.9],
          [261, 94.2],
          [277, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "900",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "900",
        orientation: "v",
        showlegend: false,
        x: [
          -213, -186, -44, -43, -39, -37, -36, -35, -34, -33, -32, -31, -30,
          -28, -27, -26, -25, -24, -23, -22, -20, -19, -18, -17, -16, -15, -14,
          -13, -12, -11, -10, -9, -8, -7, -6, -5, -4,
        ],
        xaxis: "x7",
        y: [
          2, 4, 5, 6, 7, 9, 11, 14, 16, 19, 26, 32, 40, 46, 49, 55, 61, 65, 67,
          68, 72, 84, 90, 97, 104, 106, 114, 122, 129, 136, 152, 166, 178, 192,
          213, 261, 277,
        ],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [
          [2, 0.2],
          [4, 0.4],
          [5, 0.5],
          [7, 0.7],
          [8, 0.8],
          [12, 1.2],
          [13, 1.3],
          [15, 1.5],
          [19, 1.9],
          [28, 2.8],
          [36, 3.6],
          [42, 4.2],
          [45, 4.5],
          [55, 5.5],
          [60, 6],
          [69, 6.9],
          [89, 8.9],
          [114, 11.4],
          [139, 13.9],
          [152, 15.2],
          [175, 17.5],
          [216, 21.6],
          [252, 25.2],
          [340, 34],
          [515, 51.6],
          [601, 60.2],
          [655, 65.6],
          [780, 78.1],
          [987, 98.8],
          [999, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "910",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "910",
        orientation: "v",
        showlegend: true,
        x: [
          -35, -34, -33, -31, -28, -27, -25, -23, -22, -21, -20, -19, -18, -17,
          -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x2",
        y: [
          2, 4, 5, 7, 8, 12, 13, 15, 19, 28, 36, 42, 45, 55, 60, 69, 89, 114,
          139, 152, 175, 216, 252, 340, 515, 601, 655, 780, 987, 999,
        ],
        yaxis: "y2",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.1],
          [6, 0.5],
          [7, 0.6],
          [8, 0.7],
          [10, 0.9],
          [12, 1],
          [17, 1.5],
          [18, 1.6],
          [20, 1.7],
          [26, 2.2],
          [32, 2.8],
          [46, 4],
          [58, 5],
          [66, 5.7],
          [69, 5.9],
          [73, 6.3],
          [88, 7.6],
          [92, 7.9],
          [100, 8.6],
          [118, 10.2],
          [127, 10.9],
          [143, 12.3],
          [158, 13.6],
          [179, 15.4],
          [226, 19.5],
          [348, 30],
          [529, 45.6],
          [627, 54],
          [780, 67.2],
          [955, 82.3],
          [1148, 98.9],
          [1161, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "910",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "910",
        orientation: "v",
        showlegend: false,
        x: [
          -35, -34, -32, -29, -28, -27, -26, -25, -23, -22, -21, -20, -19, -18,
          -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3,
          -2, -1, 0,
        ],
        xaxis: "x3",
        y: [
          1, 6, 7, 8, 10, 12, 17, 18, 20, 26, 32, 46, 58, 66, 69, 73, 88, 92,
          100, 118, 127, 143, 158, 179, 226, 348, 529, 627, 780, 955, 1148,
          1161,
        ],
        yaxis: "y3",
        type: "scatter",
      },
      {
        customdata: [
          [4, 0.3],
          [6, 0.4],
          [11, 0.8],
          [19, 1.4],
          [20, 1.4],
          [24, 1.7],
          [25, 1.8],
          [26, 1.9],
          [30, 2.2],
          [35, 2.5],
          [38, 2.7],
          [43, 3.1],
          [52, 3.8],
          [58, 4.2],
          [62, 4.5],
          [63, 4.6],
          [70, 5.1],
          [74, 5.3],
          [90, 6.5],
          [101, 7.3],
          [111, 8],
          [124, 9],
          [132, 9.5],
          [148, 10.7],
          [162, 11.7],
          [202, 14.6],
          [249, 18],
          [296, 21.4],
          [382, 27.6],
          [436, 31.5],
          [599, 43.3],
          [893, 64.5],
          [1179, 85.2],
          [1376, 99.4],
          [1384, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "910",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "910",
        orientation: "v",
        showlegend: false,
        x: [
          -40, -35, -34, -32, -31, -30, -29, -28, -27, -26, -25, -24, -22, -21,
          -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6,
          -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x4",
        y: [
          4, 6, 11, 19, 20, 24, 25, 26, 30, 35, 38, 43, 52, 58, 62, 63, 70, 74,
          90, 101, 111, 124, 132, 148, 162, 202, 249, 296, 382, 436, 599, 893,
          1179, 1376, 1384,
        ],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [
          [4, 0.5],
          [7, 0.9],
          [9, 1.2],
          [10, 1.3],
          [11, 1.4],
          [13, 1.7],
          [18, 2.3],
          [24, 3.1],
          [31, 4],
          [34, 4.4],
          [40, 5.1],
          [46, 5.9],
          [60, 7.7],
          [75, 9.7],
          [97, 12.5],
          [99, 12.7],
          [106, 13.6],
          [113, 14.5],
          [140, 18],
          [182, 23.4],
          [224, 28.8],
          [247, 31.8],
          [268, 34.5],
          [325, 41.8],
          [398, 51.2],
          [568, 73.1],
          [702, 90.3],
          [773, 99.5],
          [777, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "910",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "910",
        orientation: "v",
        showlegend: false,
        x: [
          -185, -31, -30, -29, -27, -24, -23, -22, -21, -20, -18, -17, -16, -15,
          -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0,
        ],
        xaxis: "x5",
        y: [
          4, 7, 9, 10, 11, 13, 18, 24, 31, 34, 40, 46, 60, 75, 97, 99, 106, 113,
          140, 182, 224, 247, 268, 325, 398, 568, 702, 773, 777,
        ],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.3],
          [2, 0.7],
          [4, 1.3],
          [7, 2.3],
          [13, 4.2],
          [22, 7.2],
          [33, 10.8],
          [41, 13.4],
          [49, 16],
          [59, 19.3],
          [78, 25.5],
          [113, 36.9],
          [142, 46.4],
          [179, 58.5],
          [260, 85],
          [303, 99],
          [306, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "910",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "910",
        orientation: "v",
        showlegend: false,
        x: [
          -174, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2,
          -1, 0,
        ],
        xaxis: "x7",
        y: [
          1, 2, 4, 7, 13, 22, 33, 41, 49, 59, 78, 113, 142, 179, 260, 303, 306,
        ],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [
          [5, 0.5],
          [9, 0.8],
          [16, 1.4],
          [18, 1.6],
          [21, 1.9],
          [29, 2.6],
          [34, 3.1],
          [41, 3.7],
          [45, 4.1],
          [58, 5.2],
          [61, 5.5],
          [70, 6.3],
          [79, 7.1],
          [88, 7.9],
          [95, 8.6],
          [110, 9.9],
          [122, 11],
          [144, 13],
          [158, 14.2],
          [178, 16],
          [197, 17.7],
          [232, 20.9],
          [291, 26.2],
          [366, 33],
          [412, 37.1],
          [488, 44],
          [572, 51.5],
          [694, 62.5],
          [898, 80.9],
          [1037, 93.4],
          [1104, 99.5],
          [1110, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "925",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "925",
        orientation: "v",
        showlegend: true,
        x: [
          -32, -31, -30, -28, -27, -26, -25, -24, -23, -22, -21, -20, -19, -18,
          -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6, -5, -4, -3,
          -2, -1, 0,
        ],
        xaxis: "x6",
        y: [
          5, 9, 16, 18, 21, 29, 34, 41, 45, 58, 61, 70, 79, 88, 95, 110, 122,
          144, 158, 178, 197, 232, 291, 366, 412, 488, 572, 694, 898, 1037,
          1104, 1110,
        ],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [
          [1, 0.2],
          [3, 0.5],
          [5, 0.8],
          [10, 1.5],
          [13, 2],
          [17, 2.6],
          [20, 3.1],
          [21, 3.2],
          [24, 3.7],
          [32, 4.9],
          [38, 5.8],
          [46, 7.1],
          [47, 7.2],
          [55, 8.5],
          [97, 14.9],
          [211, 32.5],
          [384, 59.1],
          [519, 79.8],
          [618, 95.1],
          [650, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1045",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1045",
        orientation: "v",
        showlegend: true,
        x: [
          -26, -22, -20, -18, -17, -16, -15, -14, -13, -10, -9, -8, -7, -6, -5,
          -4, -3, -2, -1, 0,
        ],
        xaxis: "x6",
        y: [
          1, 3, 5, 10, 13, 17, 20, 21, 24, 32, 38, 46, 47, 55, 97, 211, 384,
          519, 618, 650,
        ],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [
          [1, 50],
          [2, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1045",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1045",
        orientation: "v",
        showlegend: false,
        x: [-10, 0],
        xaxis: "x7",
        y: [1, 2],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [
          [1, 33.3],
          [3, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1100",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1100",
        orientation: "v",
        showlegend: true,
        x: [-4, -1],
        xaxis: "x6",
        y: [1, 3],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1130",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1130",
        orientation: "v",
        showlegend: true,
        x: [-19],
        xaxis: "x7",
        y: [1],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [
          [2, 22.2],
          [6, 66.7],
          [8, 88.9],
          [9, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1400",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1400",
        orientation: "v",
        showlegend: true,
        x: [-5, -3, -2, -1],
        xaxis: "x",
        y: [2, 6, 8, 9],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [
          [3, 42.9],
          [5, 71.4],
          [7, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1400",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1400",
        orientation: "v",
        showlegend: false,
        x: [-3, -2, -1],
        xaxis: "x4",
        y: [3, 5, 7],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [
          [4, 66.7],
          [6, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1400",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1400",
        orientation: "v",
        showlegend: false,
        x: [-6, -1],
        xaxis: "x5",
        y: [4, 6],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1400",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1400",
        orientation: "v",
        showlegend: false,
        x: [-1],
        xaxis: "x6",
        y: [1],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [
          [1, 10],
          [2, 20],
          [4, 40],
          [5, 50],
          [10, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1400",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1400",
        orientation: "v",
        showlegend: false,
        x: [-6, -4, -3, -2, -1],
        xaxis: "x7",
        y: [1, 2, 4, 5, 10],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1445",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1445",
        orientation: "v",
        showlegend: true,
        x: [-2],
        xaxis: "x4",
        y: [1],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [
          [6, 16.7],
          [8, 22.2],
          [11, 30.6],
          [13, 36.1],
          [21, 58.3],
          [25, 69.4],
          [27, 75],
          [30, 83.3],
          [32, 88.9],
          [33, 91.7],
          [34, 94.4],
          [36, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1445",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1445",
        orientation: "v",
        showlegend: false,
        x: [-48, -37, -35, -34, -33, -30, -29, -28, -24, -23, -22, -17],
        xaxis: "x5",
        y: [6, 8, 11, 13, 21, 25, 27, 30, 32, 33, 34, 36],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [
          [1, 50],
          [2, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1445",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1445",
        orientation: "v",
        showlegend: false,
        x: [-4, -3],
        xaxis: "x6",
        y: [1, 2],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [
          [2, 33.3],
          [6, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1445",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1445",
        orientation: "v",
        showlegend: false,
        x: [-59, -47],
        xaxis: "x7",
        y: [2, 6],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1615",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1615",
        orientation: "v",
        showlegend: true,
        x: [-142],
        xaxis: "x5",
        y: [1],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [[4, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1645",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1645",
        orientation: "v",
        showlegend: true,
        x: [-1],
        xaxis: "x6",
        y: [4],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1745",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1745",
        orientation: "v",
        showlegend: true,
        x: [-71],
        xaxis: "x5",
        y: [1],
        yaxis: "y5",
        type: "scatter",
      },
      {
        customdata: [
          [1, 2.1],
          [7, 14.6],
          [8, 16.7],
          [16, 33.3],
          [18, 37.5],
          [21, 43.8],
          [26, 54.2],
          [32, 66.7],
          [33, 68.8],
          [34, 70.8],
          [35, 72.9],
          [36, 75],
          [37, 77.1],
          [38, 79.2],
          [39, 81.2],
          [40, 83.3],
          [43, 89.6],
          [46, 95.8],
          [48, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1930",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1930",
        orientation: "v",
        showlegend: true,
        x: [
          -42, -41, -40, -34, -33, -32, -31, -30, -29, -28, -27, -26, -24, -22,
          -20, -19, -17, -15, -14,
        ],
        xaxis: "x",
        y: [
          1, 7, 8, 16, 18, 21, 26, 32, 33, 34, 35, 36, 37, 38, 39, 40, 43, 46,
          48,
        ],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "1930",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "1930",
        orientation: "v",
        showlegend: false,
        x: [-18],
        xaxis: "x6",
        y: [1],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2000",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2000",
        orientation: "v",
        showlegend: true,
        x: [-3],
        xaxis: "x6",
        y: [1],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [[4, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2030",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2030",
        orientation: "v",
        showlegend: true,
        x: [-62],
        xaxis: "x",
        y: [4],
        yaxis: "y",
        type: "scatter",
      },
      {
        customdata: [
          [1, 14.3],
          [3, 42.9],
          [5, 71.4],
          [6, 85.7],
          [7, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2030",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2030",
        orientation: "v",
        showlegend: false,
        x: [-37, -29, -28, -18, -17],
        xaxis: "x2",
        y: [1, 3, 5, 6, 7],
        yaxis: "y2",
        type: "scatter",
      },
      {
        customdata: [
          [4, 44.4],
          [9, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2030",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2030",
        orientation: "v",
        showlegend: false,
        x: [-49, -48],
        xaxis: "x3",
        y: [4, 9],
        yaxis: "y3",
        type: "scatter",
      },
      {
        customdata: [
          [1, 12.5],
          [3, 37.5],
          [4, 50],
          [8, 100],
        ],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2030",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2030",
        orientation: "v",
        showlegend: false,
        x: [-194, -184, -65, -53],
        xaxis: "x6",
        y: [1, 3, 4, 8],
        yaxis: "y6",
        type: "scatter",
      },
      {
        customdata: [[2, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2030",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2030",
        orientation: "v",
        showlegend: false,
        x: [-178],
        xaxis: "x7",
        y: [2],
        yaxis: "y7",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2040",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2040",
        orientation: "v",
        showlegend: true,
        x: [-2],
        xaxis: "x4",
        y: [1],
        yaxis: "y4",
        type: "scatter",
      },
      {
        customdata: [[1, 100]],
        hovertemplate:
          "<b>Passengers: </b>%{customdata[0]}<br><b>Cumulative Booked Ratio: </b>%{customdata[1]} <extra></extra>",
        legendgroup: "2130",
        line: {
          dash: "solid",
          width: 6,
          smoothing: 1.3,
          shape: "spline",
        },
        marker: {
          symbol: "circle",
        },
        mode: "lines",
        name: "2130",
        orientation: "v",
        showlegend: true,
        x: [-78],
        xaxis: "x4",
        y: [1],
        yaxis: "y4",
        type: "scatter",
      },
    ],
    layout: {
      template: {
        data: {
          bar: [
            {
              error_x: {
                color: "#2a3f5f",
              },
              error_y: {
                color: "#2a3f5f",
              },
              marker: {
                line: {
                  color: "#E5ECF6",
                  width: 0.5,
                },
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "bar",
            },
          ],
          barpolar: [
            {
              marker: {
                line: {
                  color: "#E5ECF6",
                  width: 0.5,
                },
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "barpolar",
            },
          ],
          carpet: [
            {
              aaxis: {
                endlinecolor: "#2a3f5f",
                gridcolor: "white",
                linecolor: "white",
                minorgridcolor: "white",
                startlinecolor: "#2a3f5f",
              },
              baxis: {
                endlinecolor: "#2a3f5f",
                gridcolor: "white",
                linecolor: "white",
                minorgridcolor: "white",
                startlinecolor: "#2a3f5f",
              },
              type: "carpet",
            },
          ],
          choropleth: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              type: "choropleth",
            },
          ],
          contour: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "contour",
            },
          ],
          contourcarpet: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              type: "contourcarpet",
            },
          ],
          heatmap: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "heatmap",
            },
          ],
          heatmapgl: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "heatmapgl",
            },
          ],
          histogram: [
            {
              marker: {
                pattern: {
                  fillmode: "overlay",
                  size: 10,
                  solidity: 0.2,
                },
              },
              type: "histogram",
            },
          ],
          histogram2d: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "histogram2d",
            },
          ],
          histogram2dcontour: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "histogram2dcontour",
            },
          ],
          mesh3d: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              type: "mesh3d",
            },
          ],
          parcoords: [
            {
              line: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "parcoords",
            },
          ],
          pie: [
            {
              automargin: true,
              type: "pie",
            },
          ],
          scatter: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scatter",
            },
          ],
          scatter3d: [
            {
              line: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scatter3d",
            },
          ],
          scattercarpet: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scattercarpet",
            },
          ],
          scattergeo: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scattergeo",
            },
          ],
          scattergl: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scattergl",
            },
          ],
          scattermapbox: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scattermapbox",
            },
          ],
          scatterpolar: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scatterpolar",
            },
          ],
          scatterpolargl: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scatterpolargl",
            },
          ],
          scatterternary: [
            {
              marker: {
                colorbar: {
                  outlinewidth: 0,
                  ticks: "",
                },
              },
              type: "scatterternary",
            },
          ],
          surface: [
            {
              colorbar: {
                outlinewidth: 0,
                ticks: "",
              },
              colorscale: [
                [0, "#0d0887"],
                [0.1111111111111111, "#46039f"],
                [0.2222222222222222, "#7201a8"],
                [0.3333333333333333, "#9c179e"],
                [0.4444444444444444, "#bd3786"],
                [0.5555555555555556, "#d8576b"],
                [0.6666666666666666, "#ed7953"],
                [0.7777777777777778, "#fb9f3a"],
                [0.8888888888888888, "#fdca26"],
                [1, "#f0f921"],
              ],
              type: "surface",
            },
          ],
          table: [
            {
              cells: {
                fill: {
                  color: "#EBF0F8",
                },
                line: {
                  color: "white",
                },
              },
              header: {
                fill: {
                  color: "#C8D4E3",
                },
                line: {
                  color: "white",
                },
              },
              type: "table",
            },
          ],
        },
        layout: {
          annotationdefaults: {
            arrowcolor: "#2a3f5f",
            arrowhead: 0,
            arrowwidth: 1,
          },
          autotypenumbers: "strict",
          coloraxis: {
            colorbar: {
              outlinewidth: 0,
              ticks: "",
            },
          },
          colorscale: {
            diverging: [
              [0, "#8e0152"],
              [0.1, "#c51b7d"],
              [0.2, "#de77ae"],
              [0.3, "#f1b6da"],
              [0.4, "#fde0ef"],
              [0.5, "#f7f7f7"],
              [0.6, "#e6f5d0"],
              [0.7, "#b8e186"],
              [0.8, "#7fbc41"],
              [0.9, "#4d9221"],
              [1, "#276419"],
            ],
            sequential: [
              [0, "#0d0887"],
              [0.1111111111111111, "#46039f"],
              [0.2222222222222222, "#7201a8"],
              [0.3333333333333333, "#9c179e"],
              [0.4444444444444444, "#bd3786"],
              [0.5555555555555556, "#d8576b"],
              [0.6666666666666666, "#ed7953"],
              [0.7777777777777778, "#fb9f3a"],
              [0.8888888888888888, "#fdca26"],
              [1, "#f0f921"],
            ],
            sequentialminus: [
              [0, "#0d0887"],
              [0.1111111111111111, "#46039f"],
              [0.2222222222222222, "#7201a8"],
              [0.3333333333333333, "#9c179e"],
              [0.4444444444444444, "#bd3786"],
              [0.5555555555555556, "#d8576b"],
              [0.6666666666666666, "#ed7953"],
              [0.7777777777777778, "#fb9f3a"],
              [0.8888888888888888, "#fdca26"],
              [1, "#f0f921"],
            ],
          },
          colorway: [
            "#636efa",
            "#EF553B",
            "#00cc96",
            "#ab63fa",
            "#FFA15A",
            "#19d3f3",
            "#FF6692",
            "#B6E880",
            "#FF97FF",
            "#FECB52",
          ],
          font: {
            color: "#2a3f5f",
          },
          geo: {
            bgcolor: "white",
            lakecolor: "white",
            landcolor: "#E5ECF6",
            showlakes: true,
            showland: true,
            subunitcolor: "white",
          },
          hoverlabel: {
            align: "left",
          },
          hovermode: "closest",
          mapbox: {
            style: "light",
          },
          paper_bgcolor: "white",
          plot_bgcolor: "#E5ECF6",
          polar: {
            angularaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            bgcolor: "#E5ECF6",
            radialaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
          },
          scene: {
            xaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              gridwidth: 2,
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
            },
            yaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              gridwidth: 2,
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
            },
            zaxis: {
              backgroundcolor: "#E5ECF6",
              gridcolor: "white",
              gridwidth: 2,
              linecolor: "white",
              showbackground: true,
              ticks: "",
              zerolinecolor: "white",
            },
          },
          shapedefaults: {
            line: {
              color: "#2a3f5f",
            },
          },
          ternary: {
            aaxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            baxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
            bgcolor: "#E5ECF6",
            caxis: {
              gridcolor: "white",
              linecolor: "white",
              ticks: "",
            },
          },
          title: {
            x: 0.05,
          },
          xaxis: {
            automargin: false,
            gridcolor: "white",
            linecolor: "white",
            ticks: "",
            title: {
              standoff: 15,
            },
            zerolinecolor: "white",
            zerolinewidth: 2,
          },
          yaxis: {
            automargin: false,
            gridcolor: "white",
            linecolor: "white",
            ticks: "",
            title: {
              standoff: 15,
            },
            zerolinecolor: "white",
            zerolinewidth: 2,
          },
        },
      },
      xaxis: {
        anchor: "y",
        domain: [0, 0.12285714285714285],
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis: {
        anchor: "x",
        domain: [0, 0.485],
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      xaxis2: {
        anchor: "y2",
        domain: [0.14285714285714285, 0.2657142857142857],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis2: {
        anchor: "x2",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis3: {
        anchor: "y3",
        domain: [0.2857142857142857, 0.40857142857142853],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis3: {
        anchor: "x3",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis4: {
        anchor: "y4",
        domain: [0.42857142857142855, 0.5514285714285714],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis4: {
        anchor: "x4",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis5: {
        anchor: "y5",
        domain: [0.5714285714285714, 0.6942857142857143],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis5: {
        anchor: "x5",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis6: {
        anchor: "y6",
        domain: [0.7142857142857142, 0.8371428571428571],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis6: {
        anchor: "x6",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis7: {
        anchor: "y7",
        domain: [0.8571428571428571, 0.98],
        matches: "x",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      yaxis7: {
        anchor: "x7",
        domain: [0, 0.485],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis8: {
        anchor: "y8",
        domain: [0, 0.12285714285714285],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis8: {
        anchor: "x8",
        domain: [0.515, 1],
        matches: "y",
        title: {
          text: "",
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
        },
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
      },
      xaxis9: {
        anchor: "y9",
        domain: [0.14285714285714285, 0.2657142857142857],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis9: {
        anchor: "x9",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis10: {
        anchor: "y10",
        domain: [0.2857142857142857, 0.40857142857142853],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis10: {
        anchor: "x10",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis11: {
        anchor: "y11",
        domain: [0.42857142857142855, 0.5514285714285714],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis11: {
        anchor: "x11",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis12: {
        anchor: "y12",
        domain: [0.5714285714285714, 0.6942857142857143],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis12: {
        anchor: "x12",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis13: {
        anchor: "y13",
        domain: [0.7142857142857142, 0.8371428571428571],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis13: {
        anchor: "x13",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      xaxis14: {
        anchor: "y14",
        domain: [0.8571428571428571, 0.98],
        matches: "x",
        showticklabels: false,
        showgrid: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      yaxis14: {
        anchor: "x14",
        domain: [0.515, 1],
        matches: "y",
        showticklabels: false,
        gridcolor: "rgba(174, 181, 191, 0.4)",
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridwidth: 0.25,
        zeroline: false,
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
          },
          text: "",
        },
      },
      annotations: [
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Mon",
          x: 0.06142857142857142,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Tue",
          x: 0.20428571428571426,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Wed",
          x: 0.3471428571428571,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Thu",
          x: 0.49,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Fri",
          x: 0.6328571428571428,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Sat",
          x: 0.7757142857142856,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "Sun",
          x: 0.9185714285714286,
          xanchor: "center",
          xref: "paper",
          y: 1,
          yanchor: "bottom",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "XX",
          textangle: 90,
          x: 0.98,
          xanchor: "left",
          xref: "paper",
          y: 0.2425,
          yanchor: "middle",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 13,
          },
          showarrow: false,
          text: "YY",
          textangle: 90,
          x: 0.98,
          xanchor: "left",
          xref: "paper",
          y: 0.7575000000000001,
          yanchor: "middle",
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 14,
          },
          showarrow: false,
          text: "Total Pax",
          textangle: -90,
          x: -0.075,
          xref: "paper",
          y: 0.5,
          yref: "paper",
        },
        {
          font: {
            family: "Open Sans",
            size: 14,
          },
          showarrow: false,
          text: "Days to Departure",
          x: 0.5,
          xref: "paper",
          y: -0.2,
          yref: "paper",
        },
      ],
      legend: {
        title: {
          text: "Time Blocks",
          font: {
            color: "rgb(174, 181, 191)",
          },
          side: "top",
        },
        tracegroupgap: 0,
        font: {
          color: "rgb(174, 181, 191)",
        },
        traceorder: "normal",
        orientation: "v",
        valign: "middle",
        borderwidth: 0,
        xanchor: "left",
        yanchor: "auto",
        itemsizing: "trace",
        itemclick: "toggle",
        itemdoubleclick: "toggleothers",
      },
      margin: {
        t: 35,
        l: 35,
        r: 15,
        b: 35,
        pad: 4,
      },
      title: {
        x: 0.5,
      },
      paper_bgcolor: "rgb(32, 32, 68)",
      plot_bgcolor: "rgb(32, 32, 68)",
      id: "85b8022a-4b8f-4354-8dc8-ee624dbdb85d",
    },
  };
  return (
    <Card variant="secondary" title="7 Days Average - TY vs LY">
      <div className={styles.header}>
        <Card variant="primary" height={48}>
          <div className={styles.wrapper}>
            <span className={styles.year} data-theme={theme}>
              Year:
            </span>
            <div className={styles.color_scale_wrapper}>
              {["TY", "LY", "TY", "LY", "TY", "LY", "TY", "LY", "TY"].map(
                (el: any, index) => (
                  <span className={styles.color_scale} key={index}>
                    <span className={styles.item}>{el}</span>
                    <span
                      className={styles.dot}
                      style={{ backgroundColor: el?.color ?? "red" }}
                    ></span>
                  </span>
                )
              )}
            </div>
          </div>
        </Card>
      </div>
      <Card variant="primary">
        <PlotlyComponent
          data={data.data}
          layout={data.layout}
          minHeight={524}
        />
      </Card>
    </Card>
  );
}
