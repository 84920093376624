/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/common/Card/Card";
import images from "constans/images";
import { RulesGetSimpleClient } from "lib/api/lfa/rules/RulesGetSimple";
import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.css";
import OpenMenu from "./OpenMenu";
import { ThemeContext } from "context-api/ThemeContext";
import { useFullScreenListener } from "hooks/useFullScreenListener";
import { Fullscreen, FullscreenExit } from "@mui/icons-material";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";
import Skeleton from "components/common/Skeleton";

export default function BusinessRules({
  createdNewRule,
  setCreatedNewRule,
  editRule,
  clearForm,
  scrollSmooth,
}) {
  const [data, setData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [rowId, setRowId] = useState("");
  const { theme } = useContext(ThemeContext);

  useFullScreenListener(() =>
    setIsFullScreen(document.fullscreenElement !== null)
  );
  useEffect(() => {
    setSkeletonLoader(true);
    RulesGetSimpleClient.fetchRulesGetSimple()
      .then((res: any) => {
        setData({
          data: res?.table?.data,
          columns: getColumns(res?.table?.labels),
        });
      })
      .catch(() => setData({ data: [], columns: [] }))
      .finally(() => {
        setTimeout(() => {
          setSkeletonLoader(false);
        }, 2000);
      });
  }, [createdNewRule]);

  const getColumns = (labels) => {
    const array: any = [];
    [{ openMenu: "" }, ...labels].forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (field !== "isActive" && field !== "openMenu") {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <Tooltip title={row[field] ?? ""}>
                  <span className={"rdb-custom-cell"}>{row[field]}</span>
                </Tooltip>
              );
            },
            width: 134,
          };
          array.push(obj);
        } else if (field === "isActive") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <span className={styles.bounce} data-active={row[field]} />
            ),
            width: 80,
          };
          array.push(obj);
        } else if (field === "openMenu") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <OpenMenu
                editRule={editRule}
                id={row?.id}
                setCreatedNewRule={setCreatedNewRule}
              />
            ),
            disabledHeader: true,
          };
          array.push(obj);
        }
      }
    });
    return array;
  };

  const handleRowClick = (row: any) => {
    editRule(row?.id, "edit");
    setRowId(row?.id ?? "");
  };

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      const selectedElement = document.querySelector(`.custom-id-table`);
      selectedElement?.requestFullscreen();
    }
  }
  const conditionalRowStyles = [
    {
      when: (row) => row?.id,
      style: (row) => ({
        backgroundColor: row?.id === rowId ? "rgba(156, 0, 237,0.3)" : "",
      }),
    },
  ];

  return (
    <div className={styles.container}>
      <Card variant="secondary">
        <div className={styles.card_header}>
          <span className={styles.card_title} data-theme={theme}>
            Business Rules
          </span>
          <div
            className={styles.add_button}
            onClick={() => {
              clearForm();
              scrollSmooth(2);
            }}
            data-theme={theme}
          >
            <img src={images.plus} alt="" />
            <span>Add</span>
          </div>
        </div>
        <div
          className={`${styles.card_body} custom-id-table`}
          style={{
            backgroundImage: isFullScreen ? "var(--bg-image)" : "none",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className={styles.fullscreen_button_wrapper}>
            <span
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                toggleFullScreen();
              }}
              className={styles.fullscreen_button}
            >
              <Tooltip title="Full Screen">
                {isFullScreen ? <FullscreenExit /> : <Fullscreen />}
              </Tooltip>
            </span>
          </div>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={468} />
          ) : (
            <CustomAtarevTable
              data={data.data}
              columns={data.columns}
              onRowClick={handleRowClick}
              pagination
              conditionalRowStyles={conditionalRowStyles}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
