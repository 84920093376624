import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import CustomAtarevTable from "components/common/Table/CustomTable";
import Skeleton from "components/common/Skeleton";

interface INetworkTable {
  filterIsOpen: boolean;
  data: { data: any[]; columns: any[] };
  skeletonLoader: boolean;
  storyText: {
    network_connectivity: {
      content: string;
      title: string;
    };
    network_table: {
      content: string;
      title: string;
    };
  };
}

export default function NetworkTable({
  data,
  storyText,
  skeletonLoader,
}: INetworkTable) {
  return (
    <div className={styles.container}>
      <Card
        title="Network Table"
        variant="secondary"
        height={550}
        // cardInfo={{
        //   title: storyText?.network_table?.title ?? null,
        //   description: storyText?.network_table?.content ?? null,
        // }}
      >
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={550} />
        ) : (
          <CustomAtarevTable
            data={data?.data}
            columns={data?.columns}
            pagination
            isShowing={false}
            height={400}
          />
        )}
      </Card>
    </div>
  );
}
