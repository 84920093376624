/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import styles from "./BookingCurveAnalysis.module.css";
import settingIcon from "../../../assets/icons/element-button-minimize-copy-2@3x2.webp";
import { RangeSliderClient } from "lib/api/rangeSlider/RangeSlider";
import { BookingCurveAnalysisClient } from "lib/api/msd/bookingCurveAnalysis/BookingCurveAnalysis";
import { FilterContext } from "context-api/FilterContext";
import images from "constans/images";
import Card from "components/common/Card/Card";
import Carriers from "components/common/Carriers/Carriers";
import RangeSlider from "components/common/RangeSlider/RangeSlider";
import Plotly from "components/common/Charts/Plotly";
import GetCarriers from "components/common/GetCarriers/GetCarriers";
import { ThemeContext } from "context-api/ThemeContext";
import { useOutsideClick } from "hooks/useOutsideClick";
import Skeleton from "components/common/Skeleton";

interface IBookingCurveAnalysis {
  filterIsOpen: boolean;
}
export default function BookingCurveAnalysis({
  filterIsOpen,
}: IBookingCurveAnalysis) {
  const [carriersJson, setCarriersJson] = useState<any>();
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>([]);
  const [rangeValueMonthly, setRangeValueMonthly] = useState({
    value: 0,
    min: 0,
    max: 20,
    dateList: [],
  });
  const [rangeLoader, setRangeLoader] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [rangeValueDaily, setRangeValueDaily] = useState({
    value: -90,
    min: -365,
    max: 0,
    dateList: [],
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isActiveTotalAndPercent, setIsActiveTotalAndPercent] = useState(false);
  const [aggType, setAggType] = useState("overall");
  const { filterList } = useContext(FilterContext);
  const [chartData, setChartData] = useState([]);
  const [layout, setLayout] = useState({});
  const [xAxisTitle, setXAxisTitle] = useState("");
  const wrapperRef: any = useRef(null);
  const { theme } = useContext(ThemeContext);
  // const [storyText, setStoryText] = useState({
  //   main_card: {
  //     content: "",
  //     title: "",
  //   },
  // });
  useOutsideClick(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    setRangeLoader(true);
    RangeSliderClient.fetchRangeSlider({
      agg_type: "monthly",
      time_direction: "historical-forward",
    })
      .then((response: any) => {
        setRangeValueMonthly((prevState) => ({
          ...prevState,
          max: response.values.length - 1,
          dateList: response.values,
          value: response.start_idx,
        }));
      })
      .catch(() => {
        console.log("Error");
      })
      .finally(() => {
        setTimeout(() => {
          setRangeLoader(false);
        }, 2000);
      });
  }, []);

  useEffect(() => {
    if (!filterIsOpen && rangeValueMonthly.dateList.length > 0) {
      setSkeletonLoader(true);
      BookingCurveAnalysisClient.fetchBookingCurveAnalysis({
        filterList,
        graphCarriers: selectedCarriers.join(),
        time_direction: "forward",
        selected_yearmonth: rangeValueMonthly.dateList[rangeValueMonthly.value],
        agg_type: aggType,
        val_type: isActiveTotalAndPercent ? "ratio" : "total-bookings",
        dtd: Math.abs(rangeValueDaily.value).toString(),
        dark_theme: theme === "dark",
      })
        .then((response: any) => {
          setCarriersJson(response?.carriers);
          setLayout(response.fig.layout);
          setChartData(response.fig.data);
          setXAxisTitle(response.yaxis_title);
          // setStoryText(response?.story_text);
        })
        .catch(() => {
          setChartData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [
    filterIsOpen,
    selectedCarriers,
    aggType,
    isActiveTotalAndPercent,
    filterList,
    rangeValueMonthly.value,
    rangeValueDaily.value,
    theme,
  ]);

  useEffect(() => {
    const x = [
      ...filterList.mainCompetitor,
      ...filterList.selectedCompetitors,
    ].find((item) => item === "All");
    const allParams = localStorage.getItem("allCompetitors");
    if (x === "All" && allParams) {
      setSelectedCarriers(JSON.parse(allParams));
    } else {
      setSelectedCarriers([
        ...filterList.mainCompetitor,
        ...filterList.selectedCompetitors,
      ]);
    }
  }, [filterList.mainCompetitor, filterList.selectedCompetitors]);

  return (
    <Card
      variant="secondary"
      title="Booking Curve Analysis"
      // cardInfo={{
      //   title: storyText?.main_card?.title ?? null,
      //   description: storyText?.main_card?.content ?? null,
      // }}
    >
      <div className={styles.container}>
        <div className={styles.carriers_wrapper}>
          {skeletonLoader ? (
            <Skeleton width={"100%"} height={48} />
          ) : (
            <Carriers
              data={GetCarriers()}
              added={selectedCarriers}
              setAdded={setSelectedCarriers}
              carriersJson={carriersJson}
            />
          )}
        </div>
        <div className={styles.open_menu_wrapper} ref={wrapperRef}>
          <img
            src={theme === "dark" ? settingIcon : images.setting_button_light}
            alt=""
            className={styles.setting_icon}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          />
          <div
            className={styles.open_menu}
            data-theme={theme}
            data-active={isOpen}
          >
            <div className={styles.open_menu_top} data-theme={theme}>
              <div className={styles.open_menu_title_wrapper}>
                <span className={styles.open_menu_title} data-theme={theme}>
                  Bookings
                </span>
                <img
                  src={images.travel_plane_boarding_pass}
                  alt=""
                  className={styles.open_menu_icons}
                  data-theme={theme}
                />
              </div>
              <div className={styles.open_menu_buttons_wrapper}>
                <span
                  className={styles.open_menu_buttons}
                  data-active={!isActiveTotalAndPercent}
                  onClick={() =>
                    setIsActiveTotalAndPercent(!isActiveTotalAndPercent)
                  }
                  data-theme={theme}
                >
                  Total
                </span>
                <span
                  className={styles.open_menu_buttons}
                  data-active={isActiveTotalAndPercent}
                  onClick={() =>
                    setIsActiveTotalAndPercent(!isActiveTotalAndPercent)
                  }
                  data-theme={theme}
                >
                  Percent
                </span>
              </div>
            </div>
            <div className={styles.open_menu_bottom} data-theme={theme}>
              <div className={styles.open_menu_title_wrapper}>
                <span className={styles.open_menu_title} data-theme={theme}>
                  Comparison
                </span>
                <img
                  src={images.legal_law}
                  alt=""
                  className={styles.open_menu_icons}
                  data-theme={theme}
                />
              </div>
              <div className={styles.open_menu_buttons_wrapper}>
                <span
                  className={styles.open_menu_buttons}
                  data-active={aggType === "overall"}
                  onClick={() => {
                    setAggType("overall");
                  }}
                  data-theme={theme}
                >
                  Overall
                </span>
                <span
                  className={styles.open_menu_buttons}
                  data-active={aggType === "day-of-week"}
                  onClick={() => {
                    setAggType("day-of-week");
                  }}
                  data-theme={theme}
                >
                  DOW
                </span>
              </div>
              <div className={styles.open_menu_button}>
                <span
                  className={styles.open_menu_buttons}
                  style={{ width: "100%" }}
                  data-active={aggType === "day-of-week-time"}
                  onClick={() => {
                    setAggType("day-of-week-time");
                  }}
                  data-theme={theme}
                >
                  DOW - Time of Day
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={376} />
      ) : (
        <Card variant="primary" height={376} zIndex={-1}>
          <div className={styles.chart_wrapper}>
            <p className={styles.chart_title}>{xAxisTitle}</p>
            <Plotly
              data={chartData}
              height={289}
              layout={layout}
              margin={{ t: 30, b: 30, l: 30, r: 0, p: 4 }}
            />
          </div>
        </Card>
      )}
      <div className={styles.range_wrapper}>
        {rangeLoader ? (
          <Skeleton width={"100%"} height={100} />
        ) : (
          <Card variant="primary" width={"100%"} height={100}>
            <div className={styles.card_content}>
              <img
                src={images.clock_time_arrow}
                alt=""
                className={styles.business_icon}
                data-theme={theme}
              />
              <span className={styles.content_title} data-theme={theme}>
                Days to Departure
              </span>
            </div>
            <div className={styles.range_slider_wrapper}>
              <RangeSlider
                data={{
                  max: rangeValueDaily.max,
                  min: rangeValueDaily.min,
                  value: rangeValueDaily.value,
                  dateList: rangeValueDaily.dateList,
                  setValue: setRangeValueDaily,
                }}
              />
            </div>
          </Card>
        )}
        {rangeLoader ? (
          <Skeleton width={"100%"} height={100} />
        ) : (
          <Card variant="primary" width={"100%"} height={100}>
            <div className={styles.card_content}>
              <img
                src={images.business}
                alt=""
                className={styles.business_icon}
                data-theme={theme}
              />
              <span className={styles.content_title} data-theme={theme}>
                Booking Curve Month-Year
              </span>
            </div>
            <div className={styles.range_slider_wrapper}>
              <RangeSlider
                data={{
                  max: rangeValueMonthly.max,
                  min: rangeValueMonthly.min,
                  value: rangeValueMonthly.value,
                  dateList: rangeValueMonthly.dateList,
                  setValue: setRangeValueMonthly,
                }}
              />
            </div>
          </Card>
        )}
      </div>
    </Card>
  );
}
