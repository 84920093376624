/* eslint-disable react-hooks/exhaustive-deps */
import { FilterContext } from "context-api/FilterContext";
import { useContext, useEffect, useState } from "react";
import Card from "components/common/Card/Card";
import styles from "./styles.module.css";
import { ThemeContext } from "context-api/ThemeContext";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { Tooltip } from "components/common/Tooltip";
import { UserProfileContext } from "context-api/UserProfile";
import Skeleton from "components/common/Skeleton";
import { CLevelFareStructureFareStructureTablesClient } from "lib/api/msd/cLevelfareStructureFareStructureTables";

interface ILowComp {
  filterIsOpen: boolean;
}
export default function CLevelFareStructureLowTable({
  filterIsOpen,
}: ILowComp) {
  const [hostData, setHostData] = useState<any[]>([]);
  const [hostColumns, setHostColumns] = useState<any[]>([]);
  const [hostTitle, setHostTitle] = useState([]);
  const [compData, setCompData] = useState<any[]>([]);
  const [compColumns, setCompColumns] = useState<any[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const { user } = useContext(UserProfileContext);
  const { theme } = useContext(ThemeContext);
  const { filterList } = useContext(FilterContext);

  useEffect(() => {
    if (!filterIsOpen) {
      setSkeletonLoader(true);
      const params = {
        pos: filterList.pos,
        main_competitor: filterList.mainCompetitor,
        selected_competitors: filterList.selectedCompetitors,
        sales_channel: filterList.salesChannel,
        cabin: filterList.cabin,
        host: user?.clientCode ?? "",
        is_grouped: true,
      };
      CLevelFareStructureFareStructureTablesClient.fetchCLevelFareStructureFareStructureTables(
        params
      )
        .then((response: any) => {
          setCompData(response.comp_tables ?? []);
          setCompColumns(getColumns(response?.labels));
          setHostData(response.host_table.data ?? []);
          setHostColumns(getColumns(response.labels ?? []));
          setHostTitle(response.host_table.title);
        })
        .catch(() => {
          setCompData([]);
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [filterIsOpen]);

  const getColumns = (labels) => {
    const array: any = [];
    for (const [field, name] of Object.entries(labels)) {
      if (field !== "buyup_ratio" && field !== "pax_ratio") {
        const obj = {
          field,
          name,
          cell: (row) => (
            <Tooltip title={row[field]}>
              <span className={"rdb-custom-cell"}>{row[field]}</span>
            </Tooltip>
          ),
        };
        array.push(obj);
      } else if (field === "buyup_ratio") {
        const obj = {
          name,
          field,
          cell: (row) => (
            <Tooltip title={row[field]?.text}>
              <div className={styles.custom_table_row}>
                <span
                  className={styles.row}
                  style={{ width: row[field]?.ratio * 32 }}
                  data-active={"blue"}
                />
              </div>
            </Tooltip>
          ),
          valueGetter: (row) => row[field]?.ratio,
        };
        array.push(obj);
      } else if (field === "pax_ratio") {
        const obj = {
          name,
          field,
          cell: (row) => (
            <Tooltip title={row[field]?.text}>
              <div className={styles.custom_table_row}>
                <span
                  className={styles.row}
                  style={{ width: row[field]?.ratio * 32 }}
                />
              </div>
            </Tooltip>
          ),
          valueGetter: (row) => row[field]?.ratio,
        };
        array.push(obj);
      }
    }

    return array;
  };

  return (
    <div className={styles.container}>
      <Card variant="secondary" width={"50%"} height={648}>
        <p className={styles.card_title}>{hostTitle}</p>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={504} />
        ) : (
          <CustomAtarevTable
            data={hostData}
            columns={hostColumns}
            height={504}
          />
        )}
      </Card>
      <Card variant="secondary" width={"50%"} height={648}>
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={24} sx={{ margin: "28px 0" }} />
        ) : (
          <div className={styles.card_title_wrapper}>
            <div className={styles.slider}>
              <span
                className={styles.prev_arrow}
                onClick={() => {
                  if (activeStep > 0) {
                    setActiveStep(activeStep - 1);
                  } else {
                    setActiveStep(compData.length - 1);
                  }
                }}
                data-theme={theme}
              >
                <span className={styles.arrow_left} data-theme={theme}></span>
              </span>
              <span className={styles.slider_title}>
                {`${
                  (Array.isArray(compData) && compData[activeStep]?.title) ?? ""
                }`}
              </span>
              <span
                className={styles.next_arrow}
                onClick={() => {
                  if (activeStep < compData?.length - 1) {
                    setActiveStep(activeStep + 1);
                  } else {
                    setActiveStep(0);
                  }
                }}
                data-theme={theme}
              >
                <span className={styles.arrow_right} data-theme={theme}></span>
              </span>
            </div>
            <div className={styles.active_wrapper}>
              {compData?.map((_, index) => (
                <span
                  className={styles.active_slide}
                  data-active={activeStep === index}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}
        {skeletonLoader ? (
          <Skeleton width={"100%"} height={504} />
        ) : (
          <CustomAtarevTable
            data={
              Array.isArray(compData) ? compData[activeStep]?.data ?? [] : []
            }
            columns={compColumns}
            height={504}
          />
        )}
      </Card>
    </div>
  );
}
