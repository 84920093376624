import network from "./assets/icons/business-products-document-contract-edit-pen@3x.webp";
import fareRevenue from "./assets/icons/money-money.svg";
import fareStructure from "./assets/icons/money-accounting-calculator-circle.svg";
import agency from "./assets/icons/office-work-presentation-board-text-chart-graph.svg";
import customerSegmentation from "./assets/icons/user-group-user.svg";
import comparative from "./assets/icons/legal-law-legal-scale.svg";
import strategy from "./assets/icons/sport-chess.svg";
import images from "constans/images";

import AGENCYANALYSIS from "./modules/msd/agencyAnalysis";
import STRATEGYACTIONS from "./modules/msd/strategyActions";
import FAREREVENUE from "./modules/msd/fareRevenue";
import BOOKINGTRENDS from "./modules/msd/bookingTrends";
import CUSTOMERSEGMENTATION from "./modules/msd/customerSegmentation";
import PRODUCTOVERVIEW from "./modules/msd/productOverview";
import NETWORKSCHEDULING from "./modules/msd/networkScheduling";
import MARKETSHARE from "./modules/msd/marketShare";
import FARESTRUCTURE from "./modules/msd/fareStructure";
import BRERESULTS from "./modules/lfa/recommendations";
import PRICEEVOLUTION from "./modules/lfa/priceEvolution";
import RULES from "./modules/lfa/rules";
import PERFORMANCETRENDSLOADFACTORCURVE from "modules/msd/performanceTrends";
import AVAILABILITYTRENDS from "modules/lfa/availabilityTrends";
import COMPARATIVEANALYSIS from "modules/msd/comparativeAnalysis";
import LOVESTFARECALENDAR from "modules/lfa/lovestFareCalendar";
import DAILYFLIGHTSOVERVIEW from "modules/lfa/dailyFlightsOverview";
import ALERTSDASHBOARD from "modules/ea/alertsDashboard";
import HOLIDAYS from "modules/ea/calendar";
import COMPARISONVIEW from "modules/ea/comparisonView";
import ALERTSRULES from "modules/ea/alertRules";
import FAREANALYZER from "modules/fa/fares";
import CLEVELFARESTRUCTURE from "modules/msd/cLevelFareStructure";
import DASHBOARD from "modules/clevel/dashboard";
import CLEVELHEATMAP from "modules/clevel/heatmap";
import FARULES from "modules/fa/rules";

export const RouteList = {
  LFA: [
    {
      name: "Lowest Fare Calendar",
      icon: images.calendar,
      path: "/lowest-fare-calendar",
      disabled: false,
      component: LOVESTFARECALENDAR,
    },
    {
      name: "Daily Flights Overview",
      icon: images.travel_airplane2,
      path: "/daily-flights-overview",
      disabled: false,
      component: DAILYFLIGHTSOVERVIEW,
    },
    {
      name: "Availability Trends",
      icon: images.calendar,
      path: "/availability-trends",
      disabled: false,
      component: AVAILABILITYTRENDS,
    },
    {
      name: "Price Evolution",
      icon: images.business_chart,
      path: "/price-evolution",
      disabled: false,
      component: PRICEEVOLUTION,
    },
    {
      name: "Rules",
      icon: images.programming_code_checkmark,
      path: "/actions",
      disabled: false,
      component: RULES,
    },
    {
      name: "Recommendations",
      icon: images.target_goal_white,
      path: "/price-recommendation",
      disabled: false,
      component: BRERESULTS,
    },
  ],

  MSD: [
    {
      name: "Product Overview",
      icon: images.eye,
      path: "/product-overview",
      disabled: false,
      component: PRODUCTOVERVIEW,
    },
    {
      name: "Market Share",
      icon: images.business_products_business_chart,
      path: "/market-share",
      disabled: false,
      component: MARKETSHARE,
    },
    {
      name: "Network & Scheduling",
      icon: network,
      path: "/network-scheduling",
      disabled: false,
      component: NETWORKSCHEDULING,
    },
    {
      name: "Booking Trends",
      icon: images.boarding_pass,
      path: "/booking-trends",
      disabled: false,
      component: BOOKINGTRENDS,
    },
    {
      name: "Fare & Revenue",
      icon: fareRevenue,
      path: "/fare-revenue",
      disabled: false,
      component: FAREREVENUE,
    },
    {
      name: "Fare Structure",
      icon: fareStructure,
      path: "/fare-structure",
      disabled: false,
      component: FARESTRUCTURE,
    },
    {
      name: "Agency Analysis",
      icon: agency,
      path: "/agency-analysis",
      disabled: false,
      component: AGENCYANALYSIS,
    },
    {
      name: "Customer Segmentation",
      icon: customerSegmentation,
      path: "/customer-segmentation",
      disabled: false,
      component: CUSTOMERSEGMENTATION,
    },
    {
      name: "Comparative Analysis",
      icon: comparative,
      path: "/comparative-analysis",
      disabled: true,
      component: COMPARATIVEANALYSIS,
    },
    {
      name: "Strategy & Actions",
      icon: strategy,
      path: "/strategy-actions",
      disabled: false,
      component: STRATEGYACTIONS,
    },
    {
      name: "Performance Trends",
      icon: strategy,
      path: "/performance-trends-load-factor-curve",
      disabled: false,
      component: PERFORMANCETRENDSLOADFACTORCURVE,
    },
    {
      name: "C-Level Fare Structure",
      icon: fareStructure,
      path: "/c-level-fare-structure",
      disabled: false,
      component: CLEVELFARESTRUCTURE,
    },
  ],

  FA: [
    {
      name: "Fares",
      icon: images.calendar,
      path: "/fares",
      disabled: false,
      component: FAREANALYZER,
    },
    {
      name: "Rules",
      icon: images.travel_airplane2,
      path: "/rules",
      disabled: false,
      component: FARULES,
    },
  ],

  EA: [
    {
      name: "Alerts Dashboard",
      icon: images.target_goal_white,
      path: "/events-alert",
      disabled: false,
      component: ALERTSDASHBOARD,
    },
    {
      name: "Events Calendar",
      icon: images.target_goal_white,
      path: "/events-calendar",
      disabled: false,
      component: HOLIDAYS,
    },
    {
      name: "Comparison View",
      icon: images.target_goal_white,
      path: "/settings",
      disabled: false,
      component: COMPARISONVIEW,
    },
    {
      name: "Alerts Rules",
      icon: images.target_goal_white,
      path: "/alerts-rules",
      disabled: false,
      component: ALERTSRULES,
    },
  ],
  
  CLEVEL: [
    {
      name: "Dashboard",
      icon: images.calendar,
      path: "/c-level",
      disabled: false,
      component: DASHBOARD,
    },
    {
      name: "Fare Structure",
      icon: images.travel_airplane2,
      path: "/c-level-fare-structure",
      disabled: false,
      component: CLEVELFARESTRUCTURE,
    },
    {
      name: "Heatmap",
      icon: images.travel_airplane2,
      path: "/heatmap",
      disabled: false,
      component: CLEVELHEATMAP,
    },
  ],
};

export default RouteList;
