import Skeleton from "components/common/Skeleton";


const SkeletonView = () => {
  return (
    <div>
      <Skeleton
        width={"100%"}
        height={56}
      />
      <div
        style={{
          display: "flex",
          gap: 16,
          marginTop: 16,
        }}
      >
        <Skeleton
          sx={{
            borderRadius: 10,
          }}
          width={349}
          height={208}
        />
        <Skeleton
          sx={{
            borderRadius: 10,
          }}
          width={349}
          height={208}
        />
        <Skeleton
          sx={{
            borderRadius: 10,
          }}
          width={349}
          height={208}
        />
      </div>
    </div>
  );
};

export default SkeletonView;
