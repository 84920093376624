import KPIMini from "components/msd/KPIMini/KPIMini";
import MarketConnectivityMap from "components/msd/MarketConnectivityMap/MarketConnectivityMap";
import BeyondPoints from "components/msd/BeyondPoints/BeyondPoints";
import { useState } from "react";
import NetworkTable from "components/msd/NetworkTable/NetworkTable";
import SchedulingDetails from "components/msd/SchedulingDetails/SchedulingDetails";
import SchedulingComparison from "components/msd/SchedulingComparison/SchedulingComparison";
import Layout from "components/common/Layout/Layout";
import { ICustomAtarevTable } from "types/types";

export default function NETWORKSCHEDULING() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [networkTable, setNetworkTable] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [networkTableLoader, setNetworkTableLoader] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [schedulingTable, setSchedulingTable] = useState({
    data: [],
    columns: [],
  });
  const [schedulingTableLoader, setSchedulingTableLoader] = useState(false);
  const [storyTextConnectivity, setStoryTextConnectivity] = useState({
    network_connectivity: {
      content: "",
      title: "",
    },
    network_table: {
      content: "",
      title: "",
    },
  });
  const [storyTextComparison, setStoryTextComparison] = useState({
    scheduling_comparison: {
      content: "",
      title: "",
    },
    scheduling_details: {
      content: "",
      title: "",
    },
  });
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Network Scheduling"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <MarketConnectivityMap
        filterIsOpen={filterIsOpen}
        setNetworkTable={setNetworkTable}
        setNetworkTableLoader={setNetworkTableLoader}
        setSelectedYear={setSelectedYear}
        selectedYear={selectedYear}
        storyText={storyTextConnectivity}
        setStoryText={setStoryTextConnectivity}
      />

      <div style={{ display: "flex", gap: 16 }}>
        <BeyondPoints filterIsOpen={filterIsOpen} selectedYear={selectedYear} />
        <NetworkTable
          filterIsOpen={filterIsOpen}
          data={networkTable}
          skeletonLoader={networkTableLoader}
          storyText={storyTextConnectivity}
        />
      </div>
      <div style={{ display: "flex" }}>
        <SchedulingDetails
          filterIsOpen={filterIsOpen}
          schedulingTable={schedulingTable}
          skeletonLoader={schedulingTableLoader}
          storyText={storyTextComparison}
        />
        <SchedulingComparison
          filterIsOpen={filterIsOpen}
          setSchedulingTable={setSchedulingTable}
          setSchedulingTableLoader={setSchedulingTableLoader}
          storyText={storyTextComparison}
          setStoryText={setStoryTextComparison}
        />
      </div>
    </Layout>
  );
}
