import Card from "components/common/Card/Card";
import PlotlyComponent from "components/common/Charts/Plotly";

export default function CurrentMonthBookingVarience() {
  const data = {
    data: [
      {
        fill: "tozeroy",
        hoverinfo: "text",
        hovertext: [
          "Carrier: XX<br>Date: 2023-08-01<br>Average Fare: $183",
          "Carrier: XX<br>Date: 2023-09-01<br>Average Fare: $0",
          "Carrier: XX<br>Date: 2023-10-01<br>Average Fare: $0",
          "Carrier: XX<br>Date: 2023-11-01<br>Average Fare: $0",
          "Carrier: XX<br>Date: 2023-12-01<br>Average Fare: $0",
        ],
        legendgroup: "XX",
        line: {
          color: "#7696FF",
          dash: "solid",
          shape: "spline",
          smoothing: 1.3,
          width: 6,
        },
        mode: "lines",
        name: "XX",
        opacity: 0.8,
        showlegend: true,
        visible: true,
        x: [
          "2023-08-01",
          "2023-09-01",
          "2023-10-01",
          "2023-11-01",
          "2023-12-01",
        ],
        y: [183.66666666666666, 0, 0, 0, 0],
        type: "scatter",
      },
      {
        fill: "tozeroy",
        hoverinfo: "text",
        hovertext: [
          "Carrier: YY<br>Date: 2023-08-01<br>Average Fare: $247",
          "Carrier: YY<br>Date: 2023-09-01<br>Average Fare: $187",
          "Carrier: YY<br>Date: 2023-10-01<br>Average Fare: $0",
          "Carrier: YY<br>Date: 2023-11-01<br>Average Fare: $0",
          "Carrier: YY<br>Date: 2023-12-01<br>Average Fare: $0",
        ],
        legendgroup: "YY",
        line: {
          color: "#B78EE5",
          dash: "solid",
          shape: "spline",
          smoothing: 1.3,
          width: 6,
        },
        mode: "lines",
        name: "YY",
        opacity: 0.8,
        showlegend: true,
        visible: true,
        x: [
          "2023-08-01",
          "2023-09-01",
          "2023-10-01",
          "2023-11-01",
          "2023-12-01",
        ],
        y: [247.3846153846154, 187, 0, 0, 0],
        type: "scatter",
      },
      {
        fill: "tozeroy",
        hoverinfo: "text",
        hovertext: [
          "Carrier: ZZ<br>Date: 2023-08-01<br>Average Fare: $146",
          "Carrier: ZZ<br>Date: 2023-09-01<br>Average Fare: $0",
          "Carrier: ZZ<br>Date: 2023-10-01<br>Average Fare: $0",
          "Carrier: ZZ<br>Date: 2023-11-01<br>Average Fare: $0",
          "Carrier: ZZ<br>Date: 2023-12-01<br>Average Fare: $0",
        ],
        legendgroup: "ZZ",
        line: {
          color: "#8EEBE8",
          dash: "solid",
          shape: "spline",
          smoothing: 1.3,
          width: 6,
        },
        mode: "lines",
        name: "ZZ",
        opacity: 0.8,
        showlegend: true,
        visible: true,
        x: [
          "2023-08-01",
          "2023-09-01",
          "2023-10-01",
          "2023-11-01",
          "2023-12-01",
        ],
        y: [146, 0, 0, 0, 0],
        type: "scatter",
      },
    ],
    layout: {
      xaxis: {
        title: {
          text: "Date",
          font: {
            color: "rgb(174, 181, 191)",
            size: 13,
            family: "Open Sans",
          },
        },
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        type: "date",
        gridcolor: "rgb(248, 235, 247)",
        zeroline: false,
        showgrid: false,
      },
      yaxis: {
        title: {
          text: "Average Fare ($)",
          font: {
            color: "rgb(174, 181, 191)",
            size: 13,
            family: "Open Sans",
          },
        },
        tickfont: {
          size: 13,
          family: "Open Sans",
        },
        gridcolor: "rgb(248, 235, 247)",
        zeroline: false,
        showgrid: false,
      },
      title: {
        font: {
          family: "Open Sans",
          size: 23,
        },
        x: 0.5,
      },
      legend: {
        font: {
          color: "rgb(174, 181, 191)",
          size: 12,
          family: "Open Sans",
        },
        title: {
          font: {
            color: "rgb(174, 181, 191)",
            family: "Open Sans",
            size: 12,
          },
          text: "Carriers",
          side: "top",
        },
        borderwidth: 0,
        bgcolor: "rgb(32, 32, 68)",
        orientation: "v",
        traceorder: "normal",
        itemsizing: "trace",
        itemclick: "toggle",
        itemdoubleclick: "toggleothers",
        xanchor: "left",
        yanchor: "auto",
        valign: "middle",
      },
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
        pad: 4,
      },
      paper_bgcolor: "rgb(32, 32, 68)",
      plot_bgcolor: "rgb(32, 32, 68)",
      autosize: true,
    },
  };
  return (
    <Card
      variant="secondary"
      title="Current Month Booking Varience - TY vs LY"
      width={"100%"}
    >
      <Card variant="primary">
        <PlotlyComponent
          data={data.data}
          layout={data.layout}
          minHeight={524}
        />
      </Card>
    </Card>
  );
}
