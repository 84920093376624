import KPIMini from "components/msd/KPIMini/KPIMini";
import PassengersCabinChannelMix from "components/msd/PassengersCabinChannelMix/PassengersCabinChannelMix";
import PointOfSaleBreakdown from "components/msd/PointOfSaleBreakdown/PointOfSaleBreakdown";
import ProductMatrix from "components/msd/ProductMatrix/ProductMatrix";
import { useState } from "react";
import Layout from "components/common/Layout/Layout";

export default function PRODUCTOVERVIEW() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);
  const [passengersMix, setPassengersMix] = useState<{
    data: any[];
    layout: any;
  }>({
    data: [],
    layout: {},
  });
  const [cabinMix, setCabinMix] = useState<{
    data: any[];
    layout: any;
  }>({ data: [], layout: {} });
  const [channelMix, setChannelMix] = useState<{
    data: any[];
    layout: any;
  }>({ data: [], layout: {} });

  const [passengersMixLoader, setPassengersMixLoader] = useState(true);
  const [cabinMixLoader, setCabinMixLoader] = useState(true);
  const [channelMixLoader, setChannelMixLoader] = useState(true);
  const [storyText, setStoryText] = useState<any>({
    productMatrix: {},
    pos: {},
    passenger: {},
    cabin: {},
    channel: {},
  });
  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="Product Overview"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
      <ProductMatrix
        filterIsOpen={filterIsOpen}
        setPassengersMix={setPassengersMix}
        setCabinMix={setCabinMix}
        setChannelMix={setChannelMix}
        setPassengersMixLoader={setPassengersMixLoader}
        setCabinMixLoader={setCabinMixLoader}
        setChannelMixLoader={setChannelMixLoader}
        storyText={storyText}
        setStoryText={setStoryText}
      />
      <PassengersCabinChannelMix
        storyText={storyText}
        passengersMix={passengersMix}
        cabinMix={cabinMix}
        channelMix={channelMix}
        passengersMixLoader={passengersMixLoader}
        cabinMixLoader={cabinMixLoader}
        channelMixLoader={channelMixLoader}
      />
      <PointOfSaleBreakdown
        filterIsOpen={filterIsOpen}
        storyText={storyText}
        setStoryText={setStoryText}
      />
    </Layout>
  );
}
