import moment from "moment";
import ReactExport from "react-export-excel";
import { ICustomAtarevTable } from "types/types";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

interface IProps extends ICustomAtarevTable {
  fileName?: string;
}
const ExcelExport = ({ data, columns, fileName = "Excel" }: IProps) => {
  const parsedData = data.map((item) => {
    let newItem = {};
    columns.forEach((col) => {
      if (col.valueGetter) {
        newItem[col.field] = col.valueGetter(item);
      } else {
        newItem[col.field] = item[col.field];
      }
    });
    return newItem;
  });
  return (
    <ExcelFile element={<span>Export Excel</span>}>
      <ExcelSheet data={parsedData} name={fileName}>
        <ExcelColumn
          key={"document_date"}
          label={`${moment().format("DD.MM.YYYY")}`}
        />
        {columns.map((column) => (
          <ExcelColumn
            key={column.field}
            label={column.name}
            value={column.field}
          />
        ))}
      </ExcelSheet>
    </ExcelFile>
  );
};
export default ExcelExport;
