import KPIMini from "components/msd/KPIMini/KPIMini";
import { useState } from "react";
import Layout from "components/common/Layout/Layout";

export default function CLEVELHEATMAP() {
  const [filterIsOpen, setFilterIsOpen] = useState(true);

  return (
    <Layout
      setFilterIsOpen={setFilterIsOpen}
      filterIsOpen={filterIsOpen}
      title="C-Level Fare Structure"
    >
      <KPIMini filterIsOpen={filterIsOpen} />
    </Layout>
  );
}
