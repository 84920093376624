import { ArrowForwardIos } from "@mui/icons-material";
import { Fragment, useState } from "react";
import styles from "./styles.module.css";
import { Tooltip } from "components/common/Tooltip";

const Recursive = ({
  data,
  selectableRows,
  columns,
  colGroups,
  selectedGroupFields,
}) => {
  const [expandedRowTuple, setExpandedRowTuple] = useState(null);

  const toggleExpandTuple = (index) => {
    setExpandedRowTuple((prevIndex) => (prevIndex === index ? null : index));
  };

  return data.map((tupleItem, tupleIndex) => (
    <Fragment key={tupleIndex}>
      <tr
        style={{
          backgroundColor: `rgba(255,255,255,${tupleItem?.level * 0.02})`,
          borderTop: tupleIndex > 0 ? `1px solid rgba(0,0,0,0.1)` : 0,
        }}
      >
        {selectableRows && <td />}
        <td />
        <td
          onClick={() => {
            if (tupleItem?.data) {
              toggleExpandTuple(tupleIndex);
            }
          }}
          style={{ cursor: "pointer" }}
        >
          {tupleItem?.data && (
            <span
              className={styles.expand_button}
              style={{ marginLeft: tupleItem?.level * 10 }}
            >
              <ArrowForwardIos
                style={{
                  transform:
                    expandedRowTuple === tupleIndex
                      ? "rotate(90deg)"
                      : "rotate(0deg)",
                }}
                className={styles.mui_icon}
              />
            </span>
          )}
        </td>
        {columns.map((column, columnIndex) => {
          const { cell } = column;
          return (
            <Tooltip title={cell(tupleItem) ?? ""} placement="bottom-start">
              <td
                key={columnIndex}
                className={styles.td}
                style={{
                  display: colGroups
                    ? selectedGroupFields.includes(column.field)
                      ? ""
                      : "none"
                    : "",
                }}
              >
                {cell(tupleItem)}
              </td>
            </Tooltip>
          );
        })}
      </tr>
      {expandedRowTuple === tupleIndex && tupleItem.data && (
        <Fragment>
          <Recursive
            data={tupleItem.data}
            selectableRows={selectableRows}
            columns={columns}
            colGroups={colGroups}
            selectedGroupFields={selectedGroupFields}
          />
        </Fragment>
      )}
    </Fragment>
  ));
};

export default Recursive;
