/* eslint-disable react-hooks/exhaustive-deps */
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { FilterContext } from "context-api/FilterContext";
import { ThemeContext } from "context-api/ThemeContext";
import { LowestFareCalendarClient } from "lib/api/lfa/lowestFareCalendar/LowestFareCalendar";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import styles from "./styles.module.css";
import LowestMountHolidaysTooltip from "../HolidaysTooltip";
import images from "constans/images";
import LowestMountPriceTooltip from "../PriceTooltip";
import { UserProfileContext } from "context-api/UserProfile";
import OpenMenu from "./OpenMenu";
import { useHistory } from "react-router-dom";
import CustomAtarevTable from "components/common/Table/CustomTable";
import { ICustomAtarevTable } from "types/types";
import { Tooltip } from "components/common/Tooltip";
import { BasicFilterContext } from "context-api/BasicFilter";
import Skeleton from "components/common/Skeleton";
import { FlightTypeSwitchContext } from "context-api/OwRtContext";

interface IWeekComponent {
  selectedDate: Date;
  setDate: Dispatch<SetStateAction<Date>>;
  cabin_code: string;
  setDateRange: Dispatch<
    SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
  selectedCarrier: string[];
  setDropdownOptions: Dispatch<SetStateAction<string[]>>;
  filterIsOpen: boolean;
}
interface IFares {
  weekDay: string;
  day: number;
  fare: string;
  soldOut: boolean;
  currency: string;
  date: string;
  is_holiday: boolean;
  holidays: {
    country_name: string;
    holiday_name: string;
    start_date: string;
    end_date: string;
  }[];
}

export default function WeekComponent({
  selectedDate,
  setDate,
  cabin_code,
  setDateRange,
  selectedCarrier,
  setDropdownOptions,
  filterIsOpen,
}: IWeekComponent) {
  const [tableData, setTableData] = useState<ICustomAtarevTable>({
    data: [],
    columns: [],
  });
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const [fares, setFares] = useState<IFares[]>([]);
  const [selectedDay, setSelectedDay] = useState<string>();

  const { filterList, setFilterList } = useContext(FilterContext);
  const { basicFilter } = useContext(BasicFilterContext);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserProfileContext);
  const { owRtSwitch, cs } = useContext(FlightTypeSwitchContext);

  let history = useHistory();

  const conditionalRowStyles = [
    {
      when: (row) => row?.carrier,
      style: (row) => ({
        border:
          row?.carrier?.value === user?.clientCode
            ? `1px solid ${row?.carrier?.color}`
            : "none",
      }),
    },
  ];

  useEffect(() => {
    setDateRange({
      start: new Date(
        moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay() + 6
              : selectedDate.getDay() - 1,
            "days"
          )
          .format("YYYY-MM-DD")
      ),
      end: new Date(
        moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay()
              : selectedDate.getDay() - 7,
            "days"
          )
          .format("YYYY-MM-DD")
      ),
    });

    if (
      filterList.origCityAirport.length > 0 &&
      filterList.destCityAirport.length > 0 &&
      !filterIsOpen &&
      owRtSwitch
    ) {
      const data = {
        start_date: moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay() + 6
              : selectedDate.getDay() - 1,
            "days"
          )
          .format("YYYY-MM-DD"),
        end_date: moment(selectedDate)
          .subtract(
            selectedDate.getDay() === 0
              ? selectedDate.getDay()
              : selectedDate.getDay() - 7,
            "days"
          )
          .format("YYYY-MM-DD"),
        source: filterList.origCityAirport,
        destination: filterList.destCityAirport,
        competitors: filterList.selectedCompetitors?.join(),
        main_competitor: filterList.mainCompetitor,
        cabin: cabin_code,
        agg_type: "week",
        display_date: moment(selectedDate).format("YYYY-MM-DD"),
        graph_carriers: "All",
        currency: filterList.currency,
        type: owRtSwitch.toUpperCase(),
        include_share_code: cs,
        fare_family: basicFilter,
      };
      setSkeletonLoader(true);
      LowestFareCalendarClient.fetchLowestFareCalendar({ data })
        .then((res: any) => {
          setTableData({
            data: res?.table?.data,
            columns: getColumns(res?.table?.labels),
          });
          setFares(res?.fares);
          setSelectedDay(moment(selectedDate).format("YYYY-MM-DD"));
          setDropdownOptions(Object.keys(res?.carriers));
        })
        .catch(() => {
          setTableData({ data: [], columns: [] });
        })
        .finally(() => {
          setTimeout(() => {
            setSkeletonLoader(false);
          }, 2000);
        });
    }
  }, [
    filterList,
    basicFilter,
    selectedDate,
    cabin_code,
    selectedCarrier,
    filterIsOpen,
    owRtSwitch,
    cs,
  ]);

  const getColumns = (labels) => {
    const array: any = [];
    [{ openMenu: "" }, ...labels].forEach((element) => {
      for (const [field, name] of Object.entries(element)) {
        if (
          field !== "carrier" &&
          field !== "maf" &&
          field !== "openMenu" &&
          field !== "loadFactor"
        ) {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip title={row[field]}>
                <span className={"rdb-custom-cell"}>{row[field]}</span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "openMenu") {
          const obj = {
            name,
            field,
            cell: (row) => <OpenMenu row={row} selectMenu={onRowClick} />,
            width: 50,
            disabledHeader: true,
          };
          array.push(obj);
        } else if (field === "maf") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <Tooltip
                title={
                  <LowestMountPriceTooltip
                    data={{
                      hoverTexts: row?.hoverTexts,
                      lastUpdated: row?.lastUpdated,
                    }}
                    legs={row?.legs ?? []}
                    totalTime={row?.totalTime}
                  />
                }
              >
                <span className={"rdb-custom-cell"}>{row[field]}</span>
              </Tooltip>
            ),
          };
          array.push(obj);
        } else if (field === "carrier") {
          const obj = {
            name,
            field,
            cell: (row) => (
              <div
                className={styles.custom_table_row}
                style={{
                  backgroundColor: row[field].color || "rgba(255,0,0,0.2)",
                }}
              >
                <Tooltip
                  title={
                    row?.isConnecting && (
                      <LowestMountPriceTooltip
                        data={{
                          hoverTexts: row?.hoverTexts,
                          lastUpdated: row?.lastUpdated,
                        }}
                        legs={row?.legs ?? []}
                        totalTime={row?.totalTime}
                      />
                    )
                  }
                >
                  <span className={styles.row}>{row[field].value}</span>
                </Tooltip>
              </div>
            ),
            valueGetter: (row) => row[field].value,
          };
          array.push(obj);
        } else if (field === "loadFactor") {
          const obj = {
            name,
            field,
            cell: (row) => {
              return (
                <Tooltip title={row?.LFLastupdatedAt ?? "-"}>
                  <span
                    className={"rdb-custom-cell"}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: 8,
                    }}
                  >
                    {row[field] && (
                      <span style={{ width: 30 }}>{`%${
                        row[field] ?? "-"
                      }`}</span>
                    )}
                    <span className={styles.bar_wrapper}>
                      <span
                        className={styles.bar}
                        style={{
                          backgroundColor: getColor(row[field]),
                          width:
                            row[field] &&
                            typeof row[field] === "number" &&
                            row[field] * 0.6,
                        }}
                      />
                    </span>
                  </span>
                </Tooltip>
              );
            },
          };
          array.push(obj);
        }
      }
    });
    return array;
  };
  const getColor = (value) => {
    const today = moment();
    const selected = moment(selectedDate);
    const daysDifference = selected.diff(today, "days");
    if (value && typeof value === "number") {
      if (daysDifference + 1 > 30) {
        return "#9c00ed";
      } else if (daysDifference + 1 < 30 && value < 50) {
        return "#ec4252"; //Kırmızı
      } else if (daysDifference + 1 < 30 && value >= 50) {
        return "#00ad92"; //Yeşil
      }
    }
    return "#9c00ed";
  };
  const handleSelectDate = (date) => {
    setSelectedDay(date);
    setDate(new Date(moment(date, "YYYY-MM-DD").format()));
  };
  const onRowClick = (row, location = null) => {
    let empty = filterList;
    Object.keys(empty).forEach((key) => {
      empty[key] = [];
    });
    setFilterList((prevState) => ({ ...prevState, ...empty }));
    setTimeout(() => {
      setFilterList((prevState) => ({
        ...prevState,
        origCityAirport: row?.origin ? [row?.origin] : [],
        destCityAirport: row?.destination ? [row?.destination] : [],
        mainCompetitor: row?.competitor ? [row?.competitor] : [],
        selectedCompetitors: ["All"],
        pos: ["All"],
        salesChannel: ["All"],
        cabin: ["All"],
        paxType: ["All"],
      }));
    }, 2000);

    if (location === "at") {
      setTimeout(() => {
        history.push(`/availability-trends?cabin=${row?.cabinCode ?? "All"}`);
      }, 3000);
      return;
    }
    if (location === "pe") {
      setTimeout(() => {
        history.push(`/price-evolution?cabin=${row?.cabinCode ?? "All"}`);
      }, 3000);
      return;
    }
  };
  return (
    <div>
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={24} sx={{ marginBottom: "4px" }} />
      ) : (
        <div className={styles.calendar_navigation_wrapper}>
          <div
            className={styles.arrow_left}
            onClick={() =>
              setDate(
                new Date(moment(selectedDate).subtract(7, "days").format())
              )
            }
            data-theme={theme}
          >
            <KeyboardArrowLeft />
          </div>
          <div className={styles.calendar_wrapper}>
            {fares?.map((el, index) => {
              return (
                <span
                  key={index}
                  className={styles.calendar_days}
                  data-active={el?.date === selectedDay}
                  onClick={() => handleSelectDate(el?.date)}
                  data-theme={theme}
                  data-holiday={el?.is_holiday}
                >
                  {`${el?.weekDay ?? ""} ${el?.day ?? ""} | ${
                    el?.currency ?? ""
                  } ${el?.fare ?? ""}`}
                  {el?.is_holiday && (
                    <Tooltip
                      title={
                        <LowestMountHolidaysTooltip data={el?.holidays ?? []} />
                      }
                      open={el?.is_holiday ? undefined : el?.is_holiday}
                    >
                      <img
                        src={
                          theme === "dark"
                            ? images.lowest_info
                            : images.lowest_info_light
                        }
                        alt=""
                      />
                    </Tooltip>
                  )}
                </span>
              );
            })}
          </div>
          <div
            className={styles.arrow_right}
            onClick={() =>
              setDate(new Date(moment(selectedDate).add(7, "days").format()))
            }
            data-theme={theme}
          >
            <KeyboardArrowRight />
          </div>
        </div>
      )}
      {skeletonLoader ? (
        <Skeleton width={"100%"} height={420} />
      ) : (
        <CustomAtarevTable
          data={tableData.data}
          columns={tableData.columns}
          conditionalRowStyles={conditionalRowStyles}
        />
      )}
    </div>
  );
}
